import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const GoogleSignInButton = ({deeplink, gamesession}) => {
  const navigate = useNavigate();
  const handleSuccess = async (response) => {
    const idToken = response.credential;
    try {
      const res = await fetch(`${process.env.API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY
        },
        body: JSON.stringify({ token: idToken }),
      });
      if (res.ok) {
        const data = await res.json();
        await new Promise((resolve) => {
          Cookies.set('c8_session_token', data.token, { expires: 365, secure: false, sameSite: 'strict' });
          resolve();
        });

        if (gamesession!==null) {
          const response = await fetch(`${process.env.API_URL}/collectwinnings/${gamesession}/${data.token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.API_KEY,
            },
          });
        }
        if (deeplink===null) {
          window.location.href = '/account';
        } else {
          window.location.href = deeplink;
        }
      } else {
        console.log('Failed to authenticate user:', res.statusText);
      }
    } catch (error) {
      console.log('Error sending token to backend:', error);
    }
  };

  const handleFailure = (response) => {
    console.error('Google sign-in error:', response);
  };

  return (
    <GoogleOAuthProvider clientId="770665449976-oplpl4c660b1114r1tivkpom8vq7gugr.apps.googleusercontent.com">
      <GoogleLogin
        theme='filled_blue' 
        onSuccess={handleSuccess}
        onError={handleFailure}
        logo_alignment='left'
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleSignInButton;
