import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClubLogo } from "../../ClubLogo";
import { PlayerImage } from "../../PlayerImg";

export const ScoreBox = ({teams, batting, score, overs, probability, scoregrid, partnership, displayPartnerShip='true', prematch, startdate, complete, eventStatus, matchComment, currentInnings }) => {
  if (partnership.bowler==='' && partnership.batter==='') {
    displayPartnerShip='false';
  }
  let noGrid=false;
  if (scoregrid.length<=1) {
    noGrid=true;
  }
  const [winProbTitle, setWinProbTitle] = useState('Win probability');
  useEffect(() => {
    let intervalId;
    if (currentInnings==2) {
      const updateTitle = () => {
        if (winProbTitle==='Win probability') {
          setWinProbTitle(matchComment);
        } else {
          setWinProbTitle('Win probability');
        }
      };

      intervalId = setInterval(updateTitle, 20000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [matchComment, winProbTitle, currentInnings]);

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let localdt = new Date(startdate ? startdate : '');
  let formattedDate='';
  if (localdt!=='') {
    const day = localdt.getDate().toString().padStart(2, '0');
    const month = months[localdt.getMonth()];
    const year = localdt.getFullYear();
    const hours = localdt.getHours().toString().padStart(2, '0');
    const minutes = localdt.getMinutes().toString().padStart(2, '0');
    formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;
  }
  return (
    <div className={`relative w-[100%] ${displayPartnerShip==='true' ? ('h-[273px]') : ('h-[181px]')} `}>
      
      {/* WIN PROB */}
      <div className="absolute w-[100%] h-[40px] top-[117px] left-0">
        <div className="absolute w-[100%] h-[40px] top-0 left-0 bg-white opacity-[0.15]" />
        <div className="relative top-[8px] font-chakra text-white text-center tracking-[0] leading-[normal] text-[14px]">
          {complete!=='true' ? (
            <>
               <span>{winProbTitle}</span>
            </>
          ) : ( <>{eventStatus}</> )}
        </div>

        {complete!=='true' && winProbTitle==='Win probability' ? (
        <>
        <div className="flex absolute top-[10px] left-[10px] w-[30%] font-chakra text-white text-[14px]">
          <div>
            {teams[0]===probability[0].name ? ( probability[0].probability ) : ( probability[1].probability)}%
          </div>
          <div className='relative left-[10px] top-[5px] w-[55px] md:w-[100px] flex'>
            <div className="h-[4px] bg-black opacity-50" 
                 style={{ width: `${teams[0] === probability[0].name ? probability[1].probability : probability[0].probability}%` }} 
            />
            <div className="h-[4px] bg-[#f73261]"
                 style={{ width: `${teams[0] === probability[0].name ? probability[0].probability : probability[1].probability}%` }} 
            />
          </div>
        </div>

        <div className="flex absolute top-[10px] left-[70%] w-[30%] justify-end text-right pr-[10px] font-chakra text-white text-[14px]">
          <div className='relative left-[-10px] top-[5px] w-[55px] md:w-[100px] flex'>
            <div className="h-[4px] bg-black opacity-50" 
                 style={{ width: `${teams[1] === probability[1].name ? probability[0].probability : probability[1].probability}%` }} 
            />
            <div className="h-[4px] bg-[#32f78c]"
                 style={{ width: `${teams[1] === probability[1].name ? probability[1].probability : probability[0].probability}%` }} 
            />
          </div>
          <div>
            {teams[1]===probability[1].name ? ( probability[1].probability ) : ( probability[0].probability)}%
          </div>
        </div>
        </>
        ) : ( null )}
        </div>
      {/* WIN PROB END */}

      {!prematch ? (
        <>
        {!noGrid ? ( 
        <div className='absolute w-[100%]'>
          <div className="relative w-full h-[35px] top-[159px] left-0 overflow-x-auto flex hide-scrollbar">
            {scoregrid.map((item, index) => (
              <div 
                className={`inline-flex mr-[3px] min-w-max bg-white bg-opacity-15 [font-family:'Chakra_Petch-Regular',Helvetica] font-normal text-white text-[14px] p-2 whitespace-nowrap
                            ${index===1 ? ('border-b-2 border-[#f73261] border-solid') : ( null )}`}
                key={index}
              >
                <span className="flex items-center pl-[5px] pr-[5px]">{item}</span>
              </div>
            ))}
          </div>
        </div>
        ) : ( null )}
        </>
      ) : ( 
        <>
        {startdate ? (
        <div className='absolute w-[100%]'>
          <div className="relative w-full h-[35px] top-[159px] left-0 overflow-x-auto flex hide-scrollbar">
          <div className="w-full !text-center bg-white bg-opacity-15 [font-family:'Chakra_Petch-Regular',Helvetica] font-normal pt-[10px] text-white text-[14px] whitespace-nowrap">
              Match starts {formattedDate}
            </div>
          </div>
        </div>
        ) : ( null )}
        </>
      )}

      {displayPartnerShip==='true' ? (
        <div className="flex absolute w-full top-[196px] h-[77px]">
          <div className='flex bg-white bg-opacity-15 w-[50%] h-[77px] mr-[3px] items-center'>
            <div className='w-[50px] ml-[5px] bg-white bg-opacity-15'>
              <PlayerImage teamName={teams} playerName="{partnership.bowler}" width='50px' height='50px' />
            </div>
            <div className="flex-grow relative ml-[5px] top-[-3px]">
              <div className="[font-family:'Chakra_Petch-Bold',Helvetica] text-white text-[14px] font-bold">
                {partnership.bowler}
              </div>
              <div className="[font-family:'Chakra_Petch-Regular',Helvetica] font-normal text-white text-[14px]">{partnership.bowlerover}</div> 
            </div>
            <div className="items-end w-[50px] text-right pr-[5px]">
              <span className="font-chakra bold text-white text-[22px] tracking-[0] leading-[15px]">{partnership.bowlerwickets}</span>
              <span className="[font-family:'Chakra_Petch-Bold',Helvetica] text-[14px]">/{partnership.bowlerruns}</span>
            </div>
            <div className={`absolute bottom-0 ${teams[0]===batting ? 'bg-[#32f78c]' : 'bg-[#f73261]'} w-[75px] h-[3px]`} style={{right: 'calc(50% + 3px)'}} />
          </div>
          <div className='flex bg-white bg-opacity-15 w-[50%] h-[77px] items-center'>
            <div className='w-[50px] ml-[5px] bg-white bg-opacity-15'>
              <PlayerImage teamName={teams} playerName={partnership.batter} width='50px' height='50px' />
            </div>
            <div className="flex-grow relative ml-[5px] top-[-3px]">
              <div className="[font-family:'Chakra_Petch-Bold',Helvetica] text-white text-[14px] font-bold">
                {partnership.batter}
              </div>
              <div className="[font-family:'Chakra_Petch-Regular',Helvetica] font-normal text-white text-[14px]">not out</div> 
            </div>
            <div className="items-end w-[50px] text-right pr-[5px]">
              <span className="font-chakra bold text-white text-[22px] tracking-[0] leading-[15px]">{partnership.batterruns}</span>
              <span className="[font-family:'Chakra_Petch-Bold',Helvetica] text-[14px]">/{partnership.batterballs}</span>
            </div>
          </div>
          <div className={`absolute bottom-0 right-0 ${teams[1]===batting ? 'bg-[#32f78c]' : 'bg-[#f73261]'} w-[75px] h-[3px]`} />
        </div>
      ) : ( null )}

      
      


      <div className="absolute h-[115px] top-0 left-0" style={{width: "calc(50% - 2px)"}}>
        <div className={`absolute w-[100%] h-[115px] top-0 left-0 bg-white ${teams[1]===batting ? 'opacity-90' : ''}`} />
        {teams[0]===batting ? (<div className="absolute w-[3px] h-[49px] top-0 right-0 bg-[#f73261]" />) : ( null )}
        <div className="absolute w-[100%] h-[74px] top-[5px] left-[7px]">
          <div className="absolute w-[100%] h-[74px] top-0 left-0">
            <div className="absolute top-[59px] left-[5px] font-chakra bold text-[#1b062f] text-[14px] tracking-[0] leading-[15px] max-w-[100%] whitespace-nowrap truncate">
              {teams[0]}
            </div>
            <ClubLogo teamName={teams[0]} />
          </div>
          <div className="absolute top-[11px] right-[20px] font-chakra bold text-[#1b062f] text-[26px] text-right tracking-[0] leading-[15px] whitespace-nowrap">
          {!prematch ? (
            <>
            {score[0]}
            </>
          ) : ( <>-</> )}
          </div>
        </div>
        {!prematch ? (
        <div className="absolute top-[83px] left-[12px] [font-family:'Chakra_Petch-Regular',Helvetica] font-normal text-[#1b062f] text-[14px] tracking-[0] leading-[normal]">
          {overs[0]} overs
        </div>
        ) : ( null )}
      </div>

      <div className={`absolute w-[50%] h-[115px] top-0 left-[50%] bg-white ${teams[0]===batting ? 'opacity-90' : ''}`}>
        {teams[1]===batting ? (<div className="absolute w-[3px] h-[49px] top-0 right-0 bg-[#f73261]" />) : ( null )}
        <div className="absolute w-[100%] h-[74px] top-[5px] left-[7px]">
          <div className="absolute w-[100%] h-[74px] top-0 left-0">
            <div className="absolute top-[59px] left-[5px] font-chakra bold text-[#1b062f] text-[14px] tracking-[0] leading-[15px] max-w-[100%] whitespace-nowrap truncate">
              {teams[1]}
            </div>
            <ClubLogo teamName={teams[1]} />
          </div>
          <div className="absolute top-[11px] right-[20px] font-chakra bold text-[#1b062f] text-[26px] text-right tracking-[0] leading-[15px] whitespace-nowrap">
          {!prematch ? (
            <>
            {score[1]}
            </>
          ) : ( <>-</> )}
          </div>
        </div>
        {!prematch ? (
          <div className="absolute top-[83px] left-[12px] [font-family:'Chakra_Petch-Regular',Helvetica] font-normal text-[#1b062f] text-[14px] tracking-[0] leading-[normal]">
            {overs[1]} overs
          </div>
        ): ( null )}
        {/*<img className="absolute w-px h-[115px] top-0 left-0 object-cover" alt="Line" src={line5} />*/}
      </div>
    </div>

  );
};
