import React, { useState, useEffect } from 'react';

export const useLiveMatchId = () => {
    const [liveMatchId, setLiveMatchId] = useState(null);
    const [startDate, setstartDate] = useState(null);

    async function fetchMatches() {
        let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
        const res = await fetch(`${process.env.API_URL}/fixturelist`, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY,
            },
        });
        const data = await res.json();
        const matches = data.matches || [];
        
        const liveMatchInProgress = matches.find(match => 
            match.sport === 4 && match.id.includes('LIVE') && match.status.includes('Match in Progress')
            && ( match.competition.toLowerCase().trim()===tournamentFilter.trim() || tournamentFilter==='') 
        );

        if (liveMatchInProgress) {
            setLiveMatchId(liveMatchInProgress.id);
            setstartDate(liveMatchInProgress.start);
            //console.log(liveMatchInProgress.id);
            return;
        }
        matches.sort((a, b) => new Date(a.start) - new Date(b.start));

        const firstMatch = matches.find(match => 
            match.sport === 4 && 
            match.id.includes('LIVE') && 
            (match.competition.toLowerCase().trim() === tournamentFilter.toLowerCase().trim() || tournamentFilter === '') &&
            new Date(match.start) > new Date()
        );
        setLiveMatchId(firstMatch ? firstMatch.id : null);
        setstartDate(firstMatch ? firstMatch.start : null);
    }

    useEffect(() => {
        fetchMatches();
    }, []);

    return {liveMatchId, startDate };
};
