import React from 'react';
import Modal from 'react-modal';
import { FaRegWindowClose } from "react-icons/fa";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    background: '#1b062f',
    textAlign: 'center'
  },
  overlay: {
    background: "rgba(11, 2, 18, 0.75)"
  }
};

const SpotifyModal = ({ isOpen, onRequestClose, embedUri }) => {
  const embedUrl = `https://open.spotify.com/embed/${embedUri}`;
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Spotify Podcast"
    >
      <div className='w-full text-right pb-[10px]'>
        <button onClick={onRequestClose} className="modal-close-button text-right"><FaRegWindowClose /></button>
      </div>
      <div>
        <iframe
          src={embedUrl}
          width="100%"
          height="380"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
          title="Spotify Player"
        ></iframe>
      </div>
    </Modal>
  );
};

export default SpotifyModal;
