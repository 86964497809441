import React, { useState, useEffect } from "react";
import { ChevronSharp2 } from "../icons/ChevronSharp2";
import { ClubLogo } from './ClubLogo';
import { DivWrapper } from "../components/DivWrapper";
import { IconArrowStem21 } from "../icons/IconArrowStem21";
import { IconFacebook } from "../components/IconFacebook";
import { IconInstagram } from "../components/IconInstagram";
import { IconYoutube } from "../components/IconYoutube";
import { LogoWrapper } from "../components/LogoWrapper";
import { useLiveMatchId } from "./Matches/useLiveMatchId";
import Cookies from 'js-cookie';

const Footer = () => {
  const [thisAccount, setthisAccount] = useState([]);
  const [usersession, setUsersession] = useState('');
  const [watchalong, setWatchalong] = useState(false);
  const [currentPage, setCurrentPage] = useState(window.location.href);


  const handleSignout = async () => {
    Cookies.remove('c8_session_token');
    window.location.href = currentPage;
  };
  async function getUser(session_token) {
    try {
      const response = await fetch(`${process.env.API_URL}/verifysession/${session_token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY,
        },
      });
  
      if (response.status === 200) {
        const data = await response.json();
        if (data) {
            setUsersession(session_token);
            data.verifychannel=data.verifychannel.trim();
            setthisAccount(data);
        }
      } else {
        setUsersession('');
        return null;
      }
    } catch (error) {
      setUsersession('');
      return null; 
    }
  }
  const fetch_settings = async () => {
    const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.API_KEY,
      },
    });
    if (response.status === 200) {
      const data = await response.json(); 
      setWatchalong(data.data[0].value.live);
    }
  };
  useEffect(() => {
    const session_token = Cookies.get('c8_session_token');
    getUser(session_token);
    fetch_settings();
    return () => {};
  }, []);
  return (
    <>
     <div className='bg-[#18062f] mt-[50px]'>
      <div id='sitefooter' className='mt-[20px] translate-y-[-50px]'> 
        <div className='w-full max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
          <div className='md:flex ml-[15px] mr-[15px] pb-[20px]'>
              <div id='col1' className='md:w-[370px] relative'>
                <div class="flex md:justify-start justify-center md:items-start items-center h-[100px] md:left-[15px]">
                  <LogoWrapper
                    className="bg-[url(/static/img/image-1.png)] !w-[100px] !h-[100px]"
                    to="/"
                  />
                </div>
                <div className="mt-[40px] mb-[20px] left-0 right-0 md:left-[15px] w-full flex flex-col items-center md:items-start text-center md:text-left [font-family:'Anton',Helvetica] font-normal text-transparent text-[24px] tracking-[0] leading-[26px]">
                  <div className="text-white uppercase">Insight, news and analysis, </div>
                  <div className="text-[#d2ff00] uppercase">one ball at a time.</div>
                </div>
                
                <div className={`mb-[20px] relative ${watchalong===false ? ('hidden') : ( null )}`}>
                  <a href={`/match`}>
                  <div className="w-[92%] md:w-[310px] h-[40px] top-[50px] left-[15px]">
                    <div className="h-[40px] border border-solid border-[#f73261]">
                      <div className="relative inline-block top-[9px] left-[120px] text-[14px] [font-family:'Anton',Helvetica] font-normal text-transparent ">
                        <span className="text-[#f73261]">LIVE</span>
                        <span className="text-black">&nbsp;</span>
                        <span className="text-white">WATCH-ALONG</span>
                      </div>
                      <div className="relative top-[-6px] left-[95px] w-[14px] h-[14px] rounded-[7px] border border-solid border-[#f7326199]">
                        <div className="relative w-[8px] h-[8px] top-[2px] left-[2px] bg-[#f73261] rounded-[4.2px]" />
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
              <div id='col2' className='md:pt-[125px] md:flex-grow'>
                <div className='md:flex'>
                  <div className='w-full md:w-[250px]'>
                    <div className='font-anton text-collection-1-c8-lime text-[24px]'>CRICKET8</div>
                    <div className='font-chakra text-[16px] text-white pt-[10px]'>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/' className='footerlink'>Home</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/match' className='footerlink'>Matches</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/games' className='footerlink'>Games</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/media' className='footerlink'>Media</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/news' className='footerlink'>News &amp; Analysis</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/about' className='footerlink'>About</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                      <div className='py-[10px]'><a href='/contact' className='footerlink'>Contact</a></div>
                      <div className='border-solid border-t-[1px] border-[#463859] w-full md:w-[230px]'></div>
                    </div>
                  </div>

                  {usersession==='' ? (
                    <div className='flex md:block mt-[33px]'>
                      <div className='flex-grow bg-[#d2ff00] h-[50px] md:w-[265px] text-center mr-[5px] md:mr-0'>
                        <a href='/signup'>
                          <div className='font-anton text-[#1b062f] text-[16px] pt-[17px]'>
                            SIGN UP
                          </div>
                        </a>
                      </div>
                      <div className='flex-grow md:mt-[10px] bg-[#d2ff00] h-[50px] md:w-[265px] text-center ml-[5px] md:ml-0'>
                        <a href='/login'>
                          <div className='font-anton text-[#1b062f] text-[16px] pt-[17px]'>
                            LOGIN
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className='flex md:block mt-[33px]'>
                      <div className='flex-grow bg-[#d2ff00] h-[50px] md:w-[265px] text-center mr-[5px] md:mr-0'>
                        <div className='font-anton text-[#1b062f] text-[16px] pt-[17px] cursor-pointer' onClick={handleSignout}>
                          SIGN OUT
                        </div>
                      </div>
                    </div>
                  )}


{/*               <div>
                    <div className='font-anton text-collection-1-c8-lime text-[24px]'>IPL 2024 TEAMS</div>
                    <div className='flex'>
                      <div>
                        <div className='font-chakra text-[16px] text-white pt-[10px]'>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='teams?team=Gujarat Titans' className='footerlink flex'>
                              <div><ClubLogo teamName="Gujarat Titans" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Gujarat Titans</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Chennai Super Kings' className='footerlink flex'>
                              <div><ClubLogo teamName="Chennai Super Kings" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Chennai Super Kings</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Lucknow Super Giants' className='footerlink flex'>
                              <div><ClubLogo teamName="Lucknow Super Giants" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Lucknow Super Giants</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Mumbai Indians' className='footerlink flex'>
                              <div><ClubLogo teamName="Mumbai Indians" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Mumbai Indians</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Rajasthan Royals' className='footerlink flex'>
                              <div><ClubLogo teamName="Rajasthan Royals" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Rajasthan Royals</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                        </div>
                      </div>

                      <div className='pl-[20px]'>
                        <div className='font-chakra text-[16px] text-white pt-[10px]'>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='teams?team=Royal Challengers Bengaluru' className='footerlink flex'>
                              <div><ClubLogo teamName="Royal Challengers Bengaluru" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Royal Challengers Bengaluru</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Kolkata Knight Riders' className='footerlink flex'>
                              <div><ClubLogo teamName="Kolkata Knight Riders" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Kolkata Knight Riders</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Punjab Kings' className='footerlink flex'>
                              <div><ClubLogo teamName="Punjab Kings" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Punjab Kings</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Delhi Capitals' className='footerlink flex'>
                              <div><ClubLogo teamName="Delhi Capitals" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Delhi Capitals</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Sunrisers Hyderabad' className='footerlink flex'>
                              <div><ClubLogo teamName="Sunrisers Hyderabad" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Sunrisers Hyderabad</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-[#463859] w-[50px] md:w-[230px]'></div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[10px] w-[100%] h-[44px] justify-between items-center bg-[rgba(255,255,255,0.15)]">
          <div className='w-[100%] max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
            <div className="flex-grow flex items-center pl-[15px] [font-family:'Chakra_Petch',Helvetica] font-normal text-white text-[12px] tracking-[0] leading-normal justify-between">
              <div className='mt-[0px]'>
                <a className='menuLink' href='/terms'>Terms</a>&nbsp;&nbsp; 
                <a className='menuLink' href='/privacy'>Privacy</a>&nbsp;&nbsp; 
                <a className='menuLink' href='/terms#cookies'>Cookies</a>&nbsp;&nbsp; 
                {/* <a className='menuLink' href='/sitemap.xml'>Sitemap</a>&nbsp;&nbsp; */}
              </div>

              <div id='social' className='w-[50%] flex justify-end'>
                <div className="flex relative right-0">
                  <a href='https://www.youtube.com/@Cricket8YT' target='_blank'>
                    <div className="w-[44px] h-[44px] ml-[3px] flex justify-center items-center bg-[rgba(255,255,255,0.2)] relative">
                      <IconYoutube className="relative h-[15px] w-[20px]" iconYoutube="/img/icon-youtube.svg" />
                    </div>
                  </a>
                  <a href='https://www.facebook.com/Cricket8Official' target="_blank">
                    <div className="w-[44px] h-[44px] ml-[3px] flex justify-center items-center bg-[rgba(255,255,255,0.2)] relative">
                      <IconFacebook className='relative' iconFacebook="/img/icon-facebook.svg" />
                    </div>
                  </a>
                  <a href='https://www.instagram.com/Cricket8official/' target="_blank">
                    <div className="w-[44px] h-[44px] ml-[3px] flex justify-center items-center bg-[rgba(255,255,255,0.2)] relative">
                      <IconInstagram className='relative' iconInstagram="/img/icon-instagram.svg" />
                    </div>
                  </a>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
    );
};

export default Footer;

