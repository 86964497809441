import React, { useEffect, useState, useRef } from "react";
import { IconArrowStem21 } from "../../icons/IconArrowStem21";
import { FaPlay } from "react-icons/fa";

export const LatestVideos = ({ maxVids = 20, offset = 0, style='full' }) => {
    const [newsArt, setNewsArt] = useState([]);
    function decodeHtmlEntities(str) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    }
    async function fetchNews() {
        try {
          const res = await fetch(`${process.env.API_URL}/videos/${maxVids}/${offset}`, {
            method: 'GET', 
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.API_KEY
            },
          });
          if (res.status === 200) {
            const data = await res.json(); 
            const na=[];
            data.videos.forEach(video => {
                let title=decodeHtmlEntities(video.item.snippet.title);
                let media=video.item.snippet.thumbnails.default.url;
                let bigmedia=video.item.snippet.thumbnails.high.url;
                const dateObject = new Date(video.item.snippet.publishedAt);
                const formattedDate = dateObject.toLocaleDateString('en-US', { day: '2-digit', month: 'long' }); 
                let dte=formattedDate;
                let link ="/video?videoId="+video.item.id.videoId;
                na.push({title: title, media: media, bigmedia: bigmedia, date: dte, link: link});
            })
            setNewsArt(na);

          } else {
            //console.error('Failed to fetch scorecard:', res.status);
          }
        } catch (error) {
          //console.log(error);
          //console.error('Network error:', error);
        }  
    }

    useEffect(() => {
        fetchNews();
    }, []);
          
    return (
      <>
      {style==='summary' ? (
        <>
        {newsArt.length>0 ? (
        <div className='w-full md:flex'>
          <div className='w-full md:w-[50%] md:mr-[25px]'>
            <a href={newsArt[0].link}>
              <div className='w-full flex'>
                <div className='bg-[#d2ff00] font-anton text-[#1b062d] text-[14px] px-[10px] py-[10px]'>VIDEO</div>
                <div className='flex-grow bg-[#fff] bg-opacity-15 font-chakra font-normal text-white text-right text-[14px] px-[10px] py-[10px]'>
                  {newsArt[0].date.toUpperCase()}
                </div>
              </div>
              <div className='my-[2px] relative'>
                <img src={newsArt[0].bigmedia} className='w-[100%] h-[350px] object-cover' />
                <div className='bg-white h-[50px] w-[50px] text-[12px] flex items-center justify-center text-[#1b062f] rounded-full pl-[5px] absolute top-[150px] left-[250px]'>
                  <FaPlay className='w-[50%] h-[50%]' />
                </div>
              </div>
              <div className='bg-[#fff] relative'>
                <div className='py-[20px] pl-[15px] pr-[35px] font-anton text-[34px] text-[#1b062f] truncate'>
                  {newsArt[0].title}
                </div>
                {/* <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                  <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                </div> */}
              </div>
            </a>
          </div>
          <div className='w-full md:w-[50%] md:ml-[25px]'>
            <div className='mt-[30px] md:mt-0'>
              <a href={newsArt[1].link}>
                <div className='w-full flex'>
                  <div className='bg-[#d2ff00] font-anton text-[#1b062d] text-[14px] px-[10px] py-[10px]'>VIDEO</div>
                  <div className='flex-grow bg-[#fff] bg-opacity-15 font-chakra font-normal text-white text-right text-[14px] px-[10px] py-[10px]'>
                      {newsArt[1].date.toUpperCase()}
                  </div>
                </div>
                <div className='w-full flex relative h-[100px] my-[2px]'>
                  <div className='w-[100px] h-[100px]'>
                    <img src={newsArt[1].media} className='w-[100px] h-[100px] object-cover' />
                    <div className='bg-white h-[25px] w-[25px] text-[12px] flex items-center justify-center text-[#1b062f] rounded-full pl-[3px] absolute top-[37px] left-[37px]'>
                        <FaPlay />
                    </div>
                  </div>
                  <div className='flex-grow bg-white h-[100px]'>
                    <div className='pt-[25px] pl-[15px] font-anton text-[22px] text-[#1b062f]'>
                      {newsArt[1].title}
                    </div>
                  </div>
                  {/* <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                    <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                  </div> */}
                </div>
              </a>
            </div>
            <div className='mt-[30px]'>
              <a href={newsArt[2].link}>
                <div className='w-full flex'>
                  <div className='bg-[#d2ff00] font-anton text-[#1b062d] text-[14px] px-[10px] py-[10px]'>VIDEO</div>
                  <div className='flex-grow bg-[#fff] bg-opacity-15 font-chakra font-normal text-white text-right text-[14px] px-[10px] py-[10px]'>
                      {newsArt[2].date.toUpperCase()}
                  </div>
                </div>
                <div className='w-full flex relative h-[100px] my-[2px]'>
                  <div className='w-[100px] h-[100px]'>
                    <img src={newsArt[2].media} className='w-[100px] h-[100px] object-cover' />
                    <div className='bg-white h-[25px] w-[25px] text-[12px] flex items-center justify-center text-[#1b062f] rounded-full pl-[3px] absolute top-[37px] left-[37px]'>
                        <FaPlay />
                    </div>
                  </div>
                  <div className='flex-grow bg-white h-[100px]'>
                    <div className='pt-[25px] pl-[15px] font-anton text-[22px] text-[#1b062f]'>
                      {newsArt[2].title}
                    </div>
                  </div>
                  {/* <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                    <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                  </div> */}
                </div>
              </a>
            </div>
            <div className='mt-[30px]'>
                <a href='/video'>
                  <div className='h-[50px] bg-[#d2ff00] w-full text-anton text-[16px] text-[#1b062d] text-center pt-[17px]'>
                    ALL VIDEO
                  </div>
                </a>
            </div>
          </div>
        </div>
        ) : ( null )}
        </>
      ) : (
        <div className='md:flex md:flex-wrap gap-4'>
            {newsArt.slice(0, maxArts).map((news,index) => (
                <div className='w-full md:w-[49%] relative'>
                    <a href={news.link}>
                    <img src={news.media} className='w-[100%] h-[350px] object-cover' />
                    <div className='absolute bottom-0 md:top-0 left-0 w-full h-full flex flex-col justify-between'>
                        <div className='absolute bottom-[31px] md:top-0 left-0 w-[50px] h-[30px] bg-white anton text-black text-14px pt-[8px] pl-[10px]'>
                            NEWS
                        </div>
                        <div className='absolute bottom-[31px] md:top-0 left-[50px] h-[30px] bg-[#1b062f] anton text-white text-14px pt-[8px] pl-[10px] pr-[10px]'>
                            {news.date}
                        </div>
                        <div className='absolute w-[90%] h-[30px] md:h-[38px] bottom-[1px] md:top-[30px] text-[16px] md:text-[20px] left-0 text-black p-2 bg-[#d2ff00] truncate'>
                            {news.title}    
                        </div>
                    </div>
                    </a>
                </div>
            ))}
        </div>
      )}
      </>
  );
};
