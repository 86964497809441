import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Charts } from "../../MatchDetail/Charts";
import { ClubLogo } from "../../ClubLogo";
import { PlayerImage } from "../../PlayerImg";
import { BiCricketBall, BiSolidCricketBall } from "react-icons/bi";
import { GiCoinflip } from "react-icons/gi";
import { BsCupHot, BsFillCloudRainHeavyFill } from "react-icons/bs";
import { MdOutlineSportsCricket } from "react-icons/md";
import { Logo } from "../../Logo";

export const Feed = React.memo(({ feed, fullComm, incomingEvent, selected, setSelected }) => {
  return (
            <div>
                {incomingEvent ? (
                    <div className='w-[95px] h-[25px] border-solid border-[#f73261] border-[1px] text-[#f73261] flex mb-[20px]'>
                        <div className="live-indicator-solid"></div>
                        <div className="live-indicator-solid"></div>
                        <div className="live-indicator-solid"></div>
                    </div>
                ) : ( null )}
                {(() => {
                    let lastBall=0;
                    return feed.map((item, index) => {
                        let tournament='', competitors='';
                        let batter='', bowler='';
                        let batterScore='';
                        let tossresult='';
                        let match_comment='';
                        let match_status='';
                        let matchtitle=item.scoreboard.scoreboard.find(item => item.name === "matchtitle")?.value;
                        let score =[{team:'',runs:'',wkts:'',overs:''},{team:'',runs:'',wkts:'',overs:''}];
                        let fo='',fb='';
                        if (matchtitle) {
                            competitors=matchtitle.split(",")[0];
                            tournament=matchtitle.split(",")[1].replace("Indian Premier League","IPL");
                            tossresult=item.scoreboard.scoreboard.find(item => item.name === "tossresult")?.value

                            fo=item.ball.split(".")[0];
                            fb=item.ball.split(".")[1];
                            let fi="inns"+item.inns.trim();
                            const comline = fullComm.find(item => item.inns === fi && item.over === parseInt(fo) && item.ball === parseInt(fb));
                            if (comline) {
                                batter=comline.ballTo;
                                bowler=comline.ballFrom;
                            }
                            match_comment = item.scoreboard.scoreboard.find(item => item.name === "match_comment")?.value
                            match_status = item.scoreboard.scoreboard.find(item => item.name === "status")?.value
                        }
                        const currentBatting = item.scoreboard.scoreboard.find(item => item.name === 'Current Batting Team')?.value;
                        const currentRuns = item.scoreboard.scoreboard.find(item => item.name === 'Current Innings Runs')?.value;
                        const currentWickets = item.scoreboard.scoreboard.find(item => item.name === 'Current Innings Wickets')?.value;

                        if (batter!=='') {
                            const striker = item.scoreboard.scoreboard.find(item => item.value === batter)?.name;
                            if (striker) {
                                if (striker.includes("Bat.1")) {
                                    batterScore = item.scoreboard.scoreboard.find(item => item.name === "Batsman1 Runs")?.value;  
                                } else {
                                    batterScore = item.scoreboard.scoreboard.find(item => item.name === "Batsman2 Runs")?.value;  
                                }
                            }
                        } else {
                            const bat1Strike = item.scoreboard.scoreboard.find(item => item.name === 'Batsman1 Strike')?.value;
                            const bat2Strike = item.scoreboard.scoreboard.find(item => item.name === 'Batsman2 Strike')?.value;
                            if (bat1Strike==='1') {
                                batter = item.scoreboard.scoreboard.find(item => item.name === currentBatting+"Bat.1")?.value;
                                batterScore = item.scoreboard.scoreboard.find(item => item.name === "Batsman1 Runs")?.value;
                            } else {
                                batter = item.scoreboard.scoreboard.find(item => item.name === currentBatting+"Bat.2")?.value;
                                batterScore = item.scoreboard.scoreboard.find(item => item.name === "Batsman2 Runs")?.value;
                            }
                            const bowler1 = item.scoreboard.scoreboard.find(item => item.name === 'bowler1name')?.value;
                            const bowler2 = item.scoreboard.scoreboard.find(item => item.name === 'bowler2name')?.value;
                            bowler=bowler1;
                            if (bowler==='') {
                                bowler=bowler2;
                            }
                        }
                        const battingScore = currentRuns+" / "+currentWickets;
                        const date = new Date(item.tstamp);
                        const timeString = date.toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false 
                        });

                        score[0].team=item.scoreboard.scoreboard.find(item => item.name === 'batting.1')?.value;
                        score[0].runs=item.scoreboard.scoreboard.find(item => item.name === 'runs.1')?.value;
                        score[0].wkts=item.scoreboard.scoreboard.find(item => item.name === 'wkts.1')?.value;
                        score[0].overs=item.scoreboard.scoreboard.find(item => item.name === 'overs.1')?.value;
                        score[1].team=item.scoreboard.scoreboard.find(item => item.name === 'batting.2')?.value;
                        score[1].runs=item.scoreboard.scoreboard.find(item => item.name === 'runs.2')?.value;
                        score[1].wkts=item.scoreboard.scoreboard.find(item => item.name === 'wkts.2')?.value;
                        score[1].overs=item.scoreboard.scoreboard.find(item => item.name === 'overs.2')?.value;
                        const teams=[item.scoreboard.scoreboard.find(item => item.name === 'home')?.value, item.scoreboard.scoreboard.find(item => item.name === 'away')?.value];

                        
                        let scorecard=item.scorecard;
                        let TotalW=0, TotalR=0;
                        let curIns='inns1';
                        if (scorecard!==null) {
                            curIns="inns"+scorecard.inns_now;
                            TotalW = scorecard[curIns]?.wkts ?? 0;
                            TotalR = scorecard[curIns]?.runs ?? 0;
                        }
                       

                        return (
                            <>
                            {item.result.includes('WICKET') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                        {/* <div className='absolute top-[0px] right-0'>{item.ball}</div> */}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            WICKET
                                        </div>
                                        <div className='h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] border-l-[0px] font-chakra text-[14px] pt-[10px] px-[10px] text-[#18062f] cursor-pointer' onClick={() => setSelected('scorecard')}>
                                            SCORECARD
                                            <img className="relative w-[14px] h-[8px] top-[-1px] left-[5px] svg-dark" alt="Vector stroke" src="/img/vector-stroke-dark.svg" />
                                        </div>
                                    </div>
                                    <div className='relative w-full h-[74px] md:h-[74px] bg-[#f5a6b8] font-anton text-[50px] text-center text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-[0px] pt-[12px] md:pt-[12px] overflow-hidden'>
                                        <div className='absolute top-[10px] left-[10px] font-chakra font-normal text-[16px]'>
                                            OVER {fo}.{fb.substring(0,1)}
                                        </div>
                                        <img
                                            className="absolute bottom-0 left-0 mix-blend-overlay object-cover w-[100%]"
                                            alt="Image"
                                            src="/img/image-49.png"
                                        />
                                        OUT!
                                    </div>
                                    <div className='flex w-full h-[50px] bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px]'>
                                        <div className='px-[20px] pt-[9px]'>
                                            <PlayerImage teamName={currentBatting} playerName={batter} width="40px" height="40px" />
                                        </div>
                                        <div className='font-chakra bold text-[14px] text-left text-[#18062f] pt-[20px]'>
                                            <span className='text-[#f73261] pr-[10px]'>OUT:</span>
                                            {batter}
                                            <span className='font-normal pl-[10px]'>({batterScore} runs)</span>
                                        </div>
                                    </div>
                                    <div className='flex w-full h-[50px] bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px]'>
                                        <div className='px-[20px] pt-[9px]'>
                                            <PlayerImage teamName={teams} playerName={bowler} width="40px" height="40px" />
                                        </div>
                                        <div className='font-chakra bold text-[14px] text-left text-[#18062f] pt-[20px]'>
                                            <span className='text-[#32f78c] pr-[10px]'>BOWLED:</span>
                                            {bowler}
                                            <span className='font-normal pl-[10px]'></span>
                                        </div>
                                    </div>
                                    <div className='flex w-full h-[50px] bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px]'>
                                        <div className='px-[20px] pt-[5px]'>
                                            <ClubLogo teamName={currentBatting} width="40px" height="40px" />
                                        </div>
                                        <div className='font-chakra bold text-[14px] text-left text-[#18062f] pt-[20px]'>
                                            {currentBatting}
                                            <span className='font-normal pl-[10px]'>{battingScore}</span>
                                        </div>
                                    </div>
                                </div>

                            ) : item.result.includes('Match Complete') ? (
                                <div>
                                    <div className='mb-[20px]'>
                                        <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                            <div className='absolute top-[4px] left-[-9px]'>
                                            <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                            </div>
                                            {timeString}
                                        </div>
                                        <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                            {feed.timestamp}
                                        </div>
                                        <div className='flex'>
                                            <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                                {tournament}
                                            </div>
                                        </div>
                                        <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                            <div className='w-full flex justify-center items-center text-[36px]'>
                                                <Logo className='h-[45px] w-[45px]' />
                                            </div>
                                            <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                                <div>That brings our match coverage to a close</div>
                                                <div className='font-normal text-[14px]'>Thanks for joining us</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-[20px]'>
                                        <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                            <div className='absolute top-[4px] left-[-9px]'>
                                            <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                            </div>
                                            {timeString}
                                        </div>
                                        <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                            {feed.timestamp}
                                        </div>
                                        <div className='flex'>
                                            <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                                Match Finished
                                            </div>
                                        </div>
                                        <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                            <div className='w-full text-center text-[36px]'>
                                                <BiSolidCricketBall />
                                            </div>
                                            <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                                  <div>{match_status}</div>
                                            </div>
                                            <div className='pt-[10px] w-full text-center font-chakra bold text-[16px] flex'>
                                                <div className='w-[50%] text-center font-normal'>
                                                    <div>{score[0].team}</div>
                                                    <div className='bold text-[20px] py-[5px]'>{score[0].runs}/{score[0].wkts}</div>
                                                    <div>{score[0].overs} overs</div>
                                                </div>
                                                <div className='w-[50%] text-center font-normal'>
                                                    <div>{score[1].team}</div>
                                                    <div className='bold text-[20px] py-[5px]'>{score[1].runs}/{score[1].wkts}</div>
                                                    <div>{score[1].overs} overs</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : item.result.includes('runs') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            BOUNDARY
                                        </div>
                                        <div className='h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] border-l-[0px] font-chakra text-[14px] pt-[10px] px-[10px] text-[#18062f] cursor-pointer' onClick={() => setSelected('scorecard')}>
                                            SCORECARD
                                            <img className="relative w-[14px] h-[8px] top-[-1px] left-[5px] svg-dark" alt="Vector stroke" src="/img/vector-stroke-dark.svg" />
                                        </div>
                                    </div>
                                    <div className='relative w-full h-[74px] md:h-[94px] bg-[#83f9b9] font-anton text-[50px] text-center text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-[0px] pt-[12px] md:pt-[22px] overflow-hidden'>
                                        <div className='absolute top-[10px] left-[10px] font-chakra font-normal text-[16px]'>
                                            OVER {fo}.{fb.substring(0,1)}
                                        </div>
                                        <img
                                            className="absolute bottom-0 left-0 mix-blend-overlay object-cover w-[100%]"
                                            alt="Image"
                                            src="/img/image-49.png"
                                        />
                                        {item.result.includes('4 runs') ? ( <span>4!</span> ) : item.result.includes('6 runs') ? ( <span>6!</span> ) : ( null )}
                                    </div>
                                    <div className='flex w-full h-[50px] bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px]'>
                                        <div className='px-[20px] pt-[9px]'>
                                            <PlayerImage teamName={currentBatting} playerName={batter} width="40px" height="40px" />
                                        </div>
                                        <div className='font-chakra bold text-[14px] text-left text-[#18062f] pt-[20px]'>
                                            {batter}
                                            <span className='font-normal pl-[10px]'>({batterScore} runs)</span>
                                        </div>
                                    </div>
                                    <div className='flex w-full h-[50px] bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px]'>
                                        <div className='px-[20px] pt-[5px]'>
                                            <ClubLogo teamName={currentBatting} width="40px" height="40px" />
                                        </div>
                                        <div className='font-chakra bold text-[14px] text-left text-[#18062f] pt-[20px]'>
                                            {currentBatting} 
                                            <span className='font-normal pl-[10px]'>{battingScore}</span>
                                        </div>
                                    </div>
                                </div>
                                
                            ) : item.result.includes('START') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            {tournament}
                                        </div>
                                    </div>
                                    <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                        <div className='w-full text-center text-[36px]'>
                                            <BiCricketBall />
                                        </div>
                                        <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                            {competitors}
                                        </div>
                                        <div className='w-full text-center font-chakra text-[16px]'>
                                            Welcome to Cricket8's coverage of the {tournament}
                                        </div>
                                    </div>
                                </div>
                            ) : item.result.includes('TOSS') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            {tournament}
                                        </div>
                                    </div>
                                    <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                        <div className='w-full text-center text-[36px]'>
                                            <GiCoinflip />
                                        </div>
                                        <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                            {tossresult}
                                        </div>
                                    </div>
                                </div>
                            ) : item.result.includes('Drinks') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            {tournament}
                                        </div>
                                        <div className='h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] border-l-[0px] font-chakra text-[14px] pt-[10px] px-[10px] text-[#18062f]'>
                                            {battingScore}
                                        </div>
                                    </div>
                                    <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                        <div className='w-full text-center text-[36px]'>
                                            <BsCupHot />
                                        </div>
                                        <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                            Drinks
                                        </div>
                                    </div>
                                </div>
                            ) : item.result.includes('Rain') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            {tournament}
                                        </div>
                                        <div className='h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] border-l-[0px] font-chakra text-[14px] pt-[10px] px-[10px] text-[#18062f]'>
                                            {battingScore}
                                        </div>
                                    </div>
                                    <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                        <div className='w-full text-center text-[36px]'>
                                            <BsFillCloudRainHeavyFill />
                                        </div>
                                        <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                            Rain Delay
                                        </div>
                                    </div>
                                </div>
                            ) : item.result.includes('Innings Complete') ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] font-anton text-[#18062f] text-[14px] pt-[10px] pl-[10px]'>
                                            {tournament}
                                        </div>
                                    </div>
                                    <div className='w-full bg-[#fff] border-solid border-[#18062f] border-[1px] border-t-[0px] text-[#18062f] py-[20px]'>
                                        <div className='w-full text-center text-[36px]'>
                                            <MdOutlineSportsCricket />
                                        </div>
                                        <div className='pt-[10px] w-full text-center font-chakra bold text-[16px]'>
                                            <div>End of innings</div>
                                            <div>{match_comment}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : item.result.includes('SCORECARD') && scorecard!==null ? (
                                <div className='mb-[20px]'>
                                    <div className="relative opacity-30 font-anton text-[#18062f] text-[14px] my-[5px] pl-[5px]">
                                        <div className='absolute top-[4px] left-[-9px]'>
                                        <div className="w-[8px] h-[4px] top-0 left-0 bg-[#18062f]" />
                                        </div>
                                        {timeString}
                                    </div>
                                    <div className="opacity-30 font-anton text-[#18062f] text-[14px] my-[5px]">
                                        {feed.timestamp}
                                    </div>
                                    <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]">
                                        <div className='flex-grow pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>BOWLING</div>
                                        <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>W</div>
                                        <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>R</div>
                                        <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>OV</div>
                                        <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>NB</div>
                                        <div className='pt-[7px] text-center w-[40px]'>WD</div>
                                    </div>
                                    {scorecard[curIns].bowling.map((bowl,idx) => (
                                        <>
                                        {bowl.name!=='' ? (
                                        <div className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                                            <div className={`flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px] border-r-[#c5c2c8]`}>
                                                {bowl.name}
                                            </div>
                                            <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                                                {bowl.wickets}
                                            </div>
                                            <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                                {bowl.runs}
                                            </div>
                                            <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                                {bowl.overs}
                                            </div>
                                            <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                                {bowl.nb}
                                            </div>
                                            <div className='w-[40px] py-[10px] text-center'>
                                                {bowl.wd}
                                            </div>
                                        </div>
                                        ) : (null)}
                                        </>
                                    ))}
                                    <div className='pt-[20px]'>
                                        <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[10px] md:text-[14px] flex border-solid border-[#18062f] border-[1px]">
                                            <div className='flex-grow pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>BATTING</div>
                                            <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>R</div>
                                            <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>B</div>
                                            <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>4s</div>
                                            <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>6s</div>
                                            <div className='pt-[7px] text-center w-[30px] md:w-[40px]'>SR</div>
                                        </div>
                                        {scorecard[curIns].batting.map((bat,idx) => {
                                            if (bat.name!=='') {
                                                return (
                                                    <div className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                                                        <div className={`flex-grow py-[10px] pl-[10px] !text-left bold`}>
                                                            {bat.name}
                                                        </div>
                                                        <div className='w-[70px] md:w-[250px] py-[10px] !text-left border-solid border-[#c5c2c8] border-r-[1px]'>
                                                            {bat.status}
                                                        </div>
                                                        <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                                                            {bat.runs}
                                                        </div>
                                                        <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                                            {bat.balls}
                                                        </div>
                                                        <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                                            {bat.fours}
                                                        </div>
                                                        <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                                            {bat.sixes}
                                                        </div>
                                                        <div className='w-[30px] md:w-[40px] py-[10px] text-center'>
                                                            {bat.balls!=='0' ? (
                                                                <>{Math.round((bat.runs/bat.balls)*100)}</>
                                                            ) : ( null )}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                        <div className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                                            <div className='flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px]'>
                                                Extras <span className='font-normal'>(
                                                        {scorecard[curIns].extras.nb} nb,&nbsp;
                                                        {scorecard[curIns].extras.wd} w,&nbsp;
                                                        {scorecard[curIns].extras.lb} lb,&nbsp;
                                                        {scorecard[curIns].extras.b} b)</span>
                                            </div>
                                            <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                                                {scorecard[curIns].extras.nb+scorecard[curIns].extras.wd+scorecard[curIns].extras.lb+scorecard[curIns].extras.b}
                                            </div>
                                            <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                                            <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                                            <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                                            <div className='w-[30px] md:w-[40px] py-[10px] text-center'></div>
                                        </div>
                                        <div className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                                            <div className='flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px] text-[18px]'>
                                                TOTAL 
                                            </div>
                                            <div className='w-[90px] md:w-[120px] py-[10px] pl-[10px] !text-left bold'>
                                                {TotalR}/{TotalW}
                                            </div>
                                            <div className='w-[60px] md:w-[80px] py-[10px] text-center'>{fo} OVERS</div>
                                        </div>                                    
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] border-t-[0px] font-chakra text-[14px] pt-[10px] px-[10px] text-[#18062f] cursor-pointer' onClick={() => setSelected('scorecard')}>
                                            SCORECARD
                                            <img className="relative w-[14px] h-[8px] top-[-1px] left-[5px] svg-dark" alt="Vector stroke" src="/img/vector-stroke-dark.svg" />
                                        </div>
                                    </div>
                                </div>
                            ) : ( null )}
                            </>
                        )
                    });
                })()}
      
            </div>
  );
});
export default Feed;
