import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import SpotifyModal from './SpotifyModal';  
import { IconArrowStem21 } from "../../icons/IconArrowStem21";
import { FaPlay } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa6";

export const Podcasts = () => {
  const [podList, setPodList] = useState([]);
  const [selectedPodcastUri, setSelectedPodcastUri] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const goBack = () => navigate('/media');

  const xlocation = useLocation();
  const queryParams = new URLSearchParams(xlocation.search);
  const podId = queryParams.get("id");

  function decodeHtmlEntities(str) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  }

  async function fetchPodcasts() {
    try {
      const res = await fetch(`${process.env.API_URL}/podcasts/50`, {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY
        },
      });
      if (res.status === 200) {
        const data = await res.json(); 
        const na = [];
        data.items.forEach(podcast => {
          let title = decodeHtmlEntities(podcast.name);
          let media = podcast.images[1].url;
          let bigmedia = podcast.images[0].url;
          const dateObject = new Date(podcast.release_date);
          const formattedDate = dateObject.toLocaleDateString('en-US', { day: '2-digit', month: 'long' }); 
          let dte = formattedDate;
          let link = podcast.type + "/" + podcast.id;
          na.push({ title: title, media: media, bigmedia: bigmedia, date: dte, link: link });
        });
        setPodList(na);

        // Check for podId after setting the podcast list
        if (podId) {
          handlePodcastClick("episode/" + podId);
        }
      } else {
        console.error('Failed to fetch podcasts:', res.status);
      }
    } catch (error) {
      console.log(error);
    }  
  }

  useEffect(() => {
    fetchPodcasts();
  }, []);

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "Podcasts | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  const handlePodcastClick = (uri) => {
    setSelectedPodcastUri(uri);
    setIsModalOpen(true);
  };

  return (
    <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[20px] mb-[100px]'>
      <div className='flex pb-[10px] cursor-pointer' onClick={goBack} >
          <div className='h-6 bg-[#d2ff00] text-[#1b062f] pt-[4px] px-[6px]'><FaChevronLeft /></div>
          <div className='h-6 font-anton text-[#1b062f] text-[14px] px-[8px] pt-[6px] ml-[3px] bg-[#d2ff00]'>BACK</div>
      </div>
      <h1 className='text-[30px] md:text-[55px]'>PODCASTS</h1>

      <div className='mt-[30px] w-full md:flex md:flex-wrap'>
        {podList.map((pod, index) => (
          <div 
            key={index} 
            className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-[10px] cursor-pointer' 
            onClick={() => handlePodcastClick(pod.link)}
          >
            <div className='w-full text-right bg-[#4b3265] text-white text-[14px] p-[10px]'>{pod.date}</div>
            <div className='relative'>
              <img src={pod.bigmedia} className='w-[100%] h-auto object-cover' />
              <div className='bg-white h-[50px] w-[50px] text-[12px] flex items-center justify-center text-[#1b062f] rounded-full pl-[5px] absolute top-[45%] left-[44%]'>
                  <FaPlay className='w-[50%] h-[50%]' />
              </div>
            </div>
            <div className='relative w-full h-[72px] text-left bg-white text-[#1b062f] text-[18px] p-[10px] flex'>
              <div className='w-[100%] line-clamp-3'>{pod.title}</div>
             {/*  <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
              </div> */}
            </div>
          </div>
        ))}
      </div>

      <SpotifyModal 
        isOpen={isModalOpen} 
        onRequestClose={() => setIsModalOpen(false)} 
        embedUri={selectedPodcastUri} 
      />
    </div>
  );
};
