import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import ShareModal from "../ShareModal";
import { IoMdShare } from "react-icons/io";
import { LatestVideos } from "../LastestVideos";
import { LatestPodcasts } from "../LatestPodcasts";

export const Media = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const xlocation = useLocation();
    const url = window.location.href;
    const queryParams = new URLSearchParams(xlocation.search);

    useEffect(() => {
        const updatePageTitle = () => {
          document.title = "Media | Cricket8";
        };
        updatePageTitle();
        return () => {
        };
    }, []);
    
    return (
        <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[40px]'>
            <div className='font-anton text-[30px] text-white my-[20px]'>LATEST VIDEOS</div>
            <div className='mt-[10px] min-h-[303px]'>
                <LatestVideos maxVids={3} style='summary' />
            </div>
            <div className='font-anton text-[30px] text-white mt-[40px] mb-[20px]'>LATEST PODCASTS</div>
            <div className='mt-[10px] min-h-[303px]'>
                <LatestPodcasts maxVids={3} style='summary' />
            </div>
        </div>
    )
};