/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconArrowStem21 = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="14"
      viewBox="0 0 22 14"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M16.2058 6.50937L12.5398 2.84337L13.8597 1.52344L19.7994 7.46314L13.8597 13.4028L12.5398 12.0829L16.2467 8.37603L1.52344 8.37584L1.52346 6.50918L16.2058 6.50937Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

IconArrowStem21.propTypes = {
  color: PropTypes.string,
};
