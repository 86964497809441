import React, { useState, useRef, useEffect } from "react";
import { IconArrowStem21 } from "../icons/IconArrowStem21";

export const Settings = () => {
    const [status, setStatus] = useState('Loading...');
    const pwRef = useRef(null);
    const [password, setPassword] = useState(false);
    const [isLive, setIsLive] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [standings, setStandings] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [updatingWatch, setUpdateingWatch] = useState(false);
    const [channelStatus ,setChannelStatus] = useState([]);
    const [refreshing, setRefreshing] = useState(true);
    const [upcoming, setUpcoming] = useState([]);

    async function fetchFixtures() {
        let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
        let maxDisplay=20;
        const res = await fetch(process.env.API_URL+'/fixturelist', {
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.API_KEY
          },
        });
        const data = await res.json();
        const mData=data.matches;
        let matchBuild=[];
        mData.forEach(match => {
          if (match.sport===4) {
            if (match.id.includes('LIVE')) {
              if (match.competition.toLowerCase().trim()===tournamentFilter.trim() || tournamentFilter.trim()==='') {
                match.competition=match.competition.replace('Indian Premier League','IPL 2024');
                let matchStart = new Date(match.start);
                let today = new Date();
                match.localTime = matchStart.toLocaleString('en-US', {
                  weekday: 'short', 
                  hour: 'numeric', 
                  hour12: true 
                }).toUpperCase().replace(",","");
                let diff=matchStart-today;
                let name=match.name.split(",");
                let teams=name[0].split(" v ");
                match.playing=name[0];
                if (match.status.substring(0,17)==='Match in Progress' 
                    || match.status.substring(0,13)==='Break in Play' 
                    || match.status.substring(0,16)==='Innings Complete'
                    || match.status.substring(0,8)==='Play to '
                    || match.status.substring(0,12)==='Toss Pending'
                    || diff>0) { 
                        if (match.watchalong===null) {
                            match.upcomingwatchalong=false;
                        } else {
                            match.upcomingwatchalong=true;
                        }
                        matchBuild.push(match);
                }
              }
            }
          }
        });
        setUpcoming(matchBuild);
    };
    const handlePassword = async () => {
        const pw = pwRef.current.value;
        if (pw === process.env.SETTINGS_PASSWORD) {
            setPassword(true);
            getChannelStatus();
            fetchFixtures();
        }
    };

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': process.env.API_KEY, 
    };

    const fetch_settings = async () => {
        const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
            method: 'GET',
            headers: headers,
        });
        if (response.status === 200) {
            const data = await response.json();
            setIsLive(data.data[0].value.live);
            setStartTime(data.data[0].value.starting);
        }
    };

    const fetch_standings = async () => {
        const response = await fetch(`${process.env.API_URL}/standings`, {
            method: 'GET',
            headers: headers,
        });
        if (response.status === 200) {
            const data = await response.json();
            setStandings(data.data);
        }
    }
        
    useEffect(() => {
        fetch_settings();
        fetch_standings();
    }, []);

    const saveSettings = async () => {
        const response = await fetch(`${process.env.API_URL}/settings/update`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                live: isLive,
                starting: startTime,
            }),
        });
        if (response.ok) {
            alert("Update complete!")
        } else {
            console.error('Failed to save settings');
        }
    };
    const saveWatch = async () => {
        setUpdateingWatch(true);
        let saveData=[];
        upcoming.forEach((u,i) => {
            saveData.push({id:u.id, watchalong: u.upcomingwatchalong});
        });
        const response = await fetch(`${process.env.API_URL}/watchalong/update`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                saveData: saveData
            }),
        });
        if (response.ok) {
            alert("Update complete!")
        } else {
            alert("Update failed!")
        }
        setUpdateingWatch(false);

    };
    
    const getChannelStatus = async () => {
        setRefreshing(true);
        const response = await fetch(`${process.env.API_URL}/mediaserver/status`, {
            method: 'GET',
            headers: headers,
        });
        if (response.status === 200) {
            const data = await response.json();
            setChannelStatus(data);
            setRefreshing(false);
        }
    };
    const startChannel = async () => {
        const response = await fetch(`${process.env.API_URL}/mediaserver/start`, {
            method: 'GET',
            headers: headers,
        });
        if (response.status === 200) {
            const data = await response.json();
            alert("Server is starting, this can take up to 60 seconds to complete. Press 'Refresh' to check status.");
            getChannelStatus();
        }
    };
    const stopChannel = async () => {
        const response = await fetch(`${process.env.API_URL}/mediaserver/stop`, {
            method: 'GET',
            headers: headers,
        });
        if (response.status === 200) {
            const data = await response.json();
            alert("Server is stopping, this can take up to 60 seconds to complete. Press 'Refresh' to check status.");
            getChannelStatus();
        }
    };

    const saveStandings = async () => {
        setUpdating(true);
        const response = await fetch(`${process.env.API_URL}/standings/update`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                standings: standings,
            }),
        });
        if (response.ok) {
            alert("Update complete!")
            setUpdating(false);
        } else {
            console.error('Failed to save settings');
        }
    };

    const handleStandingChange = (idx, field, value) => {
        setStandings(currentStandings => {
        const updatedStandings = [...currentStandings];
        updatedStandings[idx] = { ...updatedStandings[idx], [field]: value };
        return updatedStandings;
        });
    };
    
    const handleWatchalongChange = (index, watchalong) => {
        setUpcoming(currentUpcoming => {
            return currentUpcoming.map((match, idx) => {
                if (idx === index) {
                    return { ...match, upcomingwatchalong: watchalong };
                }
                return match;
            });
        });
    };
  
    return (
        <div id="content" className="min-h-[75vh] mt-[20px]">
            <h2 className="text-[14px] md:text-[20px]">Cricket8</h2>
            <h1 className="text-[30px] md:text-[55px]">Settings</h1>

            {password !== true ? (
                <div className="relative mt-[30px] w-[590px] h-[40px]">
                    <div className="absolute w-[300px] h-[40px] bg-[#4c3368] border border-solid border-[#fff]">
                        <input ref={pwRef} className="enteremail ml-[20px]" type="password" placeholder="enter password" />
                    </div>
                    <div className="absolute w-[40px] h-[40px] left-[300px] bg-[#4c3368] border border-solid border-[#fff]">
                        <div className="relative top-[10px] left-[8px]" onClick={handlePassword}>
                            <IconArrowStem21 className="cursor-pointer !w-[22px] !h-[14px]" color="#fff" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='mt-[30px] font-chakra text-[#fff]'>
                    
                    <div className='mt-[30px]'>
                        WATCH A LONG LIVE
                    </div>
                    <div>
                        <input name='watch' type='radio' value='yes' checked={isLive === true} onChange={() => setIsLive(true)} /> Yes
                        <input name='watch' type='radio' value='no' checked={isLive === false} onChange={() => setIsLive(false)} /> No
                    </div>

                    <div className='mt-[30px]'>
                        NEXT START TIME
                    </div>
                    <div>
                        <input type='text' value={startTime} onChange={(e) => setStartTime(e.target.value)} placeholder='yyyy-mm-dd hh:mm:ss' />
                    </div>
                    <div className='mt-[30px]'>
                        <button onClick={saveSettings}>SAVE</button>
                    </div>

                    <div className='mt-[30px]'>
                        <hr />
                    </div>
                    <div>
                        <h2>AWS MEDIA SERVER</h2>
                    </div>
                    <div className='mt-[20px] mb-[80px]'>
                        <p className='pb-[20px]'>Channel Id: {channelStatus.Id}</p>
                        <p className='pb-[20px]'>Channel Status: {channelStatus.State}</p>
                        <div className='flex'>
                        <div className={`${!refreshing ? ('bg-[#d2ff00] text-[#18062f] cursor-pointer') : ('border-[1px] border-solid border-[#ffff9f] text-[#ffff9f] cursor-not-allowed')} h-[25px] pt-[5px] text-center w-[80px] mr-[20px]`} onClick={getChannelStatus}>Refresh</div>
                            <div className={`${channelStatus.State==='IDLE' ? ('bg-[#d2ff00] text-[#18062f] cursor-pointer') : ('border-[1px] border-solid border-[#ffff9f] text-[#ffff9f] cursor-not-allowed')} h-[25px] pt-[5px] text-center w-[110px] mr-[20px]`} onClick={startChannel}>Start Server</div>
                            <div className={`${channelStatus.State==='RUNNING' ? ('bg-[#d2ff00] text-[#18062f] cursor-pointer') : ('border-[1px] border-solid border-[#ffff9f] text-[#ffff9f] cursor-not-allowed')} h-[25px] pt-[5px] text-center w-[110px] mr-[20px]`} onClick={stopChannel}>Stop Server</div>
                        </div>
                    </div>

                    <div className='mt-[30px]'>
                        <hr />
                    </div>
                    <div>
                        <h2>IPL 2024 STANDINGS</h2>
                    </div>

                    <div className='mt-[20px] mb-[80px]'>


                        <div className="w-full h-[30px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]">
                            <div className='flex-grow pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>TEAM</div>
                            <div className='pt-[7px] text-center w-[80px] border-solid border-[#18062f] border-r-[1px]'>SHORT</div>
                            <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>M</div>
                            <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>W</div>
                            <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>L</div>
                            <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>PTS</div>
                            <div className='pt-[7px] text-center w-[60px]'>NRR</div>
                        </div>

                        {standings.map((stand, idx) => (
                            <div className='flex h-[55px] font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'>
                                <div className='flex-grow pt-[20px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>{stand.team_name}</div>
                                <div className='pt-[20px] text-center w-[80px] border-solid border-[#18062f] border-r-[1px]'>{stand.team_short}</div>
                                <div className='pt-[17px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>
                                    <input
                                        className='standingInput'
                                        type='text'
                                        value={stand.m}
                                        onChange={(e) => handleStandingChange(idx, 'm', e.target.value)}
                                    />
                                </div>
                                <div className='pt-[17px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>
                                    <input
                                        className='standingInput'
                                        type='text'
                                        value={stand.w}
                                        onChange={(e) => handleStandingChange(idx, 'w', e.target.value)}
                                    />
                                </div>
                                <div className='pt-[17px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>
                                    <input
                                        className='standingInput'
                                        type='text'
                                        value={stand.l}
                                        onChange={(e) => handleStandingChange(idx, 'l', e.target.value)}
                                    />
                                </div>
                                <div className='pt-[17px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>
                                    <input
                                        className='standingInput'
                                        type='text'
                                        value={stand.pts}
                                        onChange={(e) => handleStandingChange(idx, 'pts', e.target.value)}
                                    />
                                </div>
                                <div className='pt-[17px] text-center w-[60px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>
                                    <input
                                        className='standingInput'
                                        type='text'
                                        value={stand.nrr}
                                        onChange={(e) => handleStandingChange(idx, 'nrr', e.target.value)}
                                    />
                                </div>
                            </div>
                        ))}

                        {!updating ? (
                        <div className='mt-[30px]'>
                            <button onClick={saveStandings}>UPDATE STANDINGS</button>
                        </div>
                        ) : ( null )}

                    </div>


                    <div className='mt-[30px]'>
                        <hr />
                    </div>
                    <div>
                        <h2>Upcoming matches / Watch-a-longs</h2>
                    </div>
                    <div className='mt-[20px] mb-[180px]'>

                        <div className="w-full h-[30px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]">
                            <div className='pt-[7px] pl-[10px] w-[200px] border-solid border-[#18062f] border-r-[1px]'>START</div>
                            <div className='pt-[7px] pl-[10px] text-left flex-grow border-solid border-[#18062f] border-r-[1px]'>NAME</div>
                            <div className='pt-[7px] pl-[10px] text-left w-[200px] border-solid border-[#18062f] border-r-[1px]'>COMP</div>
                            <div className='pt-[7px] text-center w-[150px]'>WATCH A LONG</div>
                        </div>

                        {upcoming.map((up, idx) => (
                            <div className='flex h-[55px] font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'>
                                <div className='pt-[20px] pl-[10px] w-[200px] border-solid border-[#18062f] border-r-[1px]'>{up.start.substring(0,16).replace("T"," ")} utc</div>
                                <div className='flex-grow pt-[20px] pl-[10px] text-left border-solid border-[#18062f] border-r-[1px]'>{up.playing}</div>
                                <div className='pt-[20px] pl-[10px] text-left w-[200px] border-solid border-[#18062f] border-r-[1px]'>{up.competition}</div>
                                <div className='pt-[17px] text-center w-[150px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>
                                    <div className='flex'>
                                        <div className='pl-[20px]'>
                                            <input
                                                name={`watchalong-${up.id}`}
                                                type='radio'
                                                value='yes'
                                                checked={up.upcomingwatchalong}
                                                onChange={() => handleWatchalongChange(idx, true)}
                                            /> Yes
                                        </div>
                                        <div className='pl-[20px]'>
                                            <input
                                                name={`watchalong-${up.id}`}
                                                type='radio'
                                                value='no'
                                                checked={!up.upcomingwatchalong}
                                                onChange={() => handleWatchalongChange(idx, false)}
                                            /> No
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {!updatingWatch ? (
                            <div className='mt-[30px]'>
                                <button onClick={saveWatch}>UPDATE WATCH-A-LONGS</button>
                            </div>
                        ) : ( null )}
                    </div>
                </div>

            )}
        </div>
    );
};
