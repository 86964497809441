/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const DivWrapper = ({ className }: Props): JSX.Element => {
  return (
    <div className={`w-[31px] h-[31px] bg-[url(/static/img/image-12-6.png)] bg-cover bg-[50%_50%] ${className}`} />
  );
};
