import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const Tournaments = React.forwardRef((props, ref) => {
    const [tourlist, setTourlist] = useState([]);
    async function getTournaments() {
        try {
            const response = await fetch(`${process.env.API_URL}/tournaments`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY,
              },
            });
    
            if (response.status === 200) {
                let tl=[];
                const data = await response.json();
                for (let i=0; i<data.length; i++) {
                    tl.push(data[i].tournaments);
                }
                setTourlist(tl);
                }
        } catch (error) {
            return null; 
        }
    }
    useEffect(() => {
        getTournaments();
    }, []);

    useEffect(() => {
        if (ref.current) {
            ref.current.value = props.selectedTournament; 
        }
    }, [ref, props.selectedTournament]);

    return (
        <div className='font-chakra text-[#18062f] !text-left text-[12px] md:text-[14px]'>
            <div className="select-wrapper !w-[100%]">
                <select
                    ref={ref}
                    id="tournament"
                    name="tournament"
                    className="dropdown !h-[40px]"
                    value={props.selectedTournament}
                    onChange={(e) => props.setSelectedTournament(e.target.value)}
                >
                    <option value="" disabled>Favourite tournament...</option>
                    {tourlist.map((tour, index) => (
                        <option key={index} value={tour}>
                            {tour}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
});
