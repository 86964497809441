import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClubLogo } from "../../ClubLogo";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

export const Worm = ({teams=['team1','team2'], scoregrid, maxovers}) => {
    function isEven(n) {
        return n % 2 == 0;
    }
    const [curIns, setCurIns] = useState("inns"+scoregrid.inns_now);
    let od, ev='';
    if (isEven(scoregrid.inns_now)) {
        ev=scoregrid.bat_now;
        od=scoregrid.bowl_now;
    } else {
        od=scoregrid.bat_now;
        ev=scoregrid.bowl_now;
    }

    let label=[];
    for (let i=1; i<=maxovers[0]; i++) {
        label.push(i);
    }
    let Cdata=[];
    for (let ins=1; ins<=4; ins++) {
        let inskey="inns"+ins;
        if (scoregrid.overs[inskey]) {
            let thisIns=[];
            let insTotal=0;
            scoregrid.overs[inskey].forEach((ov,idx) => {
                insTotal+=parseInt(ov.runs);
                thisIns.push(insTotal);
            })
            if (!isEven(ins)) {
                Cdata[ins-1]={
                    label: od,
                    data: thisIns,
                    backgroundColor: ["#32f78c"],
                    borderColor: "#32f78c",
                    borderWidth: 2,
                    pointStyle: false
                }
            } else {
                Cdata[ins-1]={
                    label: ev,
                    data: thisIns,
                    backgroundColor: ["#f73261"],
                    borderColor: "#f73261",
                    borderWidth: 2,
                    pointStyle: false
                }
            }
        }
    }
    const [chartData, setChartData] = useState({
        labels: label, 
        datasets: Cdata,
    });
    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            }
        },
        scales: {
            x: { 
                ticks: {
                    autoSkip: false,
                    font: {
                        family: "'Chakra Petch', Helvetica",
                        weight: 'normal',
                    },
                    callback: function(value, index) {
                        if (value === 0 || (value + 1) % 5 === 0) {
                            return value+1;
                        } else {
                            return '';
                        }
                    },
                    color: "#fff", 
                },
            },
            y: {
                ticks: {
                    color: "#fff",
                    font: {
                        family: "'Chakra Petch', Helvetica",
                        weight: 'normal',
                    }
                },
                border:{dash: [4, 4]},
                grid: {
                    color: '#aaa',
                    tickBorderDash: [2, 3],
                }
            }
        },
    };
    // ** changing end datapoint
    // useEffect(() => {
    //     const customizedDatasets = Cdata.map((dataset,idx) => {
    //         const dataLength = dataset.data.length;
    //         let pointStyles = new Array(dataLength).fill('circle');
    //         let pointRadii = new Array(dataLength).fill(1);

    //         if (dataLength > 0) {
    //             if (idx===0) {
    //                 pointStyles[dataLength - 2] = 'rect';
    //                 pointRadii[dataLength - 2] = 10;
    //             } else {
    //                 pointStyles[dataLength - 3] = 'rect';
    //                 pointRadii[dataLength - 3] = 10;
    //             }
    //         }
    //         return {
    //             ...dataset,
    //             pointRadius: pointRadii,
    //             pointStyle: pointStyles,
    //         };
    //     });
    //     setChartData(prevChartData => ({
    //         ...prevChartData,
    //         datasets: customizedDatasets,
    //     }));
    // }, [scoregrid, maxovers]);
  return (
    <div className='w-[350px]'>
        <div className="w-[100%] h-[30px] top-0 left-0 bg-[#d2ff00] font-anton text-[#18062f] font-normal text-[14px] pt-[8px] pl-[10px] border-solid border-[1px] border-[#18062f]">
            WORM
        </div>
        <div className='relative bg-[#1b062f] w-[100%] pt-[10px] pb-[10px]'>
            <div className='font-chakra text-[12px] absolute top-[12px] left-[8px]'>Runs</div>
            <div className='relative top-[10px] left-0'>
                <Line className='pl-[5px] pr-[5px]' data={chartData} options={chartOptions} />
            </div>

            <div className='font-chakra bold pt-[30px] ml-[5px] mr-[5px] flex'>
                <div className='w-[50%] pl-[5px] flex'>
                    <div className='bg-[#f73261] h-[14px] w-[14px] mr-[5px]' />
                    {ev}
                </div>
                <div className='w-[50%] pl-[5px] flex text-right'>
                    <div className='bg-[#32f78c] h-[14px] w-[14px] mr-[5px]' />
                    {od}
                </div>
            </div>
        </div>
    </div>
  );
};
