
import React from "react";

export const Spinner = () => {
  return (
    <div className='relative'>
        <div className='absolute top-0 left-0'>
            <img className='h-auto max-w-full' src='https://cricket8-web-content.s3.amazonaws.com/assets/spinner.png' />
        </div>
        <div className='absolute top-0 left-0'>
            <img className='h-auto max-w-full' src='https://cricket8-web-content.s3.amazonaws.com/assets/coin_spin1.png' />
        </div>
    </div>
  );
};

