import React, { useState, useRef, useEffect } from 'react';

export const AboutUs = () => {
  useEffect(() => {
    const updatePageTitle = () => {
        document.title = "ABOUT US | Cricket8";
    };
    updatePageTitle();
    return () => {
    };
  }, []);
  return (
    <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px]">
        <h2 className="text-[14px] md:text-[20px]">Cricket8</h2>
        <h1 className="text-[30px] md:text-[55px]">ABOUT US</h1>

        <div className='font-chakra font-[#fff] text-[14px] mt-[30px]'>

            <p className='pb-[20px]'>WELCOME TO CRICKET 8, WHERE WE BELIEVE CRICKET IS MORE THAN JUST A SPORTING GAME; IT&apos;S A COLLECTION OF INCREDIBLE 
STORIES WAITING TO BE SHARED. IN A WORLD THAT OFTEN GETS BOGGED DOWN BY STATS, WE&apos;RE HERE TO BRING THE HEART AND 
SOUL BACK INTO THE GAME THROUGH THE ART OF STORYTELLING.
            </p>
            <p className='pb-[20px]'>WHILE OTHERS MIGHT DROWN IN THE SEA OF DATA, WE TAKE YOU ON A SPECIAL JOURNEY BY CRAFTING CAPTIVATING STORIES 
AROUND CRICKET. THROUGH OUR ARTICLES, IMMERSIVE STORYTELLING, AND LIVELY MULTIMEDIA, OUR AIM IS TO GET YOU RIGHT 
INTO THE HEART OF THE CRICKETING WORLD, SPARKING THAT DEEP PASSION YOU HAVE FOR THE SPORT.
</p>
            <p className='pb-[20px]'>WE BELIEVE THAT CRICKET ISN&apos;T JUST A GAME, IT&apos;S A PASSION, A TRADITION, A WAY OF LIFE. JOIN US ON THIS INCREDIBLE JOURNEY 
AS WE UNFOLD HIDDEN TALES AND SHOWCASE CRICKET IN ALL ITS NARRATIVE GLORY. TOGETHER, LET&apos;S SHINE LIGHT INTO THE 
GAME AND REDISCOVER THE TIMELESS POWER OF STORYTELLING IN CRICKET.
</p>

        </div>

        <div className='mt-[30px] font-chakra font-[#fff] text-[14px]'>
            <ul className='list-disc pl-[25px]'>
                <li>WE CREATE AND PUBLISH ARTICLES, PODCASTS AND VIDEO ANALYSIS OF STATS AND DATA FOR THE GAME WE ADORE.</li>
                <li>OUR CONTENT IS CREATED ENTIRELY FOR YOUR ENTERTAINMENT AND IS COMPLETELY FREE OF CHARGE.</li>
                <li>WE DO NOT PROMOTE OR PROVIDE ANY BETTING SERVICES.</li>
                <li>WE ARE COMPLIANT WITH ALL DATA PROTECTION LAWS.</li>
            </ul>
        </div>

    </div>
  );
};
