import React, { useEffect, useState, useRef } from "react";
import { LatestNews } from "../LatestNews";
import { useNavigate, useLocation } from 'react-router-dom';
import ShareModal from "../ShareModal";
import { IoMdShare } from "react-icons/io";
import { FaChevronLeft } from "react-icons/fa6";

export const News = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const navigate = useNavigate();
    const goBack = () => navigate('/news');

    const xlocation = useLocation();
    const url = window.location.href;
    const queryParams = new URLSearchParams(xlocation.search);
    let art=queryParams.get("art");
    let artId=queryParams.get("id");
    let startat=queryParams.get("startat");
    if (startat===null) { startat=1; }
    const slug=queryParams.get("slug");
    if (slug===null) {artId=slug;}
    const [title, setTitle] = useState('NEWS & ANALYSIS');
    const [article, setArticle] = useState(null);

    async function fetchNewsArt(id, isSlug=false) {
        try {
            let uri=process.env.API_URL+"/news/article/"+id;
            if (isSlug) {
                uri=process.env.API_URL+"/news/articlebyslug/"+id;
            }
            const res = await fetch(uri, {
              method: 'GET', 
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY
              },
            });
            if (res.status === 200) {
                const data = await res.json();
                artId=data.id;
                setTitle(decodeURI(data.yoast_head_json.title));
                document.title=decodeURI(data.yoast_head_json.title);
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', decodeURI(data.yoast_head_json.description));
                } else {
                    const newMetaDescription = document.createElement('meta');
                    newMetaDescription.name = 'description';
                    newMetaDescription.content = decodeURI(data.yoast_head_json.description);
                    document.head.appendChild(newMetaDescription);
                }
                const ogImage = document.querySelector('meta[property="og:image"]');
                const imageUrl = data.yoast_head_json.og_image[0].url;
                if (ogImage) {
                    ogImage.setAttribute('content', imageUrl);
                } else {
                    const newOgImage = document.createElement('meta');
                    newOgImage.setAttribute('property', 'og:image');
                    newOgImage.content = imageUrl;
                    document.head.appendChild(newOgImage);
                }
                const regex = /<a href="https:\/\/news\.cricket8\.com\/(.*?)\/">/g;
                const updatedContent = data.content.rendered.replace(regex, (match, slug) => {
                    return `<a href="/news?slug=${slug}&id=null">`;
                });
                data.content.rendered=updatedContent;
                data.modified=data.modified.replace("T"," AT ");
                setArticle(data);
            } else {
              //console.error('Failed to fetch scorecard:', res.status);
            }
          } catch (error) {
            //console.error('Network error:', error);
          }  

    }

    useEffect(() => {
        if (artId!==null) {
            fetchNewsArt(artId);
        } else if (slug!==null) {
            fetchNewsArt(slug, true);
        }
        const updatePageTitle = () => {
          document.title = "NEWS & ANALYSIS | Cricket8";
        };
        updatePageTitle();
        return () => {
        };
      }, []);
    return (
        <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[40px]'>
            {artId===null ? ( null ) : (
                <div className='flex pb-[10px] cursor-pointer' onClick={goBack} >
                    <div className='h-6 bg-[#d2ff00] text-[#1b062f] pt-[4px] px-[6px]'><FaChevronLeft /></div>
                    <div className='h-6 font-anton text-[#1b062f] text-[14px] px-[8px] pt-[6px] ml-[3px] bg-[#d2ff00]'>BACK</div>
                </div>
            )}

            <div className='flex w-full'>
                <div className='flex-grow'>
                    <h1 className='text-[30px] md:text-[40px]'>{title}</h1>
                </div>
                {artId!==null ? (
                <div>
                    <button onClick={openModal} className='share-button'>
                        <div className='flex'>
                            <div className='btn'>Share</div>
                            <div className='bg-[#d2ff00] text-[18px] border border-1 border-solid border-[#1b062f] p-[10px] pt-[15px] cursor-pointer'>
                                <IoMdShare className='text-[#1b062f]' />
                            </div>
                        </div>                
                    </button>
                    <ShareModal isOpen={isModalOpen} onRequestClose={closeModal} url={url} />
                </div>
                ) : ( null )}
            </div>
           
            <div className='mt-[40px]'>
                {artId===null ? (
                    <LatestNews maxArts={10} startat={startat} />
                ) : (
                    <>
                    {article!==null ? ( 
                        <div className='blogpost normal-case'>
                            <div className='flex'>
                                <div className='flex-grow font-chakra text-[12px] text-white'>
                                    <div className='uppercase'>{article.yoast_head_json.twitter_misc["Est. reading time"]} read</div>
                                    <div>LAST UPDATED: {article.modified}</div>
                                </div>
                                <div className='text-right bg-white border border-solid border-1 border-[#d2ff00] text-[#1b062f] font-anton h-[25px] text-[18px] px-[10px] pt-[2px]'>
                                    NEWS
                                </div>
                            </div>
                            <div className='bg-white'>
                                <div className='mt-[30px]'>
                                    <img src={article.yoast_head_json.og_image[0].url} className='max-w-full' />
                                </div>
                                <div className='p-[10px]'>
                                    <div dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
                                </div>
                            </div>

                        </div>
                    ) : ( null )}
                    </>
                )}
            </div>
        </div>
    )
};