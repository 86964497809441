import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "../Spinner";

export const NewGameHero = () => {
    return (
        <div id='splash' className='absolute left-0 top-[121px] md:top-[156px] w-full h-[700px] md:h-[575px]'>
            
            <div className="md:hidden block bg-cover bg-center h-full bg-[#1d0833]">
                <div className='w-full max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
                    <div className='w-full text-center pt-[20px]'>
                        <div className='font-anton text-[#d2ff00] text-[40px] font-normal'>CRICKET8 GAMES</div>
                        <div className='font-anton text-[#fff] text-[40px] font-normal'>COMING SOON!</div>
                    </div>
                    <div className='w-full text-center p-[20px]'>
                        <img className='h-auto max-w-full max-h-[350px]' src='/img/spinner_large.png' />
                    </div>
                    <div className='w-full text-center bg-[#381660] p-[30px] font-chakra font-normal text-[16px] leading-5 text-[#fff]'>
                        <div>
                        Get ready for the world’s first social cricket trading game! To celebrate, we’re offering an incredible Grand Prize of 20,000 Coins to the player who collects the most Coins before our launch. Start collecting now!
                        </div>
                        <a href='/games'>
                                    <div className='bg-[#d2ff00] w-full h-[50px] text-[18px] font-anton text-[#1b062f] text-center pt-[16px] mt-[20px] cursor-pointer hover:bg-[#c2e809]'>
                                        SPIN FOR FREE COINS
                                    </div>
                                </a>
                    </div>


                </div>
            </div>
            
            <div className="hidden md:block bg-cover bg-center h-full" style={{ backgroundImage: `url(/img/image49b.png)` }}>
                <div className='w-full max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
                    <div className='w-full flex p-[15px]'>
                        <div className='w-[50%] pt-[50px]'>
                            <div className='font-anton text-[#d2ff00] text-[40px] lg:text-[70px] font-normal'>CRICKET8 GAMES</div>
                            <div className='font-anton text-[#fff] text-[40px] lg:text-[70px] font-normal'>COMING SOON!</div>
                            <div className='mt-[20px] bg-[#381660] w-[80%] p-[30px]'>
                                <div className='font-chakra font-normal text-[16px] text-[#fff] leading-5 text-center'>
                                    Get ready for the world’s first social cricket trading game! To celebrate, we’re offering an incredible Grand Prize of 20,000 Coins to the player who collects the most Coins before our launch. Start collecting now!
                                </div>
                                <a href='/games'>
                                    <div className='bg-[#d2ff00] w-full h-[50px] text-[18px] font-anton text-[#1b062f] text-center pt-[16px] mt-[20px] cursor-pointer hover:bg-[#c2e809]'>
                                        SPIN FOR FREE COINS
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='w-[45%] pt-[10px]'>
                            <img className='h-auto max-w-full' src='/img/spinner_large.png' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
};
