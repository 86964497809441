import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { MdClose } from "react-icons/md";

const getModalWidth = () => {
  if (window.innerWidth >= 768) {
    return '50%';
  } else {
    return '90%';
  }
};

const CustomModal = ({ isOpen, onRequestClose, contentLabel, children }) => {
  useEffect(() => {
    const updateWidth = () => {
      const modalContent = document.querySelector('.ReactModal__Content');
      if (modalContent) {
        modalContent.style.width = getModalWidth();
      }
    };

    window.addEventListener('resize', updateWidth);

    // Initial call to set width
    if (isOpen) {
      updateWidth();
    }

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#401a6e',
          width: getModalWidth(),
        },
        overlay: {
          background: "rgba(11, 2, 18, 0.75)"
        }
      }}
      ariaHideApp={false}
    >
      <button onClick={onRequestClose} style={{ float: 'right' }}><MdClose /></button>
      <div className='mt-[30px]'>{children}</div>
    </Modal>
  );
};

export default CustomModal;
