import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { BgTextureMobile } from "../components/BgTextureMobile";
import { FeedbackButton } from "../components/FeedbackButton";
import GetCurrentMatch from "../components/Data/GetCurrentMatch";

type LayoutProps = {
  children?: React.ReactNode;
};


const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='c8Page justify-center w-full'>
      <Header />
      <div id='mainMenu' className='w-full max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
        <main>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};
export default Layout;

