import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ClubLogo } from "../ClubLogo";

export const HeaderMatchCard = () => {
    const [output, setOutput] = useState([]);
    const [liveScore, setLiveScore] = useState(["",""]);
    const [liveBat, setLiveBat] = useState('');
    const shortTeams = {
        "Gujarat Titans": "GT",
        "Kolkata Knight Riders": "KKR",
        "Mumbai Indians": "MI",
        "Chennai Super Kings": "CSK",
        "Delhi Capitals": "DC",
        "Punjab Kings": "PBKS",
        "Lucknow Super Giants": "LSG",
        "Rajasthan Royals": "RR",
        "Sunrisers Hyderabad": "SRH",
        "Royal Challengers Bengaluru": "RCB"
    };
    const wsUrl = process.env.FEED_URL;
    const apiKey = process.env.DECIMAL_API_KEY;
    const navigate = useNavigate();

    async function fetchFinished(arrayBuild) {
        let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
        try {
            const res = await fetch(`${process.env.API_URL}/fixturelist/completed/0/25/All`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY
                },
            });
            if (res.status === 200) {
                const xdata = await res.json();
                let lastmatch=xdata.length-1;
                let hasLastMatch=false;
                while (hasLastMatch!==true) {
                    if (xdata[lastmatch].description.split(",")[1].toLowerCase().includes(tournamentFilter) || tournamentFilter==='') {
                        hasLastMatch=true;
                    } else {
                        lastmatch=lastmatch-1;
                        if (lastmatch<0) {
                            lastmatch=xdata.length-1;
                            hasLastMatch=true;
                        }
                    }
                }
                let tour=xdata[lastmatch].description.split(",")[1].replace("Indian Premier League","IPL 2024").trim();
                let teams=xdata[lastmatch].description.split(",")[0].split(" v ");
                let startTime="FINISHED";
                let ins1=xdata[lastmatch].scoregrid["inns1"].runs+"/"+xdata[lastmatch].scoregrid["inns1"].wkts;
                let ins2=xdata[lastmatch].scoregrid["inns2"].runs+"/"+xdata[lastmatch].scoregrid["inns2"].wkts;
                let score=["",""];
                if (xdata[lastmatch].scoregrid.bat_now===teams[0]) {
                    score=[ins2,ins1];
                } else {
                    score=[ins1,ins2];
                }
                let win='';
                if (xdata[lastmatch].status.indexOf(teams[0])>=0) {
                    win=teams[0];
                }
                if (xdata[lastmatch].status.indexOf(teams[1])>=0) {
                    win=teams[1];
                }
                let shortnames=[await getShortName(teams[0]), await getShortName(teams[1])];
                arrayBuild[2]={ id: xdata[lastmatch].match_id, tournament: tour, teams: teams, startTime: startTime, score: score, winner: win, shortnames: shortnames}
                setOutput(arrayBuild);

            }
        } catch (error) {
            console.error('Network error:', error);
        }
    }
            
    async function getLiveScore(match_id, teams) {
        let socket;
        if (process.env.ENVIRONMENT!=='dev') {
          socket = new WebSocket(`${wsUrl}`);
        } else {
          socket = new WebSocket(`${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`);
        }
        socket.addEventListener('open', function open() {
          const message = JSON.stringify({
            feed_filter: ['scorecard'],
            msg_type: "feed_subscription", 
            feed_id: match_id,
          });
          socket.send(message);
        });
      
        socket.addEventListener('message', function incoming(data) {
          const parsedData = JSON.parse(data.data);
          if (parsedData.scorecard) {


            let s1="0/0", s2="0/0";
            if (parsedData.scorecard.inns1!==null) {
                s1=parsedData.scorecard.inns1.runs+"/"+parsedData.scorecard.inns1.wkts;
            }
            if (parsedData.scorecard.inns2!==null) {
                s2=parsedData.scorecard.inns2.runs+"/"+parsedData.scorecard.inns2.wkts;
            }
            let insScore=[s1,s2];
            let innsnow=parsedData.scorecard.inns_now;
            let oppnow=1;
            if (innsnow===1) { oppnow=2; }
            setLiveBat(parsedData.scorecard.bat_now);
            if (parsedData.scorecard.bat_now===teams[0]) {
                setLiveScore([insScore[innsnow-1], insScore[oppnow-1]]);
            } else {
                setLiveScore([insScore[oppnow-1], insScore[innsnow-1]]);
            }
          }
        });

        socket.addEventListener('error', function error(error) {
          //console.error('WebSocket error:', error);
        });
      
        socket.addEventListener('close', function close(event) {
          //console.log('Disconnected from the WebSocket server:', event);
          //console.log(`Close code: ${event.code}, reason: ${event.reason}`);
        });
    }
    async function getShortName(teamname) {
        const res = await fetch(process.env.API_URL+'/teams/shortname/'+teamname, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY
            },
            });
            const data = await res.json();
            return data.shortname;
    }
    async function fetchData() {
        let arrayBuild=[];
        let backup='';
        let hasLive='';
        let hasLiveTeams=["",""];
        let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
        let maxDisplay=20;
        const res = await fetch(process.env.API_URL+'/fixturelist', {
        method: 'GET', 
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.API_KEY
        },
        });
        const data = await res.json();
        const mData=data.matches;
        let matchBuild=[];
        for(const match of mData) {
        //mData.forEach(match => {
        if (match.sport===4) {
            if (match.id.includes('LIVE')) {
                if (match.competition.toLowerCase().trim()===tournamentFilter.trim() || tournamentFilter.trim()==='') {
                    match.competition=match.competition.replace('Indian Premier League','IPL 2024');
                    let matchStart = new Date(match.start);
                    let today = new Date();
                    match.localTime = matchStart.toLocaleString('en-US', {
                      weekday: 'short', 
                      hour: 'numeric', 
                      hour12: true 
                    }).toUpperCase().replace(",","");
                    let diff=matchStart-today;
                    let name=match.name.split(",");
                    let teams=name[0].split(" v ");
                    if (match.status.substring(0,17)==='Match in Progress' 
                        || match.status.substring(0,13)==='Break in Play' 
                        || match.status.substring(0,16)==='Innings Complete'
                        || match.status.substring(0,8)==='Play to '
                        || match.status.substring(0,12)==='Toss Pending'
                    ) { 
                      match.localTime='LIVE'; 
                      if (!arrayBuild[1]) {
                        if (match.id==='1000149527LIVE2024') {
                            console.log(match);
                        }



                        let shortnames=[await getShortName(teams[0]), await getShortName(teams[1])];
                        arrayBuild[1]={ id: match.id, tournament: match.competition, teams: teams, startTime: match.localTime, score: ["",""], winner: '', shortnames: shortnames }
                        hasLive=match.id;
                        hasLiveTeams=teams;
                      } 
                    } else {
                        if (diff>0) {
                            let mm = Math.floor(diff / 1000 / 60);
                            let hh = Math.floor(diff / 1000 / 60 / 60);
                            let days = Math.floor(hh / 24);
                            if (days > 0) {
                            let remainingHours = hh % 24;
                            match.diff = days + "D " + remainingHours + "H";
                            } else if (hh > 0) {
                            match.diff = hh + "H";
                            } else {
                            match.diff = mm + "M";
                            }
                            matchStart.setHours(0, 0, 0, 0);
                            today.setHours(0, 0, 0, 0);
                            if (!arrayBuild[0]) {
                                let shortnames=[await getShortName(teams[0]), await getShortName(teams[1])];
                                arrayBuild[0]={ id: match.id, tournament: match.competition, teams: teams, startTime: match.localTime, score: ["",""], winner: '', shortnames: shortnames }
                            } else {
                                if (backup==='') {
                                    let shortnames=[await getShortName(teams[0]), await getShortName(teams[1])];
                                    backup={ id: match.id, tournament: match.competition, teams: teams, startTime: match.localTime, score: ["",""], winner: '', shortnames: shortnames }
                                }
                            }
                        }
                    }
                }
            }
        }
        };
        if (typeof arrayBuild[1] !=="object") {
            //arrayBuild[1]=arrayBuild[0];
            arrayBuild[1]=backup;
        }
        fetchFinished(arrayBuild);
        if (hasLive!=='') {
            getLiveScore(hasLive,hasLiveTeams);
        }
    }
    const  handleClick = (matchid, starttime) => {
        if (starttime==='LIVE') {
            let nav="/matchdetail?id="+matchid+"&status=false";
            navigate(nav);
        }
        if (starttime==='FINISHED') {
            let nav="/matchdetail?id="+matchid+"&status=true";
            navigate(nav);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
      <div id='headerMatch' className='flex gap-[10px]'>
        {output.map((match,index) => (
          <>
          <div className={`w-[180px] h-[70px] border 
                            ${match.startTime==='LIVE' ? ('border-[#f73261]') : ('border-[#9888ab]')} 
                            ${match.startTime==='LIVE' || match.startTime==='FINISHED' ? ('cursor-pointer') : ( null )} 
                            border-solid`
                         } onClick={() => handleClick(match.id, match.startTime)}>
                <div className='w-[174px] pl-[3px] text-center font-chakra bold text-[#d2ff00] text-[12px] mt-[5px] truncate text-ellipsis overflow-hidden'>
                        {match.tournament}
                </div>
                <div className='w-full mt-[5px] flex font-chakra bold text-white text-[14px] relative top-[-5px]'>
                    <div className='w-[40px] pl-[4px] text-center h-[31px] flex items-center justify-center'>
                        <ClubLogo teamName={match.teams[0]} width='25px' height='25px' fallbackOveride={`${match.winner==='' || match.winner===match.teams[0] ? ('white') : ('grey')}`} />    
                    </div>
                    <div className={`flex-grow text-center pt-[10px] text-[11px]`}>
                        <span className={`${match.winner==='' || match.winner===match.teams[0] ? ('text-[#fff]') : ('text-[#9888ab]')}`}>{match.shortnames[0].toUpperCase()}</span>
                        <span> v </span>
                        <span className={`${match.winner==='' || match.winner===match.teams[1] ? ('text-[#fff]') : ('text-[#9888ab]')}`}>{match.shortnames[1].toUpperCase()}</span>
                    </div>
                    <div className='w-[40px] pr-[4px] text-center h-[31px] flex items-center justify-center'>
                        <ClubLogo teamName={match.teams[1]} width='25px' height='25px' fallbackOveride={`${match.winner==='' || match.winner===match.teams[1] ? ('white') : ('grey')}`} />    
                    </div>
                </div>
                <div className='w-full relative top-[-3px] flex font-chakra text-[#fff] text-[14px]'>
                    <div className='w-[50px] text-left pl-[5px] text-center'>
                        {match.startTime==='LIVE' ? ( 
                            <>
                                {liveScore[0]}
                                {liveBat===match.teams[0] ? ( <div className='mt-[1px] bg-[#f73261] w-full h-[3px]' /> ) : ( null )}
                            </> 
                        ) : ( null )}
                        {match.startTime==='FINISHED' ? (
                            <>
                                <span className={`${match.winner==='' || match.winner===match.teams[0] ? ('text-[#fff]') : ('text-[#9888ab]')}`}>{match.score[0]}</span>
                            </>
                        ) : ( null )}
                    </div>
                    <div className={`flex-grow text-center ${match.startTime==='LIVE' ? ('text-[#f73261]') : match.startTime==='FINISHED' ? ('text-[#32f78c]') : ( null )}`}>
                        {match.startTime}
                    </div>
                    <div className='w-[50px] text-right pr-[5px] text-center'>
                        {match.startTime==='LIVE' ? ( 
                            <>
                                {liveScore[1]}
                                {liveBat===match.teams[1] ? ( <div className='mt-[1px] bg-[#f73261] w-full h-[3px]' /> ) : ( null )}
                            </> ) : ( null )}
                        {match.startTime==='FINISHED' ? (
                            <>
                                <span className={`${match.winner==='' || match.winner===match.teams[1] ? ('text-[#fff]') : ('text-[#9888ab]')}`}>{match.score[1]}</span>
                            </>
                        ) : ( null )}
                    </div>
                </div>
            </div>
            </>
        ))}
      </div>
  );
};
