import React, { useState, useRef } from 'react';
import { IconArrowStem21 } from '../../icons/IconArrowStem21';
import { BiBellPlus } from "react-icons/bi";

export const GetAlerts = () => {
  const emailRef = useRef(null);
  const [emailError, setEmailError] = useState('');
  const [successReg, setSuccessReg] = useState(false);

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  headers['x-api-key'] = process.env.API_KEY;
  const handleEmail = async () => {
    const email = emailRef.current.value; 
    if (validateEmail(email)) {
      setEmailError('');
      const response = await fetch(`${process.env.API_URL}/register/email`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify({ email: email })
      });
      if (response.status === 200) {
        const data = await response.json(); 
        setSuccessReg(true);
      }
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  return (
    <div id="content" className="min-h-[75vh] mt-[20px]">
      <h1 className="text-[30px] md:text-[55px]">GET ALERTS</h1>
      <div className="mt-[30px] font-chakra font-normal">
        Sign up for Cricket8's email alerts to receive:
      </div>

      <div className='font-chakra mt-[30px] md:flex md:gap-4'>
        <div className='w-full md:w-1/3 bg-[#4c3368] p-[30px] mb-[5px] flex'>
          <BiBellPlus className='text-[30px] text-[#d2ff00]' />
          <div className='pl-[10px] pt-[5px]'>Alerts for live video watch-alongs</div>
        </div>
        <div className='w-full md:w-1/3 bg-[#4c3368] p-[30px] mb-[5px] flex'>
          <BiBellPlus className='text-[30px] text-[#d2ff00]' />
          <div className='pl-[10px] pt-[5px]'>Alerts for great new content</div>
        </div>
        <div className='w-full md:w-1/3 bg-[#4c3368] p-[30px] mb-[5px] flex'>
          <BiBellPlus className='text-[30px] text-[#d2ff00]' />
          <div className='pl-[10px] pt-[5px]'>Alerts for exciting new features</div>
        </div>
      </div>

      {!successReg ? (

      <div className='md:flex font-chakra mt-[30px]'>
        <div className='w-full text-center md:w-1/3 md:text-left mb-[20px] pt-[10px]'>Enter your email address to sign up:</div>
      
        <div className='w-full md:w-1/3'>
          <div className="relative w-[590px] h-[40px] pl-[5px]">
            <div className="absolute w-[315px] md:w-[322px] h-[40px] bg-[#fff] border border-solid border-[#fff]">
              <input ref={emailRef} className="enteremail2 ml-[20px]" type="email" placeholder="Enter your email..." />
              {emailError && <div style={{ color: 'red', paddingTop: '10px' }}>{emailError}</div>}
            </div>
            <div className="absolute w-[40px] h-[40px] left-[300px] bg-[#d2ff00] ml-[23px] md:ml-[30px]">
              <div className="relative top-[12px] left-[10px]" onClick={handleEmail}>
                <IconArrowStem21 className="cursor-pointer !w-[22px] !h-[14px]" color="#22182b" />
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : (
        <div className='relative mt-[30px]'><h2>Email successfully registered!</h2></div>
      )}
    </div>
  );
};
