import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Logo } from "../components/Logo";
import { HeaderMatchCard } from "../components/HeaderMatchCard";
//import { useLiveMatchId } from "./Matches/useLiveMatchId";
import { useUser } from "../UserContext";
import Cookies from 'js-cookie';

const Header = () => {
  const { user, setUser } = useUser();
  //const { liveMatchId, startDate } = useLiveMatchId();
  const [ upcoming, setUpcoming] = useState([]);
  const [ thisAccount, setthisAccount ] = useState([]);
  const [usersession, setUsersession] = useState('');
  const [watchalong, setWatchalong] = useState(false);
  
  const location = useLocation(); 
  const queryParams = new URLSearchParams(location.search);
  const gamesession= queryParams.get("gamesession") || null;
  const deeplink=queryParams.get("back") || null ;
  const isActive = (pathname) => {
    if (pathname === '/' && location.pathname === '/') {
      return true;
    }
    else if (pathname !== '/' && location.pathname.startsWith(pathname)) {
      return true;
    }
    return false;
  };
  const fetch_settings = async () => {
    const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.API_KEY,
      },
    });
    if (response.status === 200) {
      const data = await response.json(); 
      setWatchalong(data.data[0].value.live);
    }
  };
  async function checkSignIn() {
    const session_token = Cookies.get('c8_session_token');
    if (session_token) {
      try {
        const response = await fetch(`${process.env.API_URL}/verifysession/${session_token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.API_KEY,
          },
        });
    
        if (response.status === 200) {
          const data = await response.json();
          if (data) {
              setUsersession(session_token);
              setthisAccount(data);
          }
        } else {
          setUsersession('');
          return null;
        }
      } catch (error) {
        setUsersession('');
        return null; 
      }
    }
  }

  async function fetchData() {
    let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
    let maxDisplay=20;
    const res = await fetch(process.env.API_URL+'/fixturelist', {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.API_KEY
      },
    });
    const data = await res.json();
    const mData=data.matches;
    let matchBuild=[];
    mData.forEach(match => {
      if (match.sport===4) {
        if (match.id.includes('LIVE')) {
          if (match.competition.toLowerCase().trim()===tournamentFilter.trim() || tournamentFilter.trim()==='') {
              let matchStart = new Date(match.start);
              let today = new Date();
              match.localTime = matchStart.toLocaleString('en-US', {
                weekday: 'short', 
                hour: 'numeric', 
                minute: '2-digit',
                hour12: true 
              });
              let diff=matchStart-today;
              let mm = Math.floor(diff / 1000 / 60);
              let hh = Math.floor(diff / 1000 / 60 / 60);
              let days = Math.floor(hh / 24);
              if (days > 0) {
                let remainingHours = hh % 24; // Remaining hours after subtracting full days
                match.diff = days + "D " + remainingHours + "H";
              } else if (hh > 0) {
                match.diff = hh + "H";
              } else {
                match.diff = mm + "M";
              }
              matchStart.setHours(0, 0, 0, 0);
              today.setHours(0, 0, 0, 0);
              let name=match.name.split(",");
              let teams=name[0].split(" v ");
              if (match.status.substring(0,17)==='Match in Progress' 
                  || match.status.substring(0,13)==='Break in Play' 
                  || match.status.substring(0,16)==='Innings Complete'
                  || match.status.substring(0,8)==='Play to '
                  || match.status.substring(0,12)==='Toss Pending'
                ) { 
                  match.localTime='LIVE'; 
                } else if (match.status.substring(0,14)==='Match Complete' || match.status.substring(0,12)==='Event Closed') {
                  match.localTime='Match Complete';
                  //fetchScoreCard(match.id, teams);
                }
              if (matchStart >= today) {
                if (match.end==='') {
                  match.teams=teams;
                  matchBuild.push(match);
                }
              }
          }
        }
      }
    })
    matchBuild.sort((a, b) => new Date(a.start) - new Date(b.start));
    setUpcoming(matchBuild)
  };
  useEffect(() => {
    fetch_settings();
    //checkSignIn();
  }, []);
  


  return (
    <div id='header'>
      <div className='w-full max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
        <div className="relative w-[100%] h-[104px] top-[15px] flex">
          <div className="w-[40px] h-[50px] ml-[10px]">
            <a href='/' className='menuLink'>
              <Logo className="w-[50px] h-[50px] md:h-[72px] md:w-[72px]" />
            </a>
          </div>
          <div className="relative top-0 hidden lg:inline flex-grow">
            <div className="relative top-[3px] flex items-center justify-center">
              <HeaderMatchCard />
            </div>
          </div>
          <div>
            <a href='/games'>
              <div className='hidden md:inline w-[200px] h-[40px] bg-[#371359] border border-solid border-[#a75af0] absolute right-[10px] top-[3px] bg-[#00ff00] h-[70px] cursor-pointer'>
                <div className='absolute top-[0px] right-[-42px]'>
                  <img src={`${process.env.CDN_ENDPOINT}/assets/coin_stack2.png`} style={{width:'135px'}} />
                </div>
                <div className='absolute top-[-5px] right-[0px]'>
                  <img src={`${process.env.CDN_ENDPOINT}/assets/coin_stack1.png`} className='h-[65px]' />
                </div>
                <div className="text-white [font-family:'Anton',Helvetica] font-normal text-[20px] absolute left-[15px] top-[23px]">
                  SPIN FOR COINS
                </div>
              </div>
            </a>
          </div>
          {!user ? (
            <>
            <a href='/games'>
              <div className='md:hidden w-[132px] h-[40px] bg-[#371359] border border-solid border-[#a75af0] absolute right-[150px] top-[7px] bg-[#00ff00] h-[40px] cursor-pointer'>
                <div className='absolute top-[-15px] right-[-32px]'>
                  <img src={`${process.env.CDN_ENDPOINT}/assets/coin_stack2.png`} style={{width:'98px'}} />
                </div>
                <div className='absolute top-[-10px] right-[5px]'>
                  <img src={`${process.env.CDN_ENDPOINT}/assets/coin_stack1.png`} className='h-[40px]' />
                </div>
                <div className="text-white [font-family:'Anton',Helvetica] font-normal text-[14px] absolute left-[10px] top-[12px]">
                  SPIN FOR COINS
                </div>
              </div>
            </a>
            <div>
              <div className="md:hidden absolute w-[60px] h-[40px] top-[7px] right-[80px]" >
                <div className="relative w-full h-full bg-[#d2ff00]">
                  <a className="menuLink-dark absolute top-[10px] left-[15px] [font-family:'Anton',Helvetica] font-normal text-[#1b062f] text-[14px] text-right tracking-[0] leading-[normal] block" href={`/login${gamesession ? `?gamesession=${gamesession}` : ''}${deeplink ? `&back=${deeplink}` : ''}`}>
                    LOGIN
                  </a>
                </div>
              </div>
              <div className="md:hidden absolute w-[60px] h-[40px] top-[7px] right-[15px]" >
                <div className="relative w-full h-full bg-[#d2ff00]">
                  <a className="menuLink-dark absolute top-[10px] left-[10px] [font-family:'Anton',Helvetica] font-normal text-[#1b062f] text-[14px] text-right tracking-[0] leading-[normal] block" href={`/signup${gamesession ? `?gamesession=${gamesession}` : ''}${deeplink ? `&back=${deeplink}` : ''}`}>
                    SIGN UP
                  </a>
                </div>
              </div>
            </div>
            </>
          ) : (
            <>
            <a href='/games'>
              <div className='md:hidden w-[132px] h-[40px] bg-[#371359] border border-solid border-[#a75af0] absolute right-[145px] top-[7px] bg-[#00ff00] h-[40px] cursor-pointer'>
                <div className='absolute top-[-15px] right-[-32px]'>
                  <img src={`${process.env.CDN_ENDPOINT}/assets/coin_stack2.png`} style={{width:'98px'}} />
                </div>
                <div className='absolute top-[-10px] right-[5px]'>
                  <img src={`${process.env.CDN_ENDPOINT}/assets/coin_stack1.png`} className='h-[40px]' />
                </div>
                <div className="text-white [font-family:'Anton',Helvetica] font-normal text-[14px] absolute left-[10px] top-[12px]">
                  SPIN FOR COINS
                </div>
              </div>
            </a>
            <div className='md:hidden absolute w-[120px] h-[40px] top-[7px] right-[15px]'>
              <a href='/account'>
                <div className='bg-[#d2ff00] h-full pl-[10px] pr-[5px]'>
                  <div className='justify-center flex'>
                    <div className="[font-family:'Anton',Helvetica] font-normal text-[#1b062f] text-[14px] pt-[13px]">ACCOUNT</div>
                    <div className='ml-[20px] mt-[5px] bg-[#d2ff00]'>
                      <img src={user.profile_pic} className='w-[30px] h-[30px]' />
                    </div>
                  </div>
                </div>
              </a>
             </div>
             </>
          )}
          <div className={`absolute w-[144px] h-[40px] top-[7px] right-[155px] ${watchalong===false ? ('hidden') : ( null )} md:hidden`}>
            <a href={`/match`}>
            <div className="relative w-[142px] h-[40px] border border-solid border-[#f73261]">
              <p className="absolute top-[9px] left-[30px] [font-family:'Anton',Helvetica] font-normal text-transparent text-[14px] tracking-[0] leading-[normal]">
                <span className="text-[#f73261]">LIVE</span>
                <span className="text-black">&nbsp;</span>
                <span className="text-white">WATCH-ALONG</span>
              </p>
              <div className="absolute w-[14px] h-[14px] top-[12px] left-[10px] rounded-[7px] border border-solid border-[#f7326199]">
                <div className="relative w-[8px] h-[8px] top-[2px] left-[2px] bg-[#f73261] rounded-[4.2px]" />
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>


      <div id='menuBkgrd' className="relative w-[100%] h-[40px] md:h-[52px] top-[-24px] md:top-[0px] left-0 bg-white opacity-[0.15]" />
      <div id='mainMenu' className='w-full max-w-7xl mx-auto px-0 sm:px-6 lg:px-8 xl:px-16'>
        <div className="absolute w-[100%] md:w-[1200px] h-[40px] md:h-[52px] top-[80px] md:top-[104px] overflow-x-auto scrollbar-hide text-[14px] md:text-[20px] [font-family:'Anton',Helvetica] font-normal text-white">
          <div className="relative w-[100%] h-[100%]">
            <div className="absolute w-[100%] h-[100%] top-0 left-0 " />
            <div className="absolute w-[422px] h-[39px] top-px left-[15px]">
              <div className="hidden md:inline absolute w-[173px] h-[52px] top-[0px] right-[-700px]" >
                {!user ? (
                  <div className='flex h-full'>
                    <a href={`/login${gamesession ? `?gamesession=${gamesession}` : ''}${deeplink ? `&back=${deeplink}` : ''}`}>
                    <div className='bg-[#d2ff00] h-full w-[85px]'>
                      <div className='font-anton text-[#1b062f] text-[16px] pt-[18px] text-center'>
                        LOGIN
                      </div>
                    </div>
                    </a>
                    <a href={`/signup${gamesession ? `?gamesession=${gamesession}` : ''}${deeplink ? `&back=${deeplink}` : ''}`}>
                    <div className='bg-[#d2ff00] h-full w-[85px] ml-[3px]'>
                      <div className='font-anton text-[#1b062f] text-[16px] pt-[18px] text-center'>
                        SIGNUP
                      </div>
                    </div>
                    </a>
                  </div>
                ) : (
                  <div className='flex h-full justify-right pl-[60px]'>
                    <a href='/account'>
                    <div className='bg-[#d2ff00] h-full pl-[10px] pr-[5px]'>
                      <div className='justify-center flex'>
                        <div className="font-anton text-[#1b062f] text-[16px] pt-[18px]">ACCOUNT</div>
                        <div className='ml-[10px] mt-[5px] bg-[#d2ff00]'>
                          <img src={user.profile_pic} className='w-[40px] h-[40px]' />
                        </div>
                      </div>
                    </div>
                    </a>
                  </div>
                )}
              </div>
              <div className="absolute top-[8px] tracking-[0] leading-[normal]">
                <a className='menuLink' href='/'>HOME</a>
                  {isActive('/') ? (
                    <div className="relative w-[100%] top-[7px] md:top-[10px] h-[3px] left-0 bg-[#d2ff00]" />
                  ) : null }
              </div>
              {/* <div className="left-[50px] md:left-[70px] absolute top-[8px] tracking-[0] leading-[normal]">
                <a className='menuLink' href='/ipl'>IPL</a>
                {isActive('/ipl') ? (
                  <div className="relative w-[100%] top-[5px] h-[3px] left-0 bg-[#d2ff00]" />
                ) : null }
              </div> */}
              <div className="left-[50px] md:left-[70px] absolute top-[8px] tracking-[0] leading-[normal]">
                <a className='menuLink' href='/match'>MATCHES</a>
                {isActive('/match') ? (
                  <div className="relative w-[100%] h-[3px] top-[7px] md:top-[10px] left-0 bg-[#d2ff00]" />
                ) : null }
              </div>
              <div className="left-[120px] md:left-[170px] absolute top-[8px] tracking-[0] leading-[normal]">
                <a className='menuLink' href='/games'>GAMES</a>
                {isActive('/games') ? (
                  <div className="relative w-[100%] top-[7px] md:top-[8px] h-[3px] left-0 bg-[#d2ff00]" />
                ) : null }
              </div>
              <div className="left-[175px] md:left-[255px] absolute top-[8px] tracking-[0] leading-[normal]">
                <a className='menuLink' href='/media'>MEDIA</a>
                {isActive('/media') || isActive('/video') || isActive('/podcasts') ? (
                  <div className="relative w-[100%] top-[7px] md:top-[10px] h-[3px] left-0 bg-[#d2ff00]" />
                ) : null }
              </div>
              <div className="left-[230px] md:left-[330px] absolute top-[8px] tracking-[0] leading-[normal] whitespace-nowrap">
                <a className='menuLink' href='/news'>NEWS &amp; ANALYSIS</a>
                {isActive('/news') ? (
                  <div className="relative w-[100%] top-[7px] md:top-[10px] h-[3px] left-0 bg-[#d2ff00]" />
                ) : null }
              </div>
              <div className="left-[340px] md:left-[485px] absolute top-[8px] tracking-[0] leading-[normal]">
                <a className='menuLink' href='/about'>ABOUT</a>
                {isActive('/about') ? (
                  <div className="relative w-[100%] top-[7px] md:top-[8px] h-[3px] left-0 bg-[#d2ff00]" />
                ) : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
