import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserProvider } from './UserContext';

import { NotFound } from "./components/404";
import { Account } from "./components/Account";
import { AboutUs } from "./components/AboutUs";
import { Blank } from "./components/Blank";
import { Contact } from "./components/Contact";
import { Games } from "./components/Games";
import { GetAlerts } from "./components/GetAlerts";
import { HomePage } from "./components/HomePage";
import Layout from "./components/Layout";
import { Matches } from "./components/Matches";
import { MatchDetail } from "./components/MatchDetail";
import { Media } from "./components/Media";
import { MobileHomepageLive } from "./screens/MobileHomepageLive";
import { News } from "./components/News";
import { Podcasts } from "./components/Podcasts";
import { Privacy } from "./components/Privacy";
import { Settings } from "./components/SiteSettings";
import { Signup } from "./components/Account/Signup";
import { Teams } from "./components/Teams";
import { Terms } from "./components/Terms";
import { Video } from "./components/Video";

import { AbTest } from "./components/404/AbTest";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><HomePage /></Layout>,
  },
  {
    path: "/about",
    element: <Layout><AboutUs /></Layout>,
  },
  {
    path: "/account",
    element: <Layout><Account /></Layout>,
  },
  {
    path: "/match",
    element: <Layout><Matches /></Layout>,
  },
  {
    path: "/match/:match_id",
    element: <Layout><MatchDetail /></Layout>
  },
  {
    path: "/matchdetail",
    element: <Layout><MatchDetail /></Layout>
  },
  {
    path: "/media",
    element: <Layout><Media /></Layout>
  },
  {
    path: "/podcasts",
    element: <Layout><Podcasts /></Layout>
  },
  {
    path: "/ipl",
    element: <Layout><Blank /></Layout>,
  },
  {
    path: "/login",
    element: <Layout><Signup /></Layout>,
  },
  {
    path: "/signup",
    element: <Layout><Signup /></Layout>,
  },
  {
    path: "/teams",
    element: <Layout><Teams /></Layout>,
  },
  {
    path: "/teams/:team_name",
    element: <Layout><Teams /></Layout>,
  },
  {
    path: "/terms",
    element: <Layout><Terms /></Layout>,
  },
  {
    path: "/video",
    element: <Layout><Video /></Layout>,
  },
  {
    path: "/news",
    element: <Layout><News /></Layout>,
  },
  {
    path: "/about",
    element: <Layout><Blank /></Layout>,
  },
  {
    path: "/getalerts",
    element: <Layout><GetAlerts /></Layout>,
  },
  {
    path: "/settings",
    element: <Layout><Settings /></Layout>,
  },
  {
    path: "/privacy",
    element: <Layout><Privacy /></Layout>,
  },
  {
    path: "/contact",
    element: <Layout><Contact /></Layout>,
  },
  {
    path: "/games",
    element: <Layout><Games /></Layout>,
  },
  {
    path: "*",
    element: <Layout><NotFound /></Layout>,
  }
]);

export const App = () => {
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
};
