import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Charts } from "../../MatchDetail/Charts";

export const Scorecard = ({scorecard, maxovers=[0,0], striker='', bowling='', teams, probability, scoregrid, overs, complete, battingovers}) => {
    function isEven(n) {
        return n % 2 == 0;
    }
    const [curIns, setCurIns] = useState("inns"+scorecard.inns_now);
    let od, ev='';
    if (isEven(scorecard.inns_now)) {
        ev=scorecard.bat_now;
        od=scorecard.bowl_now;
    } else {
        od=scorecard.bat_now;
        ev=scorecard.bowl_now;
    }
    let selections=[];
    for (var i=1; i<=4; i++) {
        if (scorecard["inns"+i]) {
            if (isEven(i)) {
                selections[i]=ev;
            } else {
                selections[i]=od;
            }
        }
    }
    let TotalW = scorecard[curIns]?.wkts ?? 0;
    let TotalR = scorecard[curIns]?.runs ?? 0;
    const handleInsChange = (event) => {
        setCurIns(event.target.value);
    };
    useEffect(() => {
        const newCurIns = `inns${scorecard.inns_now ?? 1}`;
        setCurIns(newCurIns);
      }, [scorecard.inns_now]); 
    return (
    <div className='px-[20px] flex'>
        <div className='flex-grow'>

            {scorecard[curIns] ? (
            <>
            <div className='anOver w-[100%] mb-[10px] bgwhite'>
                <div className='mb-[20px] flex'>
                    <div className='font-anton text-[#18062f] text-[30px] md:text-[40px] uppercase flex-grow'>
                        SCORECARD
                        
                    </div>
                    <div className='font-chakra text-[#18062f] !text-right text-[12px] md:text-[14px]'>
                        <div className="select-wrapper">
                            <select className='dropdown' onChange={handleInsChange} value={curIns}>
                                {selections.map((sel,idx) => {
                                if (sel) {
                                    return (
                                        <option value={`inns${idx}`}>
                                            {sel}
                                        </option>
                                    );
                                }
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[12px] md:text-[14px] flex border-solid border-[#18062f] border-[1px]">
                    <div className='flex-grow pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>BATTING</div>
                    <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>R</div>
                    <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>B</div>
                    <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>4s</div>
                    <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>6s</div>
                    <div className='pt-[7px] text-center w-[30px] md:w-[40px]'>SR</div>
                </div>
                <>
                {scorecard[curIns].batting.map((bat,idx) => {
                    if (bat.name!=='') {
                        return (
                            <div className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                                <div className={`flex-grow py-[10px] pl-[10px] text-balance !text-left bold ${striker===bat.name ? 'border-l-[3px] border-solid border-[#f73261]' : null }`}>
                                    {bat.name}
                                </div>
                                <div className='w-[100px] md:w-[250px] py-[10px] !text-left border-solid border-[#c5c2c8] border-r-[1px]'>
                                    {bat.status}
                                </div>
                                <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                                    {bat.runs}
                                </div>
                                <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                    {bat.balls}
                                </div>
                                <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                    {bat.fours}
                                </div>
                                <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                                    {bat.sixes}
                                </div>
                                <div className='w-[30px] md:w-[40px] py-[10px] text-center'>
                                    {bat.balls!=='0' ? (
                                        <>{Math.round((bat.runs/bat.balls)*100)}</>
                                    ) : ( null )}
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
                </>
                <div className="flex font-chakra bg-white text-[12px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                    <div className='flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px]'>
                        Extras <span className='font-normal'>(
                                {scorecard[curIns].extras.nb} nb,&nbsp;
                                {scorecard[curIns].extras.wd} w,&nbsp;
                                {scorecard[curIns].extras.lb} lb,&nbsp;
                                {scorecard[curIns].extras.b} b)</span>
                    </div>
                    <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                        {scorecard[curIns].extras.nb+scorecard[curIns].extras.wd+scorecard[curIns].extras.lb+scorecard[curIns].extras.b}
                    </div>
                    <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                    <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                    <div className='w-[30px] md:w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                    <div className='w-[30px] md:w-[40px] py-[10px] text-center'></div>
                </div>
                <div className="flex font-chakra bg-white text-[14px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                    <div className='flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px] text-[18px]'>
                        TOTAL 
                    </div>
                    <div className='w-[90px] md:w-[120px] py-[10px] pl-[10px] !text-left bold'>
                        {TotalR}/{TotalW}
                    </div>
                    <div className='w-[60px] md:w-[80px] py-[10px] text-center'>
                        {/* {maxovers[parseInt(scorecard.inns_now)-1]}  */}
                        {battingovers[parseInt(curIns.substring(4))-1]} OVERS</div>
                </div>


                <div className='m-[10px]'>still to bat</div>
                <div className='m-[10px]'>fall of wickets</div>


                <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]">
                    <div className='flex-grow pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>BOWLING</div>
                    <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>W</div>
                    <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>R</div>
                    <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>OV</div>
                    <div className='pt-[7px] text-center w-[40px] border-solid border-[#18062f] border-r-[1px]'>NB</div>
                    <div className='pt-[7px] text-center w-[40px]'>WD</div>
                </div>
                {scorecard[curIns].bowling.map((bowl,idx) => (
                    <>
                    {bowl.name!=='' ? (
                    <div className="flex font-chakra bg-white text-[1px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                        <div className={`flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px] border-r-[#c5c2c8] ${bowling===bowl.name ? 'border-l-[3px] border-solid border-[#f73261]' : null }`}>
                            {bowl.name}
                        </div>
                        <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                            {bowl.wickets}
                        </div>
                        <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                            {bowl.runs}
                        </div>
                        <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                            {bowl.overs}
                        </div>
                        <div className='w-[40px] py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                            {bowl.nb}
                        </div>
                        <div className='w-[40px] py-[10px] text-center'>
                            {bowl.wd}
                        </div>
                    </div>
                    ) : (null)}
                    </>
                ))}
            </div>
            </>
            ) : ( null )}

        </div>
        <div className='hidden md:inline'>
            <Charts teams={teams} probability={probability} scoregrid={scoregrid} maxovers={maxovers} title={false} complete={complete} />
        </div>
    </div>
  );
};
