import React, { useState } from 'react';
import { useUser } from "../../../UserContext";
import { MdLockOutline } from "react-icons/md";
import CustomModal from "../../Modals";

export const SubMenu = ({ selected = 'scorecard', setSelected, complete }) => {
  const { user } = useUser();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deeplink, setDeeplink] = useState(window.location.href);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className='relative mt-[10px] mx-0 px-0 left-[-14px] md:left-0 max-w w-[100vw] md:w-[100%] bg-white h-[48px] text-[#000] border-solid border-[#18062f] border-b-[1px] overflow-x-auto'>
      <div className="flex whitespace-nowrap px-[14px] pt-[8px] font-normal text-[#18062f] text-[18px]">
        <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'feed' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('feed')}>FEED</div>
        {complete !== 'true' ? (
          <>
            {!user ? (
              <div className={`px-[10px] pt-[5px] h-[30px] cursor-pointer ${selected === 'trading' ? 'bg-[#d2ff00]' : ''}`} onClick={openModal}>
                <div className='text-[#a39bac]'>ODDS <MdLockOutline /></div>
              </div>
            ) : (
              <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'trading' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('trading')}>
                ODDS
              </div>
            )}
          </>
        ) : (null)}
        <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'scorecard' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('scorecard')}>SCORECARD</div>
        {complete !== 'true' ? (
          <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'commentary' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('commentary')}>COMMENTARY</div>
        ) : (null)}
        <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer md:hidden ${selected === 'charts' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('charts')}>CHARTS</div>
        {complete !== 'true' ? (
          <>
            <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'lineup' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('lineup')}>LINEUP</div>
          </>
        ) : ( null )}
        {/* <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'videos' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('videos')}>VIDEOS</div>
        <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'news+analysis' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('news+analysis')}>NEWS & ANALYSIS</div> */}
      </div>
      <CustomModal id='modal' isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Need Account">
          <div>
            <div className='text-[#d2ff00] text-[20px] md:text-[30px] text-center font-anton'>YOU NEED AN ACCOUNT FOR THIS CONTENT</div>
            <div className='mt-[20px] font-chakra text-white text-center text-[14px] md:text-[16px]'>
              Create a free Cricket8 account for access to exclusive content.
            </div>
            <div className='mt-[40px] w-full flex justify-center'>
              <a href={`/signup?back=${deeplink}`}>
                <div className='bg-[#d2ff00] h-[50px] w-[100px] md:w-[265px] text-center'>
                  <div className='font-anton text-[#1b062f] text-[16px] pt-[17px]'>
                    SIGN UP
                  </div>
                </div>
              </a>
            </div>
            <div className='mt-[30px] mb-[40px] w-full justify-center font-chakra text-[14px] md:text-[16px] flex'>
              <div className='text-white pr-[5px]'>Already got an account?</div>
              <div className='text-[#d2ff00]'><a className='text-[#d2ff00] hover:text-underline' href={`/login?back=${deeplink}`}>Login here</a></div>
            </div>
          </div>
      </CustomModal>
    </div>
  );
};

export default SubMenu;
