import React, { useState, useEffect } from "react";

import { UpcomingMatches } from "../../components/UpcomingMatches";
// import { LatestNews } from "../LatestNews";
import { LatestVideos } from "../LastestVideos";
import { LatestPodcasts } from "../LatestPodcasts";
import { ScoreBox } from "../Matches/ScoreBox";
import { VideoPlayer } from "../VideoPlayer/index_old";
import { useLiveMatchId } from "../Matches/useLiveMatchId";
import { NewGameHero } from "../Heros/NewGames";
import { MainHero } from "../Heros/MainHero";

export const HomePage = (): JSX.Element => {

  const { liveMatchId, startDate } = useLiveMatchId();
  const [fetchLive, setFetchLive] = useState(null);
  const wsUrl = process.env.FEED_URL;
  const apiKey = process.env.DECIMAL_API_KEY;
  const [teams, setTeams] = useState(['','']);
  const [batting, setBatting] = useState(null);
  const [score, setScore] = useState(['0/0','0/0']);
  const [overs, setOvers] = useState(['']);
  const [probability, setProbability] = useState([{name:'',probability:0},{name:'',probability:0}]);
  const [scoregrid, setScoregrid] = useState(['']);
  const [partnership, setPartnership] = useState({});
  const [prematch, setPrematch] = useState(true);
  const [CurPos, setCurPos]=useState([0,0,0]);
  const [matchName, setMatchName] = useState('');
  const [location, setLocation] = useState();
  const [tournament, setTournament] = useState('');
  const [game_phase, setGamePhase] = useState('');
  const [localTime, setLocalTime] = useState('');
  const [watchalong, setWatchalong] = useState(false);
  const [watchalongStart, setWatchAlongStart] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };
  headers['x-api-key'] = process.env.API_KEY;

  const fetch_settings = async () => {
    const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
      method: 'GET',
      headers: headers,
    });
    if (response.status === 200) {
      const data = await response.json(); 
      setWatchalong(data.data[0].value.live);
      if (!data.data[0].value.live) {
        const endDate = new Date(data.data[0].value.starting).getTime();
        const countdown = setInterval(function() {
          const now = new Date().getTime();
          const timeLeft = endDate - now;
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          setWatchAlongStart(
            days.toString().padStart(2, '0') + "d " +
            hours.toString().padStart(2, '0') + "h " +
            minutes.toString().padStart(2, '0') + "m " +
            seconds.toString().padStart(2, '0') + "s ");
          if (timeLeft < 0) {
            clearInterval(countdown);
            setWatchAlongStart('');
          }
        }, 1000);
      } else {
        let upc=data.upcoming;
        fetchMatchDetail(upc);
        setFetchLive(upc);
        
      }
    }
  };

  const fetchScoreGrid = async (match_id) => {
    let scoreDisplay=[];
    const response = await fetch(`${process.env.API_URL}/scoregrid/${match_id}`, {
      method: 'GET',
      headers: headers,
    });
    if (response.status === 200) {
      const data = await response.json(); 
      const overs = data[0].msg_data.overs;
      
      let scoreDisplay = [''];
      for (let inns = 1; inns <= 4; inns++) {
        const currentInns = overs[`inns${inns}`];
        if (currentInns) {
          currentInns.forEach(over => {
            let ignoreOver = true;
            for (let i = 1; i <= 12; i++) {
              const ballKey = `b${i}`;
              const ballValue = over[ballKey];
              if (ballValue !== null && ballValue !== "") {
                ignoreOver = false;
                scoreDisplay.push(ballValue); 
              }
            }
            if (!ignoreOver) {
              scoreDisplay.push("OVER " + over.ov);
            }
          });
        }
      }
      setScoregrid(scoreDisplay.reverse());

    } else {
      console.error('Failed to fetch:', response.status, response.statusText);
      const errorText = await response.text(); 
    }
  };

  const fetchMatchDetail = async (match_id) => {
    let socket;
    if (process.env.ENVIRONMENT!=='dev') {
      socket = new WebSocket(`${wsUrl}`);
    } else {
      socket = new WebSocket(`${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`);
    }
    socket.addEventListener('open', function open() {
      const message = JSON.stringify({
        feed_filter: ['event', 'market_price_update'],
        msg_type: "feed_subscription", 
        feed_id: match_id,
      });
      socket.send(message);
    });
  
    socket.addEventListener('message', function incoming(data) {
      const parsedData = JSON.parse(data.data);
      if (parsedData.msg_type) {
        //console.log(parsedData.msg_type);
      }
      if (parsedData.market_price_update) {
        const winPredict = parsedData.market_price_update.markets.find(market => market.market_id === '1010');
        if (winPredict) {
          let prob1=Math.round(winPredict.selections[0].probability*100);
          let prob2=(100-prob1);
          setProbability([{name:winPredict.selections[0].name, probability:prob1},{name:winPredict.selections[1].name, probability:prob2}]);
        }
      }
      if (parsedData.event) {
        if (parsedData.event.event_description.includes("Pre Match Service")) {
          if (parsedData.event.scoreboard) {
            const scoreboard = parsedData.event.scoreboard;
            const homeObj = scoreboard.find(item => item.name === 'home');
            const awayObj = scoreboard.find(item => item.name === 'away');
            setTeams([homeObj ? homeObj.value : '', awayObj ? awayObj.value : '' ]);
          }
        } else {
          setPrematch(false);
          const scoreboard = parsedData.event.scoreboard;
          const matchTitleObj = scoreboard.find(item => item.name === 'matchtitle');
          const game_phaseObj = scoreboard.find(item => item.name === 'game_phase');
          const local_timeObj = scoreboard.find(item => item.name === 'local_time');
          const homeObj = scoreboard.find(item => item.name === 'home');
          const awayObj = scoreboard.find(item => item.name === 'away');
          const battingObj = scoreboard.find(item => item.name === 'Current Batting Team');

          let localdt = new Date(local_timeObj ? local_timeObj.value : '');
          let formattedDate = localdt.getDate().toString().padStart(2, '0') + '/'
          + (localdt.getMonth() + 1).toString().padStart(2, '0') + '/'
          + localdt.getFullYear();

          let matchname=matchTitleObj ? matchTitleObj.value : '';
          const parts = matchname.split(",");
          if (parts.length>2) {
            setMatchName(parts[0].trim());
            setLocation(parts[2].trim());
            setTournament(parts[1].trim());
          }
          setGamePhase(game_phaseObj ? game_phaseObj.value : '');
          setLocalTime(formattedDate);
          setTeams([homeObj ? homeObj.value : '', awayObj ? awayObj.value : '' ]);
          setBatting(battingObj ? battingObj.value : '');

          const NewPos = [0,0,0];
          const CurInns = scoreboard.find(item => item.name ==='Current Innings');
          NewPos[0]=CurInns ? CurInns.value : NewPos[0];
          const CurOvrs = scoreboard.find(item => item.name ==='Current Innings Overs');
          NewPos[1]=CurOvrs ? CurOvrs.value : NewPos[1];
          const CurPart = scoreboard.find(item => item.name ==='Current Innings Part Overs');
          NewPos[2]=CurOvrs ? CurPart.value : NewPos[2];
          if (CurPos!==NewPos) {
            //fetchScoreGrid(match_id);
            setCurPos(NewPos);
          }
          const maxOvers = scoreboard.find(item => item.name ==='Max Overs');
          const batting1 = scoreboard.find(item => item.name ==='batting.1');
          const batting1Runs = scoreboard.find(item => item.name ==='runs.1');
          const batting1Wkts = scoreboard.find(item => item.name ==='wkts.1');
          const batting1MaxOvers = scoreboard.find(item => item.name ==='max.1');
          const batting1Overs = scoreboard.find(item => item.name ==='overs.1');

          const batting2 = scoreboard.find(item => item.name ==='batting.2');
          const batting2Runs = scoreboard.find(item => item.name ==='runs.2');
          const batting2Wkts = scoreboard.find(item => item.name ==='wkts.2');
          const batting2MaxOvers = scoreboard.find(item => item.name ==='max.2');
          const batting2Overs = scoreboard.find(item => item.name ==='overs.2');


          let b1mover=batting1MaxOvers ? batting1MaxOvers.value : '';
          let b2mover=batting2MaxOvers ? batting2MaxOvers.value : '';
          let b1over= batting1Overs ? batting1Overs.value : '';
          let b2over= batting2Overs ? batting2Overs.value : '';

          let homeOver = b1over+"/"+ b1mover;
          if (b1over===b1mover) { homeOver = b1mover; }
          let awayOver = b2over + "/"+b2mover;


          let bat1Runs = batting1Runs ? batting1Runs.value : '';
          let bat1Wkts = batting1Wkts ? batting1Wkts.value : '';
          let bat2Runs = batting2Runs ? batting2Runs.value : '';
          let bat2Wkts = batting2Wkts ? batting2Wkts.value : '';

          if (b2over.value===b2mover) { awayOver = b2mover; }
          if (awayOver==='') { awayOver = '0/'+maxOvers.value; }
          let bat1Prog=bat1Runs +"/"+bat1Wkts;
          if (bat1Prog==='/') { bat1Prog='0/0'; }
          let bat2Prog=bat2Runs+"/"+bat2Wkts;
          if (bat2Prog==='/') { bat2Prog='0/0'; }
          if (batting1 ? batting1.value : '' === homeObj.value) {
            setScore([bat1Prog,bat2Prog]);
            setOvers([homeOver,awayOver]);
          } else {
            setScore([bat2Prog,bat1Prog]);
            setOvers([awayOver,homeOver]);
          }

          const bat1 = scoreboard.find(item => item.name === battingObj ? battingObj.value : ''+'Bat.1');
          const bat2 = scoreboard.find(item => item.name === battingObj ? battingObj.value : ''+'Bat.2');
          const strike1 = scoreboard.find(item => item.name === 'Batsman1 Strike');
          const strike2 = scoreboard.find(item => item.name === 'Batsman2 Strike');
          let bowl1 = scoreboard.find(item => item.name === "bowler1name");
          let bowl2 = scoreboard.find(item => item.name === "bowler2name");
          const runs1 = scoreboard.find(item => item.name === 'Batsman1 Runs');
          const runs2 = scoreboard.find(item => item.name === 'Batsman2 Runs');
          let bowl1overs = scoreboard.find(item => item.name === "bowler1overs");
          let bowl1runs = scoreboard.find(item => item.name === "bowler1runs");
          let bowl1wicks = scoreboard.find(item => item.name === "bowler1wickets");
          let bowl2overs = scoreboard.find(item => item.name === "bowler2overs");
          let bowl2runs = scoreboard.find(item => item.name === "bowler2runs");
          let bowl2wicks = scoreboard.find(item => item.name === "bowler2wickets");
          const balls1 = scoreboard.find(item => item.name === battingObj ? battingObj.value : ''+'Bat.1balls');
          const balls2 = scoreboard.find(item => item.name === battingObj ? battingObj.value : ''+'Bat.2balls');
          let strikerbatter='';
          let batterruns=0;
          let batterballs=0;
          if (strike1 ? strike1.value : '' ==='1') {
            strikerbatter=bat1 ? bat1.value : 0;
            batterruns=runs1.value;
            batterballs=balls1.value
          } else {
            strikerbatter=bat2 ? bat2.value : 0;
            batterruns=runs2 ? runs2.value : 0;
            batterballs=balls2 ? balls2.value : 0;
          }
          let actbowl = scoreboard.find(item => item.name === "bowler");
          let bowlovers="0 overs";
          let bowlruns="0";
          let bowlwickets="0";
          if (bowl1 ? bowl1.value : '' !=='') { 
            actbowl=bowl1.value; 
            bowlovers=bowl1overs.value+" overs";
            bowlruns=bowl1runs.value;
            bowlwickets=bowl1wicks.value;
          } else { 
            actbowl=bowl2 ? bowl2.value : ''; 
            bowlovers=bowl2overs ? bowl2overs.value : ''+" overs";
            bowlruns=bowl2runs ? bowl2runs.value : '';
            bowlwickets=bowl2wicks ? bowl2wicks.value : '';
          }
          setPartnership({bowler: actbowl, bowlerover: bowlovers, bowlerruns: bowlruns, bowlerwickets: bowlwickets, batter: strikerbatter, batterruns: batterruns, batterballs: batterballs});
          //fetchScoreGrid(match_id);
          //console.log(parsedData.event);
        }
      } else {
        //console.log(`Received: ${match_id} - ${data.data}`);
      }
    });

    socket.addEventListener('error', function error(error) {
      console.error('WebSocket error:', error);
    });
  
    socket.addEventListener('close', function close(event) {
      console.log('Disconnected from the WebSocket server:', event);
      console.log(`Close code: ${event.code}, reason: ${event.reason}`);
    });
  
  
    
  };
  useEffect(() => {
    fetch_settings();
  },[]);
  //useEffect(() => {
  //  setFetchLive(liveMatchId);
  //}, [liveMatchId]);
  useEffect(() => {
    if (fetchLive) {
      //fetchMatchDetail(fetchLive);
    }
    return () => {
      // Cleanup code here, e.g., closing a WebSocket connection
      // if (socket) {
      //   socket.close();
      // }
    };
  }, [fetchLive]);  


  return (
  <div>
    {!watchalong ? (

      <div>
        {/* <MainHero watchalongStart={watchalongStart} /> */}
        <NewGameHero />
      </div>

    ) : (

      // WATCHALONG
      <>
      <div id='content' className='mt-[20px]'>

      <div className='md:flex'>
          <div className='md:w-1/3 mr-[10px]'>
          <h2 className='text-[14px] md:text-[20px]'>ELITE CRICKET INTELLIGENCE</h2>
          <h1 className='text-[30px] md:text-[40px] leading-tight'>INSIGHT, NEWS AND ANALYSIS.</h1>
       
          <div className='mt-[20px] z-10'>
            <div className='mt-5 flex relative z-10'>
              <div className='yellowBox truncate'>{tournament}</div>
              <div className='subTitle truncate'>{teams[0]} v {teams[1]}</div>
            </div>
            
            {localTime ? ( 
            <div className='mt-1 mb-[8px] flex relative z-10'>
              <div className='dateDisplay'>{localTime}</div>
              <span className="text-[#7cf732] ml-2 mr-2 relative top-[-3px]">•</span>
              <div className='dateDisplay'>{location}</div>
              <span className="text-[#7cf732] ml-2 mr-2] relative top-[-3px]">•</span>
              {game_phase==='In Play' ? (
                <span className="dateDisplay !text-[#d73261] flex">
                  <div>&nbsp;&nbsp;LIVE</div>
                  <div className="live-indicator mr-[10px]">
                    <div className="inner"></div>
                  </div>
                </span>
              ) : (
                <span className="dateDisplay text-[#f73261]">&nbsp;&nbsp;{game_phase}</span>
              )}
            </div>
            ) : ( null )}

            <ScoreBox teams={teams} batting={batting} score={score} overs={overs} probability={probability} scoregrid={scoregrid} partnership={partnership} displayPartnerShip='false' prematch={prematch} startdate={startDate} />
            {!prematch ? (
            <a className='relative z-20' href={`/matchdetail?id=${fetchLive}`}>
              <div className='w-full bg-[#d2ff00] h-[50px]'>
                <div className='font-anton text-[#1b062f] text-[16px] text-center pt-[17px]'>GO TO MATCH</div>
              </div>
            </a>
            ) : ( null )}
          </div>
        </div>
        <div className={`mt-[30px] ${watchalong!==false ? ('md:mt-[20px]') : ('md:mt-[37px]')} md:w-2/3 !text-right relative z-10`}>
          {watchalong===false ? (
            <div>
              {watchalongStart!=='' ? (
              <div className="text-[#f73261] text-left font-anton text-[18px] flex pb-[10px]">
                <div>
                  <img className='w-[20px] h-[20px] mb-[3px] mr-[5px]' src='/img/camera-red.svg' />
                </div>
                <div>
                  LIVE
                </div>
                <div className='text-[#fff]'>
                  &nbsp;WATCH-ALONG
                </div>                
                <div className='font-chakra font-normal text-[14px] pl-[10px] pt-[4px]'>
                  <span className='text-[#fff]'>Starting in </span>
                  <span className='text-[#32f78c]'>{watchalongStart}</span>
                </div>
              </div>
              ) : ( <div className='h-[30px]'></div> )}

              <div>
                <img className='h-auto max-w-full' src='../img/watchalong_soon.PNG' />
              </div>

            </div>
          ) : (
            <>
            <VideoPlayer subHeader='watchalong' channel='0' />
            </>
          )}
        </div>
      </div> 
      </div>
      </>
      )}

      <div id='content' className={`diagonal-gradient ${watchalong ? 'mt-[20px]' : 'mt-[720px] md:!mt-[595px]'} `}>
      

      <div id='livematches' className='mt-[80px]'>
        <div className='flex justify-content-between'>
          <div className='flex-grow'>
            <h1 className='mt-[30px] text-[30px] md:text-[40px]'>MATCHES</h1>
          </div>
          <div className='hidden md:inline'>
            <a href='/match'>
              <div className="relative w-[118px] h-[50px] top-[-4px] left-0 bg-[#d2ff00]">
                <div className="relative left-[2px] top-[14px] font-anton font-normal text-[#1b062d] text-[16px] !text-center tracking-[0] leading-[normal]">
                  ALL MATCHES
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className='mt-[10px] min-h-[303px]'>
            <UpcomingMatches />
        </div>
        <a className='md:hidden' href='/match'>
          <div className="md:hidden mt-[20px] w-[100%] h-[30px] bg-[#d2ff00] ">
            <div className='!text-center pt-[6px] text-[#1b062d] anton font-normal'>
              ALL MATCHES
            </div>
          </div>
        </a>
      </div>

      <div id='news' className='mt-[50px]'>
        <div className='flex justify-content-between'>
          <div className='flex-grow'>
            <h1 className='text-[30px] md:text-[40px]'>LATEST VIDEO</h1>
          </div>
        </div>
        <div className='mt-[10px] min-h-[303px]'>
            <LatestVideos maxVids={3} style='summary' />
        </div>
      </div>

      <div id='news' className='mt-[50px]'>
        <div className='flex justify-content-between'>
          <div className='flex-grow'>
            <h1 className='text-[30px] md:text-[40px]'>LATEST PODCASTS</h1>
          </div>
        </div>
        <div className='mt-[10px] min-h-[303px]'>
            <LatestPodcasts maxPods={3} style='summary' />
        </div>
      </div>

      {/* <div id='news' className='mt-[50px]'>
        <div className='flex justify-content-between'>
          <div className='flex-grow'>
            <h1 className='text-[30px] md:text-[40px]'>LATEST NEWS &amp; ANALYSIS</h1>
          </div>
        </div>
        <div className='mt-[10px] min-h-[303px]'>
            <LatestNews maxArts={3} style='summary' />
        </div>
      </div> */}

    </div>
  </div>
  );
};
