import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Charts } from "../../MatchDetail/Charts";

export const Commentary = ({commentary, fullComm, teams, probability, scoregrid, maxovers}) => {
    function isEven(n) {
        return n % 2 == 0;
    }
    const [curIns, setCurIns] = useState("inns"+scoregrid.inns_now);
    let od, ev='';
    if (isEven(scoregrid.inns_now)) {
        ev=scoregrid.bat_now;
        od=scoregrid.bowl_now;
    } else {
        od=scoregrid.bat_now;
        ev=scoregrid.bowl_now;
    }
    let selections=[];
    for (var i=1; i<=4; i++) {
        if (scoregrid.overs["inns"+i]) {
            if (isEven(i)) {
                selections[i]=ev;
            } else {
                selections[i]=od;
            }
        }
    }
    const handleInsChange = (event) => {
        setCurIns(event.target.value);
    };
    return (
        <div className='px-[20px] flex'>
            <div className='flex-grow'>

                <div className='mb-[20px] flex'>
                    <div className='font-anton text-[#18062f] text-[30px] md:text-[40px] uppercase flex-grow'>
                        COMMENTARY
                    </div>
                    <div className='font-chakra text-[#18062f] !text-right text-[12px] md:text-[14px]'>
                        <div className="select-wrapper">
                            <select className='dropdown' onChange={handleInsChange} value={curIns}>
                                {selections.map((sel,idx) => {
                                    if (sel) {
                                    return (
                                        <option value={`inns${idx}`}>
                                            {sel}
                                        </option>
                                    );
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                
                {(() => {
                    let previousOver = null;
                    return fullComm.map((x, index) => {
                        if (x.inns===curIns) {
                            const shouldInsertHeader = x.over !== previousOver;
                            previousOver = x.over;
                            let OverRuns='';
                            let OverScore=''
                            let ovKey=scoregrid.overs[curIns].findIndex(item => item.ov === (x.over+1).toString());
                            if (ovKey>0) {
                                OverRuns=scoregrid.overs[curIns][ovKey].runs;
                                OverScore=scoregrid.overs[curIns][ovKey].total+"/"+scoregrid.overs[curIns][ovKey].wkts;
                            }
                            let sball=x.ball.toString().substring(0,1);
                            return (
                            <>
                                {shouldInsertHeader && (
                                    <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex justify-between border-solid border-[#18062f] border-[1px] mt-[20px]">
                                        <div className="pt-[7px] pl-[10px] [tracking-[0] leading-[15px] whitespace-nowrap">
                                            OVER {x.over+1}
                                        </div>
                                        <div className='pt-[7px] pr-[10px]'>
                                            <span className='font-normal pr-[10px]'>{OverRuns} RUNS </span>
                                            <span>{OverScore}</span>
                                            
                                        </div>
                                    </div>
                                )}
                                <div className={`flex justify-between font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0`}>
                                    <div className={`w-[40px] md:w-[50px] text-center 
                                                        ${x.result.substring(0,6)==='WICKET' ? ('bg-[#f73261]/[0.3]') : (null)} 
                                                        ${x.result.substring(0,6)==='4 runs' ? ('bg-[#32f78d]/[0.3]') : (null)} 
                                                        ${x.result.substring(0,6)==='6 runs' ? ('bg-[#9432f7]/[0.3]') : (null)} 
                                                        py-[10px] m-0 border-solid border-[#18062f] border-r-[1px]`}>
                                        {x.over}.{sball}
                                    </div>
                                    <div className='m-0 py-[10px]'>
                                        <div className='absolute left-[70px] md:left-[100px] text-left'>
                                            <span className='bold'>{x.ballFrom}</span> to  <span className='bold'>{x.ballTo}</span>
                                        </div>
                                    </div>
                                    <div className={`text-center w-[50px] md:w-[125px] py-[10px] px-[5px] md:px-[10px] border-solid border-[#18062f] border-l-[1px] border-r-0 ${x.result.substring(0,6)==='WICKET' ? ('text-[#f73261] bold') : (null)}`}>
                                        {x.result}
                                    </div>
                                </div>
                            </>
                            );
                        }
                    });
                })()}



                {/* {finalOver.map((item, index) => (
                    <div className='anOver w=[100%] mb-[10px] bgwhite'>
                        <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex justify-between border-solid border-[#18062f] border-[1px]">
                            <div className="pt-[7px] pl-[10px] [tracking-[0] leading-[15px] whitespace-nowrap">
                                OVER {item[0][0]+1}
                            </div>
                            <div className='pt-[7px] pr-[10px]'>
                                {item[item.length-1][4]} runs {(scoregrid.overs["inns1"].find(item => item.ov === "1")).runs}
                            </div>
                        </div>
                        {item.map((ci,cidx) => (
                            <div className="flex justify-between font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0">
                                <div className={`w-[40px] md:w-[50px] text-center 
                                                ${ci[3].substring(0,6)==='WICKET' ? ('bg-[#f73261]/[0.3]') : (null)} 
                                                ${ci[3].substring(0,6)==='4 runs' ? ('bg-[#f96d33]/[0.3]') : (null)} 
                                                ${ci[3].substring(0,6)==='6 runs' ? ('bg-[#f73261]/[0.3]') : (null)} 
                                                ${ci[3].substring(0,6)==='2 runs' ? ('bg-[#9cd2ff]/[0.3]') : (null)} 
                                                py-[10px] m-0 border-solid border-[#18062f] border-r-[1px]`}>
                                {ci[0]}.{ci[1]}
                                </div>
                                <div className='m-0 py-[10px]'>
                                    <div className='absolute left-[70px] md:left-[100px] text-left'>
                                        <span className='bold'>{ci[2][0]}</span> to <span className='bold'>{ci[2][1]}</span>
                                    </div>
                                </div>
                                <div className={`text-center w-[50px] md:w-[125px] py-[10px] px-[5px] md:px-[10px] border-solid border-[#18062f] border-l-[1px] border-r-0 ${ci[3].substring(0,6)==='WICKET' ? ('text-[#f73261] bold') : (null)}`}>{ci[3]}</div>
                            </div>
                        ))}
                    </div>
                ))} */}
            </div>
            <div className='hidden md:inline'>
                <Charts teams={teams} probability={probability} scoregrid={scoregrid} maxovers={maxovers} title={false} />
            </div>
        </div>
    );
};
