import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "../Spinner";
import Modal from 'react-modal';
import { CgClose } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { IoMdShare } from "react-icons/io";
import Cookies from 'js-cookie';
import ShareModal from "../ShareModal";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const getRanHex = size => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
                  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
                ];
    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
}

function isToday(dateString) {
  const today = new Date();
  const someDate = new Date(dateString);
  return someDate.toDateString() === today.toDateString();
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0',
    width: 'calc(100vw - 40px)', 
    height: 'calc(-40px + 100vh)',
    background: 'none',
    textAlign: 'center',
    padding: '0',
    position: 'absolute',
    maxWidth: '476px',
    maxHeight: '690px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '0'
  },
  overlay: {
    background: "rgba(11, 2, 18, 0.75)"
  }
};

export const Games = () => {
    const [title, setTitle] = useState("Cricket8.com | Games");
    const [description, setDescription] = useState("Check this out - Grab your free coins before the launch of our exciting new game! Join the Cricket8.com community today and start collecting your rewards!");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const openModal = () => setIsShareModalOpen(true);
    const closeModal = () => {
      getWallet();
      setIsShareModalOpen(false);
    };
    const hashtags = ['Cricket8','SpinToWinCoins','PlayEveryDay'];

    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [sessionId, setSessionId] = useState('');
    const [showWallet, setShowWallet] = useState(false);
    const [wallet, setWallet] = useState([]);
    const [coins, setCoins] = useState(0);
    const [diamonds, setDiamonds] = useState(0);
    const [alreadyPlayed, setAlreadyPlayed] = useState(false);
    const [lastWon, setLastWon] = useState(0);
    const [counterSpin, setCounterSpin] = useState(false);
    const [loading, setLoading] = useState(true);

    const xlocation = useLocation();
    const url = window.location.href;

    const handleSpin = () => {
        setIsModalOpen(true);
    }

    const SpinModal = ({ isOpen, onRequestClose}) => {
        useEffect(() => {
            const handleResize = () => {
            };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      const updatePageTitle = () => {
        document.title = "Games | Cricket8";
      };
      updatePageTitle();
      return () => {
      };
    }, []);

    useEffect(() => {
      const handleMessage = (event) => {
        if ((event.origin === 'http://18.200.62.208' || event.origin === 'https://spin.cricket8.com' )  && event.data === 'GAME_DONE') {
          setIsModalOpen(false);
          setCounterSpin(true);
          getWinnings();
        }
      };
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);
  
    return (
      <Modal id='Modalspinner'
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel="Spinner"
      >
            <div className='absolute top-[10px]  right-[8px]'>
            <button onClick={onRequestClose} className="modal-close-button text-right"><CgClose /></button>
            </div>
            <div style={{width:'100%', height: '100%'}}>
                <iframe src={`https://spin.cricket8.com/?sessionId=${sessionId}`} frameborder="0" style={{width:'100%', height: '100%'}} className='border-0'></iframe>
            </div>
      </Modal>
    );
  };
  const getWinnings = async() => {
    const session_token = Cookies.get('c8_session_token');
    if (session_token) {
      if (sessionId!=='') {
        setAlreadyPlayed(true);
        const response = await fetch(`${process.env.API_URL}/collectwinnings/${sessionId}/${session_token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.API_KEY,
          },
        });
        if (response.status === 200) {
          getWallet();
        }
      }
    } else {
      window.location.href="/login?gamesession="+sessionId+"&back=games";
    }
  };
  const getWallet = async () => {
    const session_token = Cookies.get('c8_session_token');
    if (session_token) {
      const response = await fetch(`${process.env.API_URL}/wallet/${session_token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        const data = await response.json(); 
        setWallet(data.data);
        if (data.data.games!==null) {
          for (let i=0; i<data.data.games.length; i++) {
            if (data.data.games[i].name==='spin_for_coins') {
              if (isToday(data.data.games[i].values.last)) {
                setAlreadyPlayed(true);
                setLastWon(data.data.games[i].values.lastwon);
                setCounterSpin(false);
              }
            }
          }
        }
        if (data.data.wallet!==null) {
          if (data.data.wallet.coins) {
            setCoins(data.data.wallet.coins);
          }
          if (data.data.wallet.diamonds) {
            setDiamonds(data.data.wallet.diamonds);
          }
        }
        setShowWallet(true);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSessionId(getRanHex(32));
    getWallet();
  }, []);

  return (
    <div>
      <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={`https://${window.location.hostname}/img/spinner_large.png`} />
      </Helmet>

      <div id='content' className='min-h-[50vh] mt-[0px] md:mt-[20px] mb-[100px]'>
        {loading ? ( null ) :
        (
          <>
          {showWallet ? (
            <div className="relative w-full h-[40px]">
              <div className="absolute w-full h-[40px] top-0 left-0 bg-[#1b062f] opacity-70" />
              <div className='absolute top-0 left-0 w-full'>
                <div className='flex'>
                  <div className='w-[50px]'>
                    <img src={wallet.profile_pic} className='h-[40px]' />
                  </div>
                  <div className="flex-grow font-anton text-white pt-[11px] text-[18px] pl-[10px] truncate">
                    {wallet.username}
                  </div>

                  <div className='flex-end font-anton text-white text-[18px] px-[20px] border-l-2 border-solid border-l-[#34145a] flex'>
                    <img src={`${process.env.CDN_ENDPOINT}/assets/single_diamond.png`} className='h-[40px]' />
                    <div className='pt-[11px] pl-[10px]'>{diamonds}</div>
                  </div>
                  <div className='flex-end font-anton text-white text-[18px] px-[20px] border-l-2 border-solid border-l-[#34145a] flex'>
                    <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[40px]' />
                    <div className='pt-[11px] pl-[10px]'>{coins}</div>
                  </div>

                </div>
              </div>
            </div>
          ) : ( null )} 
          

          <div className='w-full lg:flex'>

            {!alreadyPlayed ? (
              <div>
                <div className='mt-[30px] font-anton text-white text-3xl'>
                    WE&apos;RE LAUNCHING GAMES SOON!
                </div>
                <div className='mt-[20px] font-chakra text-white text-base normal-case md:pr-[20px]'>
                  Spin daily and win free Coins for a chance to win the Grand Prize! Don’t miss out on your opportunity to hit the jackpot—start spinning now!
                </div>
              </div>
            ) : (
              <div>
                <div className='mt-[30px] font-anton text-[#d2ff00] text-3xl'>
                    CRICKET8 GAMES
                </div>
                <div className='font-anton text-white text-3xl'>
                  We&apos;re launching games soon!
                </div>
                <div className='mt-[20px] font-chakra text-white text-base normal-case'>
                  <div className='font-bold'>YOU WON!</div>
                  <div>
                    Winnings are safely stored in your wallet, ready to use when our first game launches. Stay tuned for updates on the launch date, and don&apos;t forget to come back daily for your free spin to earn more coins and the chance to win our Grand Prize!
                  </div>
                </div>
              </div>
            )}


            <div className="mt-[50px] md:mt-[100px] w-full">
                <div className="w-full bg-[rgba(255,255,255,0.1)] rounded-[13px]">
                    <div className='flex pb-[10px] lg:h-[120px]'>
                        <div className='w-[50%] lg:w-[80%] font-anton text-[#d2ff00] text-[30px] lg:text-[36px] uppercase p-[20px]'>
                            Spin for free coins!
                        </div>
                        <div className='w-[50%] justify-right flex-end'>
                            <div className='w-full relative'>
                              <div className='absolute top-[-35px] lg:top-[-45px] right-[0px] w-[150px] lg:w-[200px]'>
                                  <Spinner />
                              </div>
                            </div>
                        </div>
                    </div>
                    {alreadyPlayed ? (
                      <div className='mt-[10px] w-full p-[20px]'>

                        <div className="relative w-full h-[70px]">
                          <div className="absolute w-full h-[70px] top-0 left-0 bg-[#1b062f] opacity-70" />
                          <div className='absolute top-0 left-0 w-full'>
                              <div className='flex'>
                                <div className='w-[30px] h-[30px] bg-[#32f78c] text-[#18062f] text-[18px] my-[20px] mx-[10px] p-[6px]'>
                                  <FaCheck />
                                </div>
                                <div className='flex-grow text-[#32f78c] text-[18px] font-anton mt-[20px] pt-[6px]'>
                                  PLAYED TODAY
                                </div>
                                <div className='flex-end text-white text-[30px] mt-[20px] mr-[5px]'>
                                  {counterSpin ? ( null ) : (
                                    <>+{lastWon}</>
                                  )}
                                </div>
                                <div className='flex-end'>
                                  <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='mt-[5px] mr-[5px] h-[60px]' />
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className='w-full font-chakra font-normal normal-case text-white text-center text-[18px] mt-[20px]'>
                          Come back tomorrow to spin again!
                        </div>
                        <div className="relative w-full h-[173px] mt-[20px]" onClick={openModal} >
                          <div className="absolute w-full h-[173px] top-0 left-0 bg-[#1b062f] opacity-70" />
                          <div className='absolute top-0 left-0 w-full'>
                            <div className='font-anton text-[#d2ff00] text-[24px] text-center mt-[20px]'>
                              SHARE FOR MORE COINS
                            </div>
                            <div className='mt-[20px] font-chakra text-white font-normal normal-case text-center text-[16px]'>
                              Get +10 coins by sharing with friends.
                            </div>
                            <div className='m-[20px] bg-[#d2ff00] h-[50px] flex cursor-pointer hover:bg-[#c2e809]'>
                              <div className='w-[40px]'></div>
                              <div className='flex-grow font-anton text-center pt-[13px] text-[24px] text-[#1b062f]'>
                                SHARE 
                              </div>
                              <div className='w-[40px] text-right text-[24px] text-[#1b062f] pt-[13px] pr-[10px]'>
                                <IoMdShare />
                              </div>
                            </div>
                          </div>
                        </div>
                        <ShareModal isOpen={isShareModalOpen} onRequestClose={closeModal} url={url} sharename='GAME PLAY' title='Spin for free coins!' hashtags={hashtags} />

                      </div>
                    ) : (
                      <div className='mt-[10px] w-full p-[20px]'>
                          <div className='w-full bg-[#d2ff00] hover:bg-[#c2e809] h-[50px] cursor-pointer text-center'>
                              <div className='font-anton text-[#1b062f] uppercase text-[24px] pt-[13px]' onClick={() => handleSpin()}>
                                  SPIN THE WHEEL
                              </div>
                          </div>
                      </div>
                    )}
                </div>
            </div>

          </div>

          <SpinModal 
              isOpen={isModalOpen} 
              onRequestClose={() => setIsModalOpen(false)} 
          />
        </>
        )}
      </div>
    </div>
  );
};
