import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import YouTube from 'react-youtube';
import Modal from 'react-modal';
import { IoMdShare } from "react-icons/io";
import { IconArrowStem21 } from "../../icons/IconArrowStem21";
import { FaRegWindowClose, FaPlay } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa6";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '800px',
    background: '#1b062f',
    textAlign: 'center',
  },
  overlay: {
    background: "rgba(11, 2, 18, 0.75)"
  }
};

const VideoModal = ({ isOpen, onRequestClose, videoId }) => {
  const getVideoOpts = () => {
    const width = window.innerWidth > 640 ? 640 : window.innerWidth * 0.8; 
    const height = (width / 16) * 9;
    return {
      height: height.toString(),
      width: width.toString(),
      playerVars: {
        autoplay: 1,
        mute: 1
      },
    };
  };

  const [opts, setOpts] = useState(getVideoOpts());

  useEffect(() => {
    const handleResize = () => {
      setOpts(getVideoOpts());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "Videos | Cricket8";
    };
    updatePageTitle();
    return () => {
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="YouTube Video"
    >
      <div className='md:w-full text-right pb-[10px]'>
        <button onClick={onRequestClose} className="modal-close-button text-right"><FaRegWindowClose /></button>
      </div>
      <div>
        <YouTube videoId={videoId} opts={opts} />
      </div>
    </Modal>
  );
};

export const Video = () => {
  const [videoList, setVideoList] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const goBack = () => navigate('/media');

  const xlocation = useLocation();
  const queryParams = new URLSearchParams(xlocation.search);
  let videoId=queryParams.get("videoId");

  function decodeHtmlEntities(str) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  }

  async function fetchVideos() {
    try {
      const res = await fetch(`${process.env.API_URL}/videos/999/0`, {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY
        },
      });
      if (res.status === 200) {
        const data = await res.json(); 
        const na = [];
        data.videos.forEach(videos => {
          let title = decodeHtmlEntities(videos.item.snippet.title);
          let media = videos.item.snippet.thumbnails.default.url;
          let bigmedia = videos.item.snippet.thumbnails.high.url;
          const dateObject = new Date(videos.item.snippet.publishedAt);
          const formattedDate = dateObject.toLocaleDateString('en-US', { day: '2-digit', month: 'long' }); 
          let dte = formattedDate;
          let link = "https://www.youtube.com/watch?v=" + videos.item.id.videoId;
          na.push({ title: title, media: media, bigmedia: bigmedia, date: dte, link: link, videoId: videos.item.id.videoId });
        })
        setVideoList(na);
      }
    } catch (error) {
      console.log(error);
      //console.error('Network error:', error);
    }  
  }

  useEffect(() => {
    fetchVideos();
  }, []);
  
  useEffect(() => {
    if (videoId!==null) {
        handleVideoClick(videoId);
    }
  }, [videoId])

  const handleVideoClick = (videoId) => {
    setSelectedVideoId(videoId);
    setIsModalOpen(true);
  };

  return (
    <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[20px] mb-[100px]'>
      <div className='flex pb-[10px] cursor-pointer' onClick={goBack} >
          <div className='h-6 bg-[#d2ff00] text-[#1b062f] pt-[4px] px-[6px]'><FaChevronLeft /></div>
          <div className='h-6 font-anton text-[#1b062f] text-[14px] px-[8px] pt-[6px] ml-[3px] bg-[#d2ff00]'>BACK</div>
      </div>
      <h1 className='text-[30px] md:text-[55px]'>VIDEOS</h1>

      <div className='mt-[30px] w-full md:flex md:flex-wrap'>
        {videoList.map((video, index) => (
          <div key={index} className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-[10px] cursor-pointer' onClick={() => handleVideoClick(video.videoId)}>
            <div className='w-full text-right bg-[#4b3265] text-white text-[14px] p-[10px]'>{video.date}</div>
            <div className="relative">
              <img src={video.bigmedia} className='w-[100%] h-auto object-cover' />
              <div className='bg-white h-[50px] w-[50px] text-[12px] flex items-center justify-center text-[#1b062f] rounded-full pl-[5px] absolute top-[45%] left-[44%]'>
                  <FaPlay className='w-[50%] h-[50%]' />
              </div>
            </div>
            <div className='relative w-full h-[72px] text-left bg-white text-[#1b062f] text-[18px] p-[10px] flex'>
              <div className='w-[100%] line-clamp-3'>{video.title}</div>
              {/* <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
              </div> */}
            </div>
          </div>
        ))}
      </div>

      <VideoModal 
        isOpen={isModalOpen} 
        onRequestClose={() => setIsModalOpen(false)} 
        videoId={selectedVideoId} 
      />
    </div>
  );
};
