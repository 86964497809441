import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import https from 'https';
import { useParams, useNavigate } from "react-router-dom";
import { Charts } from "./Charts";
import { Commentary } from "../Matches/Commentary";
import { Feed } from "../Matches/Feed";
import { Lineup } from "../Matches/Lineup";
import { ScoreBox } from "../Matches/ScoreBox";
import { Scorecard } from "../Matches/Scorecard";
import { Standings } from "../Matches/Standings";
import { SubMenu } from "../Matches/SubMenu";
import { Trading } from "../Matches/Trading";
import { VideoPlayer } from "../VideoPlayer/index_old";
import { IconArrowStem21 } from "../../icons/IconArrowStem21";
import { FaPlay } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import ShareModal from "../ShareModal";
import Modal from "../Modals";
import { IoMdShare } from "react-icons/io";
import { setHeapSnapshotNearHeapLimit } from "v8";
import { useUser } from "../../UserContext"
import Cookies from 'js-cookie';
import CustomModal from "../Modals";

export const MatchDetail = () => {
  const { user, setUser } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deeplink, setDeeplink] = useState(window.location.href);

  const openLoginModal = () => {
    setModalIsOpen(true);
  };

  const closeLoginModal = () => {
    setModalIsOpen(false);
  };

  const xlocation = useLocation();
  const url = window.location.href;
  const queryParams = new URLSearchParams(xlocation.search);
  const match_id=queryParams.get("id");
  const complete=queryParams.get("status");
  
  const [batting, setBatting] = useState(null);
  const [ballIds, setBallIds] = useState([]);
  const [bowling, setBowling] = useState();
  const [commentary, setCommentary] = useState([]);
  const [CurPos, setCurPos]=useState([0,0,0]);
  const [eventStatus, setEventStatus]=useState();
  const [feed, setFeed]=useState([]);
  const [format, setFormat] = useState(20);
  const [fullComm, setFullComm] = useState([]);
  const [game_phase, setGamePhase] = useState(null);
  const [incomingEvent, setIncomingEvent] = useState(false);
  const [lineup, setLineup] = useState([]);
  const [location, setLocation] = useState();
  const [marketPriceUpdate, setMarketPriceUpdate] = useState([]);
  const [matchName, setMatchName] = useState(null);
  const [overs, setOvers] = useState('');
  const [partnership, setPartnership] = useState(["",""]);
  const [probability, setProbability] = useState([{name:'',probability:0},{name:'',probability:0}]);
  const [selected, setSelected] = useState('feed');
  const [score, setScore] = useState(['0/0','0/0']);
  const [scorecardData, setScorecardData] = useState([]);
  const [scoregrid, setScoregrid] = useState([]);
  const [scoreGridData, setScoreGridData] = useState([]);
  const [striker, setStriker]=useState();
  const [teams, setTeams] = useState([]);
  const [tournament, setTournament] = useState('IPL 2024');
  const [trading, setTrading] = useState([]);
  const [localTime, setLocalTime] = useState(null);
  const [mOvers, setMOvers] = useState([0,0])
  const [matchComment, setMatchComment] = useState('');
  const [currentInnings, setCurrentInnings] = useState(0);
  const [watchalong, setWatchalong] = useState(false);
  const [subHeader, setSubHeader] = useState('standard');
  const [stream, setStream] = useState(true);
  const [channel, setChannel] = useState('0');
  const [battingOvers, setBattingOvers] = useState([0,0,0,0]);
  const [embedcode, setEmbedCode] = useState('');
  const dte = new Date();
  let eventCount=0;

  const wsUrl = process.env.FEED_URL;
  const apiKey = process.env.DECIMAL_API_KEY;
  const apiEndpoint = process.env.DECIMAL_ENDPOINT;
  let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventCount = async (match_id) => {
        try {
            const response = await fetch(`${process.env.API_URL}/events_count/${match_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                if (eventCount !== data.data[0].row_count) {
                    eventCount=data.data[0].row_count;
                    fetchEvents(match_id);
                }
            }
        } catch (error) {
            console.error('Error fetching event count:', error);
            return null;
        }
    };
    const intervalId = setInterval(() => fetchEventCount(match_id), 60000);
    return () => clearInterval(intervalId);
  }, [match_id]);

  const getEmbedCode = async (match_id) => {
    try {
      const response = await fetch(`${process.env.API_URL}/embeddedplayer/${match_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setEmbedCode(data.data);
      }
    } catch (error) {
      return null; 
    }
  };

  const fetchEvents = async (match_id) => {
    try {
      const response = await fetch(`${process.env.API_URL}/events/${match_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setStream(data.stream.trim());
        if (data.stream.trim()==='2') {
          setSubHeader('stream');
          if (embedcode==='') {
            getEmbedCode(match_id);
          }
        }
        setFeed(data.data);
        setIncomingEvent(false);
      }
    } catch (error) {
      return null; 
    }
  };

  const fetchScoreGrid = async (match_id, scoreGridData) => {
      const data = scoreGridData;
      const overs = data.overs;
      
      let scoreDisplay = [];
      for (let inns = 1; inns <= 4; inns++) {
        const currentInns = overs[`inns${inns}`];
        if (currentInns) {
          currentInns.forEach(over => {
            let ignoreOver = true;
            for (let i = 1; i <= 12; i++) {
              const ballKey = `b${i}`;
              const ballValue = over[ballKey];
              if (ballValue !== null && ballValue !== "") {
                ignoreOver = false;
                scoreDisplay.push(ballValue); 
              }
            }
            if (!ignoreOver) {
              scoreDisplay.push("OVER " + over.ov);
            }
          });
        }
      }
      setScoregrid(scoreDisplay.reverse());
  };

  const feedCheck = async (event) => {

  };

  async function getCompletedData(match_id) {
    try {
      const response = await fetch(`${process.env.API_URL}/completed/${match_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.data.event) {
          EventProc(data.data.event[0].msg_data);
        }
        if (data.data.scorecard) {
          setScorecardData(data.data.scorecard[0].msg_data);
          setEventStatus(data.data.scorecard[0].msg_data.event_status);
        }
        if (data.data.scoregrid) {
          setScoreGridData(data.data.scoregrid[0].msg_data);
          fetchScoreGrid(match_id,data.data.scoregrid[0].msg_data);
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      //console.error('Error fetching data:', error);
      return null; 
    }
  };

  
  useEffect (() => {
    if (complete==='true') {
      getCompletedData(match_id);
      setSelected('scorecard');
    } else {
      let socket;
      if (process.env.ENVIRONMENT!=='dev') {
        socket = new WebSocket(`${wsUrl}`);
      } else {
        socket = new WebSocket(`${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`);
      }
      socket.addEventListener('open', function open() {
        const message = JSON.stringify({
          feed_filter: ['event','commentary','match_update','market_update','market_price_update', 'scorecard', 'scoregrid', 'lineups'], 
          feed_type: "update_only",
          msg_type: "feed_subscription", 
          feed_id: match_id,
        });
        socket.send(message);
      });

      socket.addEventListener('message', function incoming(data) {
        let parsedData='';
        try {
          parsedData = JSON.parse(data.data);
        } catch (e) {
        }
        if (parsedData.msg_type) {
          let timeStamp=parseFloat(parsedData.last_update.split("-")[0]);
          const last_update = new Date(timeStamp);
        }
        if (parsedData.lineups) {
          setLineup(parsedData.lineups);
        }
        if (parsedData.match_update) {
          if (parsedData.match_update.competition.toLowerCase()!==tournamentFilter && tournamentFilter!=='') {
            navigate('/', { replace: true });
          }
          setFormat(parsedData.match_update.format);
        }
        if (parsedData.scoregrid) {
          setScoreGridData(parsedData.scoregrid);
          fetchScoreGrid(match_id,parsedData.scoregrid);
        }
        if (parsedData.market_update) {
          let tm=trading;
          let markets=parsedData.market_update.markets;
          markets.forEach((mk,id) => {
            if (mk.in_play==='Yes' && mk.priority!=='0') {
              let existingIndex = tm.findIndex(item => item.market_id === mk.market_id);
              if (existingIndex===-1) {
                tm.push(mk);
              } else {
                tm[existingIndex]=mk;
              }
            }
          })
          tm.sort((a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10));
          setTrading(tm);
        }

        //**commentary */
        if (parsedData.commentary) {
          let commentary=parsedData.commentary;
          let comms=fullComm;
          for (let inns=1; inns<=4; inns++) {
              let insKey="inns"+inns;
              if (commentary.commentaries[insKey]) {
                  let thisIns=[]
                  commentary.commentaries[insKey].forEach((com,idx) => {
                      let ballParts=com.ball.toString().split(".");
                      let ov=parseInt(ballParts[0],10);
                      let ba=parseInt(ballParts[1]);
                      let csplit=com.commentary.split(",");
                      let res=csplit[1];
                      let fr=csplit[0].split(" to ")[0];
                      let tr=csplit[0].split(" to ")[1];
                      let newObject = { inns: insKey, over: ov, ball: ba, commentary: com.commentary, ballFrom: fr, ballTo: tr, result: res.trim() };
                      let existingIndex = comms.findIndex(item => item.inns === insKey && item.over === ov && item.ball === ba);
                      if (existingIndex !== -1) {
                          comms[existingIndex].commentary = com.commentary;
                      } else {
                          comms.unshift(newObject);
                      }
                  });
              }
          }
          setFullComm(comms);
        }

        if (parsedData.scorecard) {
          const scorecard = parsedData.scorecard;
          setScorecardData(scorecard);
        }
        if (parsedData.market_price_update) {
          const winPredict = parsedData.market_price_update.markets.find(market => market.market_id === '1010');
          if (winPredict) {
            let prob1=Math.round(winPredict.selections[0].probability*100);
            let prob2=(100-prob1);
            setProbability([{name:winPredict.selections[0].name, probability:prob1},{name:winPredict.selections[1].name, probability:prob2}]);
          }
          let cMPU=marketPriceUpdate;
          parsedData.market_price_update.markets.forEach((m,i) => {
            let existingIndex = cMPU.findIndex(item => item.market_id === m.market_id);
            if (existingIndex<0) {
              cMPU.push(m);
            } else {
              cMPU[existingIndex]=m;
            }
          })
          setMarketPriceUpdate(cMPU);
        }
        if (parsedData.event) {
          EventProc(parsedData.event);
        } else {
          //console.log(`Received: ${match_id} - ${data.data}`);
        }
      });

      socket.addEventListener('error', function error(error) {
        console.error('WebSocket error:', error);
      });

      socket.addEventListener('close', function close(event) {
        //console.log('Disconnected from the WebSocket server:', event);
        //console.log(`Close code: ${event.code}, reason: ${event.reason}`);
      });
      return () => socket.close();
    }
  }, [match_id]); 


  async function EventProc(event) {
    const scoreboard = event.scoreboard;
    const matchTitleObj = scoreboard.find(item => item.name === 'matchtitle');
    const game_phaseObj = scoreboard.find(item => item.name === 'game_phase');
    const local_timeObj = scoreboard.find(item => item.name === 'local_time');
    const channelObj = scoreboard.find(item => item.name === 'channel');
    setChannel(channelObj ? channelObj.value : '0');
    const homeObj = scoreboard.find(item => item.name === 'home');
    const awayObj = scoreboard.find(item => item.name === 'away');
    const battingObj = scoreboard.find(item => item.name === 'Current Batting Team');
    const commentObj = scoreboard.find(item => item.name === 'match_comment');
    setMatchComment(commentObj ? commentObj.value : '');
    if (local_timeObj) {
      let localdt = new Date(local_timeObj.value);
      let formattedDate = localdt.getDate().toString().padStart(2, '0') + '/'
      + (localdt.getMonth() + 1).toString().padStart(2, '0') + '/'
      + localdt.getFullYear();
      setLocalTime(formattedDate);
    } 

    let matchname=matchTitleObj ? matchTitleObj.value : ' , , ';
    setMatchName(matchname.split(",")[1].trim());
    setLocation(matchname.split(",")[2].trim());
    //setTournament(matchname.split(",")[0].trim())
    setGamePhase(game_phaseObj ? game_phaseObj.value : '');
    setTeams([homeObj ? homeObj.value : '', awayObj ? awayObj.value : '' ]);
    setBatting(battingObj ? battingObj.value : '');

    const NewPos = [0,0,0];
    const CurInns = scoreboard.find(item => item.name ==='Current Innings');
    NewPos[0]=CurInns ? CurInns.value : NewPos[0];
    const CurOvrs = scoreboard.find(item => item.name ==='Current Innings Overs');
    NewPos[1]=CurOvrs ? CurOvrs.value : NewPos[1];
    const CurPart = scoreboard.find(item => item.name ==='Current Innings Part Overs');
    NewPos[2]=CurOvrs ? CurPart.value : NewPos[2];
    if (CurPos!==NewPos) {
      //fetchScoreGrid(match_id);
      setCurPos(NewPos);
    }
    setCurrentInnings(CurInns ? CurInns.value : 0);
    const maxOvers = scoreboard.find(item => item.name ==='Max Overs');
    const batting1 = scoreboard.find(item => item.name ==='batting.1');
    const batting1Runs = scoreboard.find(item => item.name ==='runs.1');
    const batting1Wkts = scoreboard.find(item => item.name ==='wkts.1');
    const batting1MaxOvers = scoreboard.find(item => item.name ==='max.1');
    const batting1Overs = scoreboard.find(item => item.name ==='overs.1');
    const batting2 = scoreboard.find(item => item.name ==='batting.2');
    const batting2Runs = scoreboard.find(item => item.name ==='runs.2');
    const batting2Wkts = scoreboard.find(item => item.name ==='wkts.2');
    const batting2MaxOvers = scoreboard.find(item => item.name ==='max.2');
    const batting2Overs = scoreboard.find(item => item.name ==='overs.2');
    const batting3Overs = scoreboard.find(item => item.name ==='overs.3');
    const batting4Overs = scoreboard.find(item => item.name ==='overs.4');
    let battingScore="0/0";


    let b1mo = batting1MaxOvers ? batting1MaxOvers.value : 0;
    let b2mo = batting2MaxOvers ? batting2MaxOvers.value : 0;
    let b1o = batting1Overs ? batting1Overs.value : 0;
    let b2o = batting2Overs ? batting2Overs.value : 0;
    let b3o = batting2Overs ? batting3Overs.value : 0;
    let b4o = batting2Overs ? batting4Overs.value : 0;
    setBattingOvers([b1o, b2o, b3o, b4o]);

    let b1r = batting1Runs ? batting1Runs.value : 0;
    let b2r = batting2Runs ? batting2Runs.value : 0;
    let b1w = batting1Wkts ? batting1Wkts.value : 0;
    let b2w = batting2Wkts ? batting2Wkts.value : 0;

    let teambowl='', teambat='';

    setMOvers([b1mo, b2mo]);
    let homeOver = b1o+"/"+b1mo;
    if (b1o===b1mo) { homeOver = b1mo; }
    let awayOver = b2o+"/"+b2mo;
    if (b2o===b2mo) { awayOver =b2mo; }
    if (awayOver==='') { awayOver = '0/'+maxOvers.value; }
    let bat1Prog=b1r+"/"+b1w;
    if (bat1Prog==='/') { bat1Prog='0/0'; }
    let bat2Prog=b2r+"/"+b2w;
    if (bat2Prog==='/') { bat2Prog='0/0'; }
    let batx1 =batting1 ? batting1.value : '';
    if (batx1 === homeObj.value) {
      setScore([bat1Prog,bat2Prog]);
      setOvers([homeOver,awayOver]);
      battingScore=bat1Prog;
      teambat=awayObj.value;
      teambowl=homeObj.value;
    } else {
      setScore([bat2Prog,bat1Prog]);
      setOvers([awayOver,homeOver]);
      battingScore=bat2Prog;
      teambat=awayObj.value;
      teambowl=homeObj.value;
    }

    let batv = battingObj ? battingObj.value : '';
    const bat1 = scoreboard.find(item => item.name === batv+'Bat.1');
    const bat2 = scoreboard.find(item => item.name === batv+'Bat.2');
    const strike1 = scoreboard.find(item => item.name === 'Batsman1 Strike');
    const strike2 = scoreboard.find(item => item.name === 'Batsman2 Strike');
    let bowl1 = scoreboard.find(item => item.name === "bowler1name");
    let bowl2 = scoreboard.find(item => item.name === "bowler2name");
    const runs1 = scoreboard.find(item => item.name === 'Batsman1 Runs');
    const runs2 = scoreboard.find(item => item.name === 'Batsman2 Runs');
    let bowl1overs = scoreboard.find(item => item.name === "bowler1overs");
    let bowl1runs = scoreboard.find(item => item.name === "bowler1runs");
    let bowl1wicks = scoreboard.find(item => item.name === "bowler1wickets");
    let bowl2overs = scoreboard.find(item => item.name === "bowler2overs");
    let bowl2runs = scoreboard.find(item => item.name === "bowler2runs");
    let bowl2wicks = scoreboard.find(item => item.name === "bowler2wickets");
    const balls1 = scoreboard.find(item => item.name === batv+'Bat.1balls');
    const balls2 = scoreboard.find(item => item.name === batv+'Bat.2balls');
    let strikerbatter='';
    let batterruns=0;
    let batterballs=0;
    if (strike1 ? strike1.value : '1' ==='1') {
      strikerbatter=bat1 ? bat1.value : '';
      batterruns=runs1 ? runs1.value : 0;
      batterballs=balls1 ? balls1.value : 0;
    } else {
      strikerbatter=bat2.value;
      batterruns=runs2.value;
      batterballs=balls2.value
    }
  
    setStriker(strikerbatter);
    let actbowl = scoreboard.find(item => item.name === "bowler");
    let bowlovers="0 overs";
    let bowlruns="0";
    let bowlwickets="0";
    if (bowl1 ? bowl1.value : '' !=='') { 
      actbowl=bowl1.value; 
      bowlovers=bowl1overs.value+" overs";
      bowlruns=bowl1runs.value;
      bowlwickets=bowl1wicks.value;
    } else { 
      actbowl=bowl2 ? bowl2.value : ''; 
      bowlovers=bowl2overs ? bowl2overs.value : '' +" overs";
      bowlruns=bowl2runs ? bowl2runs.value : '';
      bowlwickets=bowl2wicks ? bowl2wicks.value : '';
    }
    setBowling(actbowl);
    setPartnership({bowler: actbowl, bowlerover: bowlovers, bowlerruns: bowlruns, bowlerwickets: bowlwickets, bowlteam: teambowl, batteam: teambat, batter: strikerbatter, batterruns: batterruns, batterballs: batterballs});

    /** feed events */
    let scoreIdx = event.score.findIndex(itm => itm.priority === 1);
    let tBall = parseFloat(event.score[scoreIdx].ball);
    let tEvent = event.score[scoreIdx].result;
    if (
      tEvent.substring(0,6).toUpperCase()==='WICKET' ||
      tEvent.includes('6 runs') ||
      tEvent.includes('4 runs')
    ) {
      setIncomingEvent(true);
      setTimeout(() => {
        fetchEvents(match_id);
      }, 2000);
    }
  };
  const fetch_settings = async () => {
    const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
      method: 'GET',
      headers: headers,
    });
    if (response.status === 200) {
      const data = await response.json(); 
      if (data.upcoming===match_id) {
        setWatchalong(true);
        setSubHeader('watchalong');
      }
    }
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  headers['x-api-key'] = process.env.API_KEY;
  useEffect(() => {
    fetch_settings();
    const updatePageTitle = () => {
      document.title = matchName+" | Cricket8";
    };
    updatePageTitle();
    fetchEvents(match_id);
    return () => {
    };
  }, [matchName]);

  useEffect(() => {
    if (selected && selected!=='feed' && complete!=='true' ) {
      const element = document.getElementById('submatch');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [selected]);

  return (
    <div id='content'>
      <div className='md:mt-[20px] w-full flex'>
        <div className='font-anton text-[#d2ff00] text-[20px] flex-grow'>MATCHES</div>
        <div>
            <button onClick={openModal} className='share-button'>
              <div className='border border-solid border-1 border-[#d2ff00] h-[32px] cursor-pointer px-[5px] pt-[7px]'>
                  <IoMdShare className='text-[#d2ff00]' />
              </div>
            </button>
            <ShareModal isOpen={isModalOpen} onRequestClose={closeModal} url={url} sharename='Page' title={matchName} hashtags={teams} />
        </div>
      </div>

      {subHeader==='standard' ? (
        <div className='MatchPage md:mt-[10px]'>
          <div className='w-full bg-[#35204a] p-[20px] md:flex'>
            <div className='md:w-[670px]'>
              <div className='font-anton inline bg-[#d2ff00] text-[#1b062f] text-[16px] h-[40px] pt-[1px] px-[10px] uppercase'>
                {matchName}
              </div>
              <div className='w-full md:w-[80%] font-anton text-white text-[40px] md:text-[80px] mt-[30px] uppercase'>
                {teams[0]} <span className='text-[#d2ff00]'>V</span> {teams[1]}
              </div>
              <div className='mt-[30px] mb-[20px] md:mb-[0] font-chakra text-white text-[12px] md:text-[20px] flex'>
                <div>{localTime}</div>
                <div><span className="text-[#7cf732] ml-2 mr-2 relative">•</span></div>
                <div>{location}</div>
                <div><span className="text-[#7cf732] ml-2 mr-2 relative">•</span></div>
                <div>
                  {game_phase==='In Play' ? (
                    <span className="!text-[#d73261] flex">
                      <div>&nbsp;&nbsp;LIVE</div>
                      <div className="live-indicator mt-[2px] mr-[10px]">
                        <div className="inner"></div>
                      </div>
                    </span>
                  ) : (
                    <span className="text-[#f73261]">&nbsp;&nbsp;{game_phase}</span>
                  )}
                </div>
              </div>

            </div>
            <div className='w-[100%] md:w-[414px]'>
                <ScoreBox teams={teams} batting={batting} score={score} overs={overs} probability={probability} scoregrid={scoregrid} partnership={partnership} complete={complete} eventStatus={eventStatus} matchComment={matchComment} currentInnings={currentInnings} />
            </div>
          </div>
        </div>
      ) : ( 
        <div className='MatchPage'>
          <div className='relative flex flex-col md:flex-row w-full'>
            <div className='col1 md:flex-shrink-0 md:mr-8 md:max-w-[40%] md:min-w-[40%]'>
              <div className='font-anton text-[30px] text-white truncate'>{matchName}</div>
              <div className='mt-5 flex'>
                <div className='font-anton text-white text-[18px]'>{teams[0]} v {teams[1]}</div>
              </div>
              <div className='mt-1 flex'>
                <div className='dateDisplay'>{localTime}</div>
                <span className="text-[#7cf732] ml-2 mr-2 relative top-[-3px]">•</span>
                <div className='dateDisplay'>{location}</div>
                <span className="text-[#7cf732] ml-2 mr-2] relative top-[-3px]">•</span>
                {game_phase==='In Play' ? (
                  <span className="dateDisplay !text-[#d73261] flex">
                    <div>&nbsp;&nbsp;LIVE</div>
                    <div className="live-indicator mr-[10px]">
                      <div className="inner"></div>
                    </div>
                  </span>
                ) : (
                  <span className="dateDisplay text-[#f73261]">&nbsp;&nbsp;{game_phase}</span>
                )}
              </div>

              <div className='mt-5 w-[100%]'>
                <ScoreBox teams={teams} batting={batting} score={score} overs={overs} probability={probability} scoregrid={scoregrid} partnership={partnership} complete={complete} eventStatus={eventStatus} matchComment={matchComment} currentInnings={currentInnings} />
              </div>
            </div>    
            <div className='col2 flex-grow mt-[10px] md:absolute md:right-[0px] md:bottom-[0px] md:w-[58%] h-[250px] md:h-[370px]'>
              {watchalong===true || stream=='2' && complete!=='true' ? (
                <>
                {user ? (
                  <>
                  {subHeader==='watchalong' ? ( 
                    <div className='text-white'><VideoPlayer subHeader='watchalong' channel='0' /></div>
                  ) : ( 
                    <div className="relative w-full h-full overflow-hidden">
                      <iframe width="100%" height="100%" src={`https://www.decimalsports.com/embeddedplayer/?id=${embedcode}`} frameborder="0" allowfullscreen></iframe>
                    </div>
                  )}
                  </>
                ) : ( 
                  <div className="cursor-pointer w-full h-[350px] bg-cover bg-center flex flex-col items-center justify-center relative" style={{ backgroundImage: `url('${process.env.CDN_ENDPOINT}/assets/rectangle-6964.png')` }} onClick={openLoginModal}>
                    <div className='flex justify-center mb-2 relative top-[15px]'>
                      <div className='bg-white h-[50px] w-[50px] text-[24px] flex items-center justify-center text-[#1b062f] rounded-full pl-[5px]'>
                        <FaPlay />
                      </div>
                    </div>
                    <div className='flex justify-center'>
                      <div className="w-[191px] h-[50px] bg-[#1b062f] flex items-center justify-center">
                        <div className='font-anton text-white text-[16px] text-center'>
                          SIGN UP TO ACCESS VIDEO
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                </>
              ) : (
                <div className='w-full'>
                  <img className='w-full' src={process.env.CDN_ENDPOINT+"/match-image-placeholder.png"} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}




      <div id='submatch'>
       <SubMenu selected={selected} setSelected={setSelected} complete={complete}  /> 
        <div className='relative subs mt-[20px] pb-[20px] bg-white min-h-[200px] mx-0 px-0 left-[-14px] md:left-0 w-[100vw] md:w-[100%]'>
          <div className='relative w-[100%] h-[100px] mt-[-20px] mb-[-60px] pt-0 left-0 [background:linear-gradient(180deg,rgb(27,6,47)_0%,rgba(27,6,47,0)_100%)] opacity-10' />
          {selected==='commentary' ? (
            <div><Commentary commentary={commentary} fullComm={fullComm} teams={teams} probability={probability} scoregrid={scoreGridData} maxovers={mOvers} /></div>
          ) : selected==='scorecard' ? (
            <div><Scorecard scorecard={scorecardData} maxovers={mOvers} striker={striker} bowling={bowling} teams={teams} probability={probability} scoregrid={scoreGridData} overs={overs} complete={complete} battingovers={battingOvers} /></div>

          ) : selected==='charts' ? (
            <div><Charts teams={teams} probability={probability} scoregrid={scoreGridData} maxovers={mOvers} complete={complete} /></div>

          ) : selected==='trading' ? (
            <div><Trading trading={trading} marketprice={marketPriceUpdate} teams={teams} probability={probability} scoregrid={scoreGridData} maxovers={mOvers} /></div>

          ) : selected==='standings' ? (
            <div><Standings teams={teams} probability={probability} scoregrid={scoreGridData} maxovers={mOvers} complete={complete} /></div>

          ) : selected==='feed' ? (
            <div>
              <div className='px-[20px] flex'>
                <div className='anOver w=[100%] mt-[15px] mb-[10px] bgwhite flex-grow'>
                  <Feed feed={feed} incomingEvent={incomingEvent} fullComm={fullComm} selected={selected} setSelected={setSelected} />
                </div>
                <div className='hidden md:inline'>
                  <Charts teams={teams} probability={probability} scoregrid={scoreGridData} maxovers={mOvers} title={false} tp={'0px'} />
                </div>  
              </div>
            </div>
          ) : selected==='lineup' ? (
            <div>
              <div className='px-[20px] flex'>
                <div className='anOver w=[100%] mt-[0px] mb-[10px] bgwhite flex-grow'>
                  <Lineup lineup={lineup} selected={selected} setSelected={setSelected} />
                </div>
                <div className='hidden md:inline mt-[20px]'>
                  <Charts teams={teams} probability={probability} scoregrid={scoreGridData} maxovers={mOvers} title={false} tp={'0px'} />
                </div>  
              </div>
            </div>
          ) : (
            <div>{selected}</div>
          )}
        </div>
      </div>
      <CustomModal id='modal' isOpen={modalIsOpen} onRequestClose={closeLoginModal} contentLabel="Need Account">
          <div>
            <div className='text-[#d2ff00] text-[20px] md:text-[30px] text-center font-anton'>YOU NEED AN ACCOUNT FOR THIS CONTENT</div>
            <div className='mt-[20px] font-chakra text-white text-center text-[14px] md:text-[16px]'>
              Create a free Cricket8 account for access to exclusive content.
            </div>
            <div className='mt-[40px] w-full flex justify-center'>
              <a href={`/signup?back=${deeplink}`}>
                <div className='bg-[#d2ff00] h-[50px] w-[100px] md:w-[265px] text-center'>
                  <div className='font-anton text-[#1b062f] text-[16px] pt-[17px]'>
                    SIGN UP
                  </div>
                </div>
              </a>
            </div>
            <div className='mt-[30px] mb-[40px] w-full justify-center font-chakra text-[14px] md:text-[16px] flex'>
              <div className='text-white pr-[5px]'>Already got an account?</div>
              <div className='text-[#d2ff00]'><a className='text-[#d2ff00] hover:text-underline' href={`/login?back=${deeplink}`}>Login here</a></div>
            </div>
          </div>
      </CustomModal>
    </div>
  );
};
