import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Charts } from "../../MatchDetail/Charts";
import { ClubLogo } from "../../ClubLogo";

export const Standings = ({teams, probability, scoregrid, maxovers, complete}) => {
    const [standings, setStandings] = useState([]);
    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': process.env.API_KEY, 
    };
    const [teamNames, setTeamNames] = useState([]);

    const fetch_standings = async () => {
        const response = await fetch(`${process.env.API_URL}/standings`, {
            method: 'GET',
            headers: headers,
        });
        if (response.status === 200) {
            const data = await response.json();
            
            let teams=data.data;
            teams.sort((a, b) => {
                if (a.pts !== b.pts) {
                    return b.pts - a.pts;
                }
                if (a.nrr !== b.nrr) {
                    return b.nrr - a.nrr;
                }
            });
            setTeamNames(teams);
        }
    }
    useEffect (() => {
        fetch_standings();
    },[]);
  return (
    <div className='px-[20px] flex'>
        <div className='anOver w-[100%] mb-[10px] bgwhite flex-grow'>
            <div className='mb-[20px]'>
                <div className='font-anton text-[#18062f] text-[30px] md:text-[40px] uppercase'>
                    IPL 2024 STANDINGS
                </div>
            </div>
            <div className="w-full h-[30px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]">
                <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'></div>
                <div className='flex-grow pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>TEAM</div>
                <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>M</div>
                <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>W</div>
                <div className='pt-[7px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>L</div>
                <div className='pt-[7px] text-center w-[30px] md:w-[60px] border-solid border-[#18062f] border-r-[1px]'>NRR</div>
                <div className='pt-[7px] text-center w-[30px] md:w-[40px] '>PTS</div>
            </div>
            {teamNames.map((team, idx) => (
                <div key={idx} className="flex h-[55px] font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0"> 
                    <div className='pt-[20px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px] bold'>{idx + 1}</div>
                    <div className='flex-grow pt-[8px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>
                        <div className='flex'>
                            <ClubLogo teamName={team.team_name.trim()} />
                            <div className='md:hidden pl-[15px] pt-[15px] text-[10px] md:text-[14px] text-wrap'>
                                {team.team_short}
                            </div>
                            <div className='hidden md:inline pl-[15px] pt-[15px] text-[10px] md:text-[14px] text-wrap'>
                                {team.team_name}
                            </div>
                        </div>
                    </div>
                    <div className='pt-[20px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>{team.m}</div>
                    <div className='pt-[20px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px]'>{team.w}</div>
                    <div className='pt-[20px] text-center w-[30px] md:w-[40px] border-solid border-[#18062f] border-r-[1px] bg-[#f3f1f5]'>{team.l}</div>
                    <div className='pt-[20px] text-center w-[30px] md:w-[60px] border-solid border-[#18062f] border-r-[1px]'>{team.nrr}</div>
                    <div className='pt-[20px] text-center w-[30px] md:w-[40px] bold bg-[#f3f1f5]'>{team.pts}</div>
                </div>
            ))}
        </div>
        <div className='hidden md:inline'>
            <Charts teams={teams} probability={probability} scoregrid={scoregrid} maxovers={maxovers} title={false} complete={complete} />
        </div>
    </div>
  );
};
