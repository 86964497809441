import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Charts } from "../../MatchDetail/Charts";

export const Trading = ({trading, marketprice, teams, probability, scoregrid, maxovers}) => {
    return (
    <div className=''>
        <div className='flex px-[20px]'>
            <div className='flex-grow anOver w-[100%] mb-[10px] bgwhite'>
                <div className='mb-[20px]'>
                    <div className='font-anton text-[#18062f] text-[40px] text-[30px] md:text-[40px] uppercase'>
                        TRADING
                    </div>
                </div>

                {trading.map((market, index) => {
                    const matchingTrading = marketprice.find(t => t.market_id === market.market_id);
                    return market?.priority === "1" && matchingTrading.display===1 ? (
                        <React.Fragment key={index}>
                        <div className="h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]">
                            <div className='flex-grow pt-[7px] pl-[10px]'>
                            {market?.name || null }
                            </div>
                            {market?.class!=="Win" ? (
                                <>
                                <div className='w-[69px] md:w-[100px] pt-[7px] pl-[10px] text-center'>
                                    Line
                                </div>
                                <div className='w-[69px] md:w-[100px] pt-[7px] pl-[10px] text-center'>
                                    Under
                                </div>
                                <div className='w-[69px] md:w-[100px] w-[10px] pt-[7px] pl-[10px] text-center'>
                                    Over
                                </div>
                                </>
                            ) : ( null )}
                        </div>
                        {market?.class==="Win" ? (
                            <>
                            {market.selections.map((sel, idx) => {
                                const matchingSelection = matchingTrading?.selections.find(selection => selection.name === sel.name);
                                return (
                                    <div key={idx} className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0"> 
                                        <div className='flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px]'>
                                            {sel.name}
                                        </div>
                                        <div className='md:w-[100px] py-[4px] pl-[20px]'>
                                            <div className={`w-[69px] py-[8px] !text-center ${matchingTrading?.status==='ACTIVE' ? 'bg-[#9cd2ff]' : 'bg-[#ffff9f]'}`}>
                                                {matchingSelection?.recommended_price.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            </>
                        ) : (
                            <>
                            <div className="flex font-chakra bg-white text-[10px] md:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0"> 
                                <div className='flex-grow py-[10px] pr-[30px] !text-right bold border-solid border-[#c5c2c8] border-r-[1px]'>
                                    {parseFloat(matchingTrading?.line).toFixed(1)}
                                </div>
                                <div className='md:w-[100px] py-[4px] pl-[20px] border-solid border-[#c5c2c8] border-r-[1px]'>
                                    <div className={`w-[69px] py-[8px] !text-center ${matchingTrading?.status==='ACTIVE' ? 'bg-[#f9c2cb]' : 'bg-[#ffff9f]'}`}>
                                        {matchingTrading?.selections[1].recommended_price.toFixed(2)}
                                    </div>
                                </div>
                                <div className='md:w-[100px] py-[4px] pl-[20px]'>
                                    <div className={`w-[69px] py-[8px] !text-center ${matchingTrading?.status==='ACTIVE' ? 'bg-[#9cd2ff]' : 'bg-[#ffff9f]'}`}>
                                        {matchingTrading?.selections[0].recommended_price.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            </>
                        )}
                        

                        <div className='h-[20px]' />
                        </React.Fragment>
                    ) : null;
                })}
            </div>
            <div className='hidden md:inline'>
                <Charts teams={teams} probability={probability} scoregrid={scoregrid} maxovers={maxovers} title={false} />
            </div>
        </div>
    </div>
  );
};
