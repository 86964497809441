/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  className: any;
  to: string;
}

export const LogoWrapper = ({ className, to }: Props): JSX.Element => {
  return (
    <Link
      className={`w-[300px] h-[300px] bg-[url(/static/img/image-1-2.png)] bg-cover bg-[50%_50%] ${className}`}
      to={to}
    />
  );
};

LogoWrapper.propTypes = {
  to: PropTypes.string,
};
