import React, { useState, useRef, useEffect } from 'react';
import { MdOutlinePinDrop, MdOutlineEmail } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleMessage = async () => {
    setEmailError('');
    setMessageError('');
    setRecaptchaError('');
    setNameError('');
    const email = emailRef.current.value; 
    const name = nameRef.current.value; 
    const message = messageRef.current.value; 
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
    }
    if (name==='') {
      setNameError('Please enter your name.');
    }
    if (message==='') {
      setMessageError('Please enter your message.');
    }
    if (!captchaValue) {
      setRecaptchaError('Error verifying reCaptcha.');
    }
    const headers = {
      'Content-Type': 'application/json',
    };
    headers['x-api-key'] = process.env.API_KEY;
    if (validateEmail(email) && name!=='' && message!=='' && captchaValue) {
      setSending(true);
      const response = await fetch(`${process.env.API_URL}/register/message`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify({ name: name, email: email, message: message, captchaValue: captchaValue })
      });
      if (response.status === 200) {
        const data = await response.json(); 
        if (data.success===true) {
          setSuccess(true);
        } else {
          setSuccess(false);
          setRecaptchaError('Message failed to send.');
          setSending(false);
        }
      }
    }
  };

  useEffect(() => {
    const updatePageTitle = () => {
        document.title = "CONTACT US | Cricket8";
    };
    updatePageTitle();
    return () => {
    };
  }, []);

  function onChange(value) {
    setCaptchaValue(value);
  }

  return (
    <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px]">
        <h2 className="text-[14px] md:text-[20px]">Cricket8</h2>
        <h1 className="text-[30px] md:text-[55px]">CONTACT US</h1>


        <div className='mt-[60px] font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex'>

            <div className='bg-[#d2ff00] h-[120px] w-full md:w-[150px] text-[#1b062f] text-[60px] text-center pt-[20px]'>
              <MdOutlinePinDrop />
              <div className='text-[16px] font-chakra'>Address</div>

            </div>
            <div className='mt-[20px] md:mt-[0px] md:ml-[50px]'>
              <p>
                  <div className='bold mb-[6px]'>Pukka Entertainment Limited (Trading as Cricket8)</div>
                  <div>Registered Address:<br />
                      5th Floor,<br/>167-169 Great Portland Street,<br/>London, W1W 5PF<br />
                      U.K. Registered Company Number: 14138673<br />
                      Phone +44 (0) 208 135 9165<br />
                  </div>
              </p>
            </div>
        </div>


        <div className='mt-[60px] pb-[40px] font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex'>
            <div className='bg-[#d2ff00] h-[120px] w-full md:w-[150px] text-[#1b062f] text-[60px] text-center pt-[20px]'>
              <MdOutlineEmail  />
              <div className='text-[16px] font-chakra'>Message</div>
            </div>
            <div className='mt-[20px] md:mt-[0px] md:ml-[50px] md:mr-[50px] flex-grow'>


              <div className="w-full h-[40px] bg-[#4c3368] border border-solid border-[#fff] mb-[30px]">
                <input ref={nameRef} className="enteremail ml-[20px]" type="text" placeholder="your name" />
                {nameError && <div style={{ color: '#f73261', paddingTop: '5px', paddingLeft: '20px' }}>{nameError}</div>}
              </div>

              <div className="w-full h-[40px] bg-[#4c3368] border border-solid border-[#fff] mb-[30px]">
                <input ref={emailRef} className="enteremail ml-[20px]" type="email" placeholder="enter your email" />
                {emailError && <div style={{ color: '#f73261', paddingTop: '5px', paddingLeft: '20px' }}>{emailError}</div>}
              </div>

              <div className="w-full h-[100px] bg-[#4c3368] border border-solid border-[#fff] mb-[30px]">
                <textarea ref={messageRef} className="enteremail ml-[18px] !mt-[5px] !w-[97%] !h-[88px]" placeholder="your message" />
                {messageError && <div style={{ color: '#f73261', paddingTop: '5px', paddingLeft: '20px' }}>{messageError}</div>}
              </div>

              <div>
                  <ReCAPTCHA
                    sitekey="6LcNubcpAAAAADIenWKHvGywBH0hNAL55T9yrqL8"
                    onChange={onChange}
                  />
                 {recaptchaError && <div style={{ color: '#f73261', paddingTop: '5px', paddingLeft: '20px' }}>{recaptchaError}</div>}
              </div>

              {success ? (
                <div className='text-[#32f78c] font-anton text-[24px] pt-[20px]'>
                  Message sent!
                </div>
              ) : ( null )}
              {!sending ? (
              <div className='mt-[20px] h-[52px] w-[110px] bg-[#d2ff00] font-anton text-[20px] text-[#1b062f] px-[20px] py-[16px] flex cursor-pointer' onClick={handleMessage}>
                <div>SEND</div>
                <div className='ml-[15px]'>
                  <FaArrowRight />
                </div>
              </div>
              ) : ( null )}

            </div>
        </div>


    </div>
  );
};
