import React from "react";

export const ClubLogo = ({ teamName='', height = '40px', width = '40px', fallbackOveride=false }) => {
  let  fallbackImageUrl = `${process.env.CDN_ENDPOINT}/IPL+Team+Images/shield.png`;
  if (fallbackOveride!==false) {
    if (fallbackOveride==='white') {
      fallbackImageUrl = `${process.env.CDN_ENDPOINT}/IPL+Team+Images/shield_white.png`;
    } else {
      fallbackImageUrl = `${process.env.CDN_ENDPOINT}/IPL+Team+Images/shield_grey.png`;
    }
  }
  const teamlogo = `${process.env.CDN_ENDPOINT}/IPL+Team+Images/${teamName.replace(/ /g, '+')}/${teamName.replace(/ /g, "_")}.png`;
  const handleError = (e) => {
    e.target.src = fallbackImageUrl;
  };
  function handleLoad(event) {
    const img = event.target;
    if (img.naturalHeight > img.naturalWidth) {
      img.style.maxHeight = `${height}`;
    } else {
      // For images where width is greater,
    }
  }
  return (
    <div>
        <img src={teamlogo} onError={handleError} alt={teamName} className={`w-[${width}]`} onLoad={handleLoad} style={{width: width}} />
    </div>
  );
};
