import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

export const VideoPlayer = ({ subHeader = 'standard', channel = 'x' }) => {
  const videoRef = useRef(null);
  const videoEndpoints = [
    'https://31f8be510446.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.533267122454.channel.x5voLscd2oJA.m3u8',
    'https://d1j0naooljly8m.cloudfront.net/out/v1/6492357950ff4b00bd79900e72fedd11/CMAF_HLS/index.m3u8',
    'https://d25rrcl4cfy9bc.cloudfront.net/out/v1/2ab3dfa3215949a98393a85151cba513/CMAF_HLS/index.m3u8',
    'https://d2l5arpwym7tzt.cloudfront.net/out/v1/40d89115ba954483950577e9563d1133/CMAF_HLS/index.m3u8',
    'https://d24dvt6kw6gbre.cloudfront.net/out/v1/a77198f0984e4a87a91646e398a096ed/CMAF_HLS/index.m3u8',
    'https://d1c38kgjm9r55k.cloudfront.net/out/v1/3e4798c5b3f64898b7f819b6a85c5108/CMAF_HLS/index.m3u8',
    'https://d2c10t90gqjfp1.cloudfront.net/out/v1/c5abf398e1754bc2a4a7e098f8519867/CMAF_HLS/index.m3u8',
    'https://d9j8x0wg5yrsf.cloudfront.net/out/v1/68eedd5fd8d94b4b91b154b5b6eb82dd/CMAF_HLS/index.m3u8'
  ];

  useEffect(() => {
    let hls;
    let retryCount = 0;
    const maxRetries = 10;
    const retryDelay = 5000;

    const setupPlayer = (src) => {
      console.log(`Setting up player for source: ${src}`);
      if (videoRef.current) {
        if (Hls.isSupported()) {
          hls = new Hls({
            lowLatencyMode: true,
            liveSyncDurationCount: 3,
            maxLiveSyncPlaybackRate: 1.5,
            maxBufferLength: 60,
            backBufferLength: 90,
            maxFragLookUpTolerance: 0.2,
            startPosition: -1,
            debug: true
          });

          hls.loadSource(src);
          hls.attachMedia(videoRef.current);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            console.log('Manifest parsed successfully');
            if (videoRef.current) {
              videoRef.current.play().catch(error => console.log('Error auto-playing video:', error));
            }
          });

          hls.on(Hls.Events.ERROR, (event, data) => {
            console.log('HLS.js Error:', data);
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.log('A network error occurred:', data);
                  retrySetupPlayer(src);
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log('A media error occurred:', data);
                  hls.recoverMediaError();
                  break;
                default:
                  console.log('An unrecoverable error occurred:', data);
                  hls.destroy();
                  break;
              }
            }
          });
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = src;
          videoRef.current.play().catch(error => console.log('Error auto-playing video:', error));
        }
      }
    };

    const retrySetupPlayer = (src) => {
      if (retryCount < maxRetries) {
        retryCount += 1;
        console.log(`Retrying to load video (${retryCount}/${maxRetries})...`);
        setTimeout(() => setupPlayer(src), retryDelay);
      } else {
        console.log('Max retries reached. Could not load video.');
      }
    };

    console.log(`Initializing player for channel: ${channel}`);
    setupPlayer(videoEndpoints[channel]);

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [channel]);

  return (
    <div id="video_container" className="w-full">
      <video
        ref={videoRef}
        className="w-full h-auto"
        id="c8-video-player"
        autoPlay
        muted
        controls
        playsInline
      ></video>
    </div>
  );
};
