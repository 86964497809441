import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClubLogo } from "../../ClubLogo";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Chart } from 'chart.js';

export const Manhattan = ({teams=['team1','team2'], scoregrid, maxovers}) => {
  function isEven(n) {
    return n % 2 == 0;
  }
  const [curIns, setCurIns] = useState("inns"+scoregrid.inns_now);
  let od, ev='';
  if (isEven(scoregrid.inns_now)) {
      ev=scoregrid.bat_now;
      od=scoregrid.bowl_now;
  } else {
      od=scoregrid.bat_now;
      ev=scoregrid.bowl_now;
  }
  let label=[];
  for (let i=1; i<=maxovers[0]; i++) {
      label.push(i);
  }
  let Cdata=[];
  for (let ins=1; ins<=4; ins++) {
    let wicketcount=0;
    let inskey="inns"+ins;
    if (scoregrid.overs[inskey]) {
        let thisIns=[];
        let insTotal=0;
        scoregrid.overs[inskey].forEach((ov,idx) => {
            insTotal+=parseInt(ov.runs);
            let didfall=false;
            if (wicketcount<ov.wkts) {
              didfall=true;
              wicketcount=ov.wkts;
            }
            thisIns.push({x: idx, y: ov.runs, wicket: didfall});
        })
        if (!isEven(ins)) {
            Cdata[ins-1]={
                label: od,
                data: thisIns,
                backgroundColor: ["#32f78c"],
                barThickness: 3,
                pointStyle: false
            }
        } else {
            Cdata[ins-1]={
                label: ev,
                data: thisIns,
                backgroundColor: ["#f73261"],
                barThickness: 3,
                pointStyle: false
            }
        }
    }
}
const highlightWicketsPlugin = {
  id: 'highlightWicketsPlugin',
  afterDatasetsDraw: function(chart, args, options) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        if (dataset.data[index].wicket) {
          if (i === 0) {
            ctx.fillStyle = 'rgb(50, 247, 140)';
          } else {
            ctx.fillStyle = 'rgb(247, 50, 97)'; 
          }
          const position = bar.getProps(['x', 'y'], true);
          const outerRadius = 5; 
          ctx.beginPath();
          ctx.arc(position.x, position.y, outerRadius, 0, 2 * Math.PI);
          ctx.fill();
          const innerRadius = 3;
          ctx.fillStyle = 'rgb(27, 6, 47)';
          ctx.beginPath();
          ctx.arc(position.x, position.y, innerRadius, 0, 2 * Math.PI);
          ctx.fill();
        }
      });
    });
  }
};
Chart.register(highlightWicketsPlugin);
const [chartData, setChartData] = useState({
  labels: label, 
  datasets: Cdata,
});
const chartOptions = {
  plugins: {
    legend: {
        display: false,
    },
    tooltip: {
        enabled: false,
    },
  },
  scales: {
    x: { 
        ticks: {
            autoSkip: false,
            font: {
                family: "'Chakra Petch', Helvetica",
                weight: 'normal',
            },
            callback: function(value, index) {
                if (value === 0 || (value + 1) % 5 === 0) {
                    return value+1;
                } else {
                    return '';
                }
            },
            color: "#fff", 
        },
    },
    y: {
        ticks: {
            color: "#fff",
            font: {
                family: "'Chakra Petch', Helvetica",
                weight: 'normal',
            }
        },
        border:{dash: [4, 4]},
        grid: {
            color: '#aaa',
            tickBorderDash: [2, 3],
        }
    },
  },
};





return (
    <div className='w-[350px]'>
        <div className="w-[100%] h-[30px] top-0 left-0 bg-[#d2ff00] font-anton text-[#18062f] font-normal text-[14px] pt-[8px] pl-[10px] border-solid border-[1px] border-[#18062f]">
            MANHATTAN
        </div>
        <div className='relative bg-[#1b062f] w-[100%] pt-[10px] pb-[10px]'>
            <div className='font-chakra text-[12px] absolute top-[12px] left-[8px]'>Runs</div>
            <div className='relative top-[10px] left-0'>
              <Bar className='pl-[5px] pr-[5px]' data={chartData} options={chartOptions} />
            </div>
            <div className='font-chakra bold pt-[30px] ml-[5px] mr-[5px] flex'>
                <div className='w-[50%] pl-[5px] flex'>
                    <div className='bg-[#f73261] h-[14px] w-[14px] mr-[5px]' />
                    {ev}
                </div>
                <div className='w-[50%] pl-[5px] flex text-right'>
                    <div className='bg-[#32f78c] h-[14px] w-[14px] mr-[5px]' />
                    {od}
                </div>
            </div>
        </div>
    </div>
  );
};



