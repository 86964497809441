import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Manhattan } from "./Manhattan";
import { WinProbability } from "./WinProbability";
import { Worm } from "./Worm";

export const Charts = ({teams, probability, scoregrid, maxovers, title=true, tp='20px', complete }) => {
  useEffect (() => {
    
  });
  return (
    <>
    {scoregrid.overs ? (

        <div className='px-[20px]'>
            <div className={`mb-[${tp}] flex`}>
                <div className='font-anton text-[#18062f] text-[40px] uppercase flex-grow'>
                    {title ? (
                        <>
                        MATCH CHARTS
                        </>
                    ) : ( <>&nbsp;</> )}
                </div>
            </div>

            {complete!=='true' ? (
                <div className='pb-[20px]'>
                    <WinProbability teams={teams} probability={probability} maxovers={maxovers} />
                </div>
            ) : ( null )}

            <div className='pb-[20px]'>
                <Worm teams={teams} scoregrid={scoregrid} maxovers={maxovers} />
            </div>

            <div className='pb-[20px]'>
                <Manhattan teams={teams} scoregrid={scoregrid} maxovers={maxovers}  />
            </div>

        </div>
    ) : ( null )}
    </>
  );
};
