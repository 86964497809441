import React, { useState } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';

const ShareModal = ({ isOpen, onRequestClose, url, sharename='article', title='', hashtags=[] }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleShare = (platform) => {
    if (sharename==='GAME PLAY') {
      addSharePoints(platform);
    }
  };

  const addSharePoints = async (platform) => {
    const session_token = Cookies.get('c8_session_token');
    if (session_token) {
      const response = await fetch(`${process.env.API_URL}/sharepoints/${platform}/${session_token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY,
        },
      });
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Share Modal"
        ariaHideApp={false}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Share this {sharename}</h2>
        <div className="share-buttons">
          <FacebookShareButton 
            hashtags={"#"+hashtags.join(" ")}
            url={url}
            quote={'Check this out - Grab your free coins before the launch of our exciting new game! Join the Cricket8.com community today and start collecting your rewards!'}
            onClick={() => handleShare('Facebook')}
          >
            <FacebookIcon size={32} square />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title} hashtags={hashtags} onClick={() => handleShare('Twitter')}>
            <XIcon size={32} square />
          </TwitterShareButton>
          <WhatsappShareButton url={url} onClick={() => handleShare('Whatsapp')}>
            <WhatsappIcon size={32} square />
          </WhatsappShareButton>
          <LinkedinShareButton url={url} onClick={() => handleShare('LinkedIn')}>
            <LinkedinIcon size={32} square />
          </LinkedinShareButton>
          <EmailShareButton url={url} onClick={() => handleShare('Email')}>
            <EmailIcon size={32} square />
          </EmailShareButton>
        </div>
      </Modal>
    </div>
  );
};

export default ShareModal;
