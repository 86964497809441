import React from "react";
import { Link } from "react-router-dom";
import { ClubLogo } from "../../ClubLogo";
import { IconArrowStem21 } from "../../../icons/IconArrowStem21";
import { FaVideo } from "react-icons/fa";

export const MatchCard = ({ match_id, tournament, matchTime, homeTeamName, awayTeamName, homeTeamScore='', awayTeamScore='', diff, batnow, complete='false', watchalongscheduled=false, stream='false' }) => {
  tournament=tournament.replace('Indian Premier League','IPL 2024');
  const handleMatchClick = (matchId, localTime, complete) => {
    if (localTime==='LIVE' || localTime==='Match Complete' || complete==='true') {
      window.location="/matchdetail?id="+matchId+"&status="+complete;
    }
  };
  return (
    <div className={`MatchCard ${matchTime==='LIVE' || matchTime==='Match Complete' || complete==='true' ? 'cursor-pointer' : ''}`} onClick={() => handleMatchClick(match_id, matchTime, complete)}> 
        <div className="header">
            <div className='title truncate !text-[14px] !pt-[12px]'>
                {tournament}
            </div>
            <div className={`schedule ${matchTime === 'LIVE' ? 'live' : ''}`}>
                {matchTime}
                {matchTime==='LIVE' ? (
                    <div className="live-indicator mr-[10px]">
                        <div className="inner"></div>
                    </div>
                ) : ( null )}
           </div>
        </div>
        <div className='body'>
            <div className='homeTeam relative'>
                <div className='top'>
                    <div className='logo'>
                        <ClubLogo teamName={homeTeamName} />
                    </div>
                    <div className='scoreline'>
                        {homeTeamScore}
                    </div>
                </div>
                <div className='name'>
                    {homeTeamName}
                </div>
                {homeTeamName===batnow ? ( <div className='absolute right-[0px] top-[0px] bg-[#f73261] h-[49px] w-[3px]' /> ) : (null)}
            </div>
            <div className='awayTeam relative'>
                <div className='top'>
                    <div className='logo'>
                        <ClubLogo teamName={awayTeamName} />
                    </div>
                    <div className='scoreline'>
                        {awayTeamScore}
                    </div>
                </div>
                <div className='name'>
                    {awayTeamName}
                </div>
                {awayTeamName===batnow ? ( <div className='absolute right-[0px] top-[0px] bg-[#f73261] h-[49px] w-[3px]' /> ) : (null)}
            </div>
        </div>
        {(matchTime === 'LIVE' || complete === 'true') ? (
            <div className={`footer relative`}>
                <div className={`action truncate`}>
                    {matchTime==='LIVE' ? (
                     <>
                     {watchalongscheduled==='false' ? (
                        <div>
                            {stream==='true' ? ( 
                                <div className='bg-[#f73261] absolute top-0 left-0 h-[38px] pl-[10px] pt-[12px] pr-[10px]'>
                                    <img className="pr-[10px]" alt="Vector" src="/img/camera.svg" />
                                    LIVE COVERAGE
                                </div>
                            ) : ( 
                                <span>GO TO MATCH</span>
                            )}
                            
                        </div>
                        
                     ) : (
                        <div className='bg-[#f73261] absolute top-0 left-0 h-[38px] pl-[10px] pt-[12px] pr-[10px]'>
                            <img className="pr-[10px]" alt="Vector" src="/img/camera.svg" />
                            WATCH ALONG LIVE
                        </div>
                     )}
                     </>
                    ) : matchTime=='Match Complete' || complete==='true' ? (
                        <>
                        {complete==='true' ? (
                            <span>{diff.substring(17)}</span>
                        ) : (
                            <span>MATCH COMPLETE</span>
                        )}
                        </>
                    ) : (
                        <span>STARTS IN {diff}</span>
                    )}
                </div>
                {(matchTime==='LIVE' || complete==='true') && (
                  <div className='prompt'>
                      <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                  </div>
                )}
            </div>
        ) : (
          <div className={`footer ${matchTime === 'LIVE' ? '!bg-[#d73261]' : ''}`}>
              <div className={`action w-full`}>
                  {matchTime==='LIVE' ? (
                     <>
                     {watchalongscheduled==='false' ? (
                        <span>LIVE</span>
                     ) : (
                      <span>
                          <img className="pr-[10px]" alt="Vector" src="/img/camera.svg" />
                          WATCH ALONG LIVE
                      </span>
                     )}
                     </>
                  ) : matchTime=='Match Complete' || complete==='true' ? (
                      <>
                      {complete==='true' ? (
                          <span>{diff.substring(17)}</span>
                      ) : (
                          <span>MATCH COMPLETE</span>
                      )}
                      </>
                  ) : (
                    <div className='w-full flex'>
                        <div className='flex-grow'>{diff==='Delayed' ? ( 'DELAYED' ) : ( <>STARTS IN {diff}</> )}</div>
                        <div className='font-chakra text-[14px] text-[#32f78c]'>
                        {watchalongscheduled!=='false' ? (
                            <>
                            <FaVideo className='pt-[3px]' /> WATCH-ALONG SCHEDULED
                            </>
                        ) : stream==='true' ? ( 
                            <>
                                <FaVideo className='pt-[3px]' /> LIVE COVERAGE
                            </>

                        ) : ( null )}    
                        </div>
                    </div>
                  )}
              </div>
              {(matchTime==='LIVE' || complete==='true') && (
                <div className='prompt'>
                    <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                </div>
              )}
          </div>
        )}
    </div>
  );
};
