import React, { useEffect, useState, useRef } from "react";
import { PiDeviceMobile } from "react-icons/pi";
import { BsArrowLeft } from "react-icons/bs";
import { DialingCodes } from "../Account/DialingCodes";
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import GoogleSignInButton from "../GoogleSignInButton";


export const Signup = () => {
    const [signupStage, setSignupStage] = useState(1);
    const phoneRef = useRef(null);
    const dialRef = useRef(null);
    const verificationRef = useRef(null);
    const [phoneError, setPhoneError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [dialcode, setDialCode] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [sentto, setSentto] = useState(null);
    const [submittingCode, setSubmittingCode] = useState(false);
    const [submittingError, setSubmittingError] = useState('');
    const navigate = useNavigate();
    const xlocation = useLocation();
    let path='SIGN UP';
    if (xlocation.pathname==="/login") { path='LOGIN'; }
    const queryParams = new URLSearchParams(xlocation.search);
    const gamesession= queryParams.get("gamesession") || null;
    const deeplink=queryParams.get("back");

    const handleSubmit = async () => {
        setPhoneError('');
        const phone = phoneRef.current.value; 
        const dial = dialRef.current.value;
        setPhoneNumber(phone);
        setDialCode(dial);
        if (phone === '') {
            setPhoneError('Please enter your phone number.');
        } else {
            setSubmitting(true);
            const headers = {
                'Content-Type': 'application/json',
            };
            headers['x-api-key'] = process.env.API_KEY;
            const response = await fetch(`${process.env.API_URL}/otp/send`, {
                method: 'post',
                headers: headers,
                body: JSON.stringify({ dialcode: dial, phonenumber: phone })
            });
            if (response.status === 200) {
                const data = await response.json(); 
                setSentto(data.sento);
                setSignupStage(3);
                setSubmitting(false);
            } else {
                setPhoneError('Error sending to phone number, please check entered number.');
                setSubmitting(false);
            }
        }
    };

    const handleSubmitCode = async () => {
        setSubmittingError('');
        const verifycode = verificationRef.current.value;
        if (verifycode==='') {
            setSubmittingError("Please enter your code.");
        } else {
            setSubmittingCode(true)
            const headers = {
                'Content-Type': 'application/json',
            };
            headers['x-api-key'] = process.env.API_KEY;
            const response = await fetch(`${process.env.API_URL}/otp/verify`, {
                method: 'post',
                headers: headers,
                body: JSON.stringify({ sentto: sentto, verifycode: verifycode })
            });
            if (response.status === 200) {
                const data = await response.json(); 
                await new Promise((resolve) => {
                    Cookies.set('c8_session_token', data.token, { expires: 365, secure: false, sameSite: 'strict' });
                    resolve();
                });
                if (gamesession!==null) {
                    const response = await fetch(`${process.env.API_URL}/collectwinnings/${gamesession}/${data.token}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.API_KEY,
                      },
                    });
                }
                if (deeplink===null) {
                    window.location.href = '/account';
                } else {
                    window.location.href = deeplink;
                }
            } else {
                setSubmittingCode(false);
                setSubmittingError('Verification failed. Please check code and try again.');
            }
        }
    };
    useEffect(() => {
        const updatePageTitle = () => {
            document.title = "ACCOUNT SIGNUP | Cricket8";
        };
        updatePageTitle();
        return () => {};
    }, []);
    return (
        <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[20px]'>
            {signupStage === 1 ? (
                <div id='stage1'>
                    <div className='w-full flex'>
                        <div className='flex-grow'>
                            <h1 className='text-[30px] md:text-[55px] !text-[#d2ff00]'>{path}</h1>
                        </div>
                        <div className='text-right'>
                            <div className='font-chakra text-white'>
                                {path!=='LOGIN' ? (<>Already registered?</>)
                                : ( <>Don't have an account?</>)}
                            </div>
                            <div className='font-chakra text-[#d2ff00]'>
                                {path!=='LOGIN' ? (<><a className='text-white hover:underline' href='/login'>Login here...</a></>)
                                : ( <><a className='text-white hover:underline' href='/signup'>Sign up here...</a></>)}
                            </div>
                        </div>
                    </div>

                    {gamesession ? (
                        <div className='mt-[20px] text-white font-chakra text-normal normal-case text-[14px]'>
                            Create an account or login to store your coins for when C8 Trader launches.
                        </div> ) : ( null )
                    }

                    <div 
                        className='mt-[30px] bg-[#1a73e8] h-[50px] w-full md:w-[600px] flex justify-center cursor-pointer pt-[5px]'
                    >
                        <GoogleSignInButton deeplink={deeplink} gamesession={gamesession} />
                    </div>
                    <div 
                        className='mt-[30px] bg-[#d2ff00] h-[50px] w-full md:w-[600px] flex justify-center cursor-pointer'
                        onClick={() => setSignupStage(2)}
                    >
                        <div className='mt-[10px] w-[30px] h-[30px] bg-[#1b062f]'>
                            <PiDeviceMobile className='mt-[6px] ml-[8px] text-[#d2ff00] h-[18px]' />
                        </div>
                        <div className='ml-[10px] pt-[17px] font-anton text-[16px] text-[#1b062f]'>
                            {path} WITH PHONE NUMBER (OTP)
                        </div>
                    </div>


                </div>
            ) : signupStage === 2 ? (
                <div id='stage2'>
                    <div 
                        className='flex h-[20px] cursor-pointer'
                        onClick={() => setSignupStage(1)}
                    >
                        <div className='bg-[#d2ff00] w-[20px] rotate-180'>
                            <BsArrowLeft className="!w-[14px] !h-[14px] ml-[4px] mt-[3px] text-[#1B062F]" />
                        </div>
                        <div className='bg-[#d2ff00] ml-[3px] pt-[3px] px-[10px] text-anton text-[#1b062f] text-[14px]'>
                            BACK
                        </div>
                    </div>
                    <div className='mt-[30px]'>
                        <h1 className='text-[30px] !text-[#d2ff00]'>
                            SIGN UP WITH PHONE NUMBER (OTP)
                        </h1>
                    </div>
                    <div className='mt-[30px] font-chakra text-white text-[14px]'>
                        Enter your phone number and we'll send you a code by SMS.
                    </div>

                    <div className="mt-[30px] w-full md:w-[600px] pb-[20px] diagonal-gradient p-[15px]">
                        <div className='text-white font-anton text-[16px]'>
                            ENTER YOUR PHONE NUMBER
                        </div>
                        <div className='mt-[15px] flex'>
                            <div>
                                <DialingCodes ref={dialRef} />
                            </div>
                            <div className='flex-grow pl-[5px]'>
                                <div className='w-full h-[40px] bg-[#fff] border-none mt-[1px]'>
                                    <input ref={phoneRef} className='enteremail !text-[#1b062f] !pl-[10px]' type='text' placeholder="Enter phone number..." />
                                </div>
                            </div>
                        </div>
                        {!submitting ? (
                            <div className='mt-[15px] bg-[#d2ff00] w-full h-[50px] text-[#1b062f] pt-[15px] font-anton text-[18px] text-center cursor-pointer' onClick={handleSubmit}>
                                SUBMIT PHONE NUMBER
                            </div>
                        ) : (
                            <div className='h-[50px] pt-[29px] font-chakra text-white text-[14px] text-center'>
                                Submitting...
                            </div>
                        )}
                        {phoneError && <div style={{ color: '#f73261', paddingTop: '10px', paddingLeft: '0px' }}>{phoneError}</div>}
                    </div>
                </div>
            ) : signupStage === 3 ? (
                <div id='stage3'>
                    <div 
                        className='flex h-[20px] cursor-pointer'
                        onClick={() => setSignupStage(2)}
                    >
                        <div className='bg-[#d2ff00] w-[20px] rotate-180'>
                            <BsArrowLeft className="!w-[14px] !h-[14px] ml-[4px] mt-[3px] text-[#1B062F]" />
                        </div>
                        <div className='bg-[#d2ff00] ml-[3px] pt-[3px] px-[10px] text-anton text-[#1b062f] text-[14px]'>
                            BACK
                        </div>
                    </div>
                    <div className='mt-[30px]'>
                        <h1 className='text-[30px] !text-[#d2ff00]'>
                            SIGN UP WITH PHONE NUMBER (OTP)
                        </h1>
                    </div>
                    <div className="mt-[30px] w-full md:w-[600px] pb-[20px] diagonal-gradient p-[15px]">
                        <div className='text-white font-anton text-[16px]'>
                            ENTER VERIFICATION CODE
                        </div>
                        <div className='mt-[15px] text-white font-chakra text-[12px]'>
                            We've sent a verification code to {dialcode} {phonenumber}.
                        </div>
                        <div className='mt-[15px]'>
                            <div className='w-full'>
                                <div className='w-full h-[40px] bg-[#fff] border-none mt-[1px]'>
                                    <input ref={verificationRef} className='enteremail !text-[#1b062f] !pl-[10px]' type='text' placeholder="Enter verification code..." />
                                </div>
                            </div>
                        </div>
                        {!submittingCode ? (
                            <div className='mt-[15px] bg-[#d2ff00] w-full h-[50px] text-[#1b062f] pt-[15px] font-anton text-[18px] text-center cursor-pointer' onClick={handleSubmitCode}>
                                SUBMIT CODE
                            </div>
                        ) : ( 
                            <div className='h-[50px] pt-[29px] font-chakra text-white text-[14px] text-center'>
                                Checking code...
                            </div>
                        )}
                        {submittingError && <div style={{ color: '#f73261', paddingTop: '10px', paddingLeft: '0px' }}>{submittingError}</div>}
                    </div>
                    <div className='mt-[30px] flex'>
                        <div className='text-white font-chakra text-[14px]'>Didn't get a code?&nbsp;</div>
                        <div className='text-[#d2ff00] font-chakra text-[14px] cursor-pointer' onClick={() => setSignupStage(2)}>Try again</div>
                    </div>
                </div>
            ) : (null)}
        </div>
    );
};
