/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  className: any;
  iconYoutube: string;
}

export const IconYoutube = ({ className, iconYoutube = "/img/icon-youtube-1.svg" }: Props): JSX.Element => {
  return (
    <img className={`absolute w-[27px] h-[20px] top-0 left-0 ${className}`} alt="Icon youtube" src={iconYoutube} />
  );
};

IconYoutube.propTypes = {
  iconYoutube: PropTypes.string,
};
