import React, { useEffect, useState, useRef } from "react";
import { PiDeviceMobile } from "react-icons/pi";
import { FaGoogle } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import { DialingCodes } from "../Account/DialingCodes";
import { Countries } from "../Account/Countries";
import { Teams } from "../Account/Teams";
import { Tournaments } from "../Account/Tournaments";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

export const Account = () => {
    const usernameRef = useRef('');
    const emailRef = useRef('');
    const phoneRef = useRef('');
    const countryRef = useRef('');
    const team1Ref = useRef(null);
    const team2Ref = useRef(null);
    const tournamentRef = useRef('');
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedTournament, setSelectedTournament] = useState('');
    const [selectedTeam1, setSelectedTeam1] = useState('');
    const [selectedTeam2, setSelectedTeam2] = useState('');
    const marketingRef = useRef('');
    const [thisAccount, setthisAccount] = useState([]);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [saving, setSaving] = useState(false);
    const [usersession, setUsersession] = useState('');
    const [saveFeedback, setSaveFeedback] = useState('');
    const [feedbackError, setFeedbackError] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const navigate = useNavigate();

    async function getUser(session_token) {
        try {
          const response = await fetch(`${process.env.API_URL}/verifysession/${session_token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.API_KEY,
            },
          });
      
          if (response.status === 200) {
            const data = await response.json();
            if (data) {
                setUsersession(session_token);
                data.verifychannel=data.verifychannel.trim();
                setthisAccount(data);
                setUsername(data.username || '');
                setEmail(data.email || '');
                setPhone(data.phone || '');
                if (data.country!==null) {
                    countryRef.current=data.country.trim();
                    setSelectedCountry(countryRef.current);
                }
                if (data.favourites!==null) {
                    tournamentRef.current=data.favourites.favtour;
                    setSelectedTournament(tournamentRef.current);
                    team1Ref.current=data.favourites.favteam1;
                    setSelectedTeam1(team1Ref.current);
                    team2Ref.current=data.favourites.favteam2;
                    setSelectedTeam2(team2Ref.current);
                }
            }
          } else {
            setUsersession('');
            return null;
          }
        } catch (error) {
          setUsersession('');
          return null; 
        }
      }

    const handleSignout = async () => {
        Cookies.remove('c8_session_token');
        window.location.href = '/';
    };

    const handleDeleteClick = async () => {
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        const headers = {
            'Content-Type': 'application/json',
        };
        headers['x-api-key'] = process.env.API_KEY;
        const response = await fetch(`${process.env.API_URL}/account/delete`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({ usersession: usersession })
        });
        if (response.status === 200) {
            setIsDeleteModalOpen(false);
            Cookies.remove('c8_session_token');
            window.location.href = '/';
        }
    };

    const handleSave = async () => {
        setSaving(true);
        setSaveFeedback('');
        setFeedbackError(false);
        const marketing = marketingRef.current.checked;
        const favtour=selectedTournament;
        const favteam1=selectedTeam1;
        const favteam2=selectedTeam2;
        const headers = {
            'Content-Type': 'application/json',
        };
        headers['x-api-key'] = process.env.API_KEY;
        const response = await fetch(`${process.env.API_URL}/account/update`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({ username: username, email: email, phone: phone, usersession: usersession, marketing: marketing, country: selectedCountry, favtour: favtour, favteam1: favteam1, favteam2: favteam2 })
        });
        if (response.status === 200) {
            const data = await response.json();
            setSaving(false);
            if (data.error) {
                setFeedbackError(true);
            }
            setSaveFeedback(data.message);
        }
    };

    useEffect(() => {
        const updatePageTitle = () => {
            document.title = "My Account | Cricket8";
        };
        updatePageTitle();

        const session_token = Cookies.get('c8_session_token');
        getUser(session_token);
        return () => {};
    }, []);

    return (
        <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[20px]'>
            <div className='flex-grow'>
                <h1 className='text-[30px] md:text-[55px] !text-[#d2ff00]'>MY ACCOUNT</h1>
            </div>

            <div className="mt-[30px] w-full md:w-[600px] pb-[20px] diagonal-gradient p-[15px]">
                {thisAccount.verifychannel==='google' ? (
                    <div className='flex'>
                        <div className='mt-[10px] w-[30px] h-[30px] bg-[#1b062f]'>
                            <FaGoogle className='mt-[6px] ml-[8px] text-[#d2ff00] h-[18px]' />
                        </div>
                        <div className="ml-[10px] pt-[17px] font-chakra text-[14px] text-[#fff] flex">
                            <div className='bold pr-[10px]'>Signed in with Google</div>
                            <div>{thisAccount.sent_to}</div>
                        </div>
                    </div>
                ) : (
                    <div className='flex'>
                        <div className='mt-[10px] w-[30px] h-[30px] bg-[#1b062f]'>
                            <PiDeviceMobile className='mt-[6px] ml-[8px] text-[#d2ff00] h-[18px]' />
                        </div>
                        <div className="ml-[10px] pt-[17px] font-chakra text-[14px] text-[#fff] flex">
                            <div className='bold pr-[10px]'>Signed in with phone number (OTP)</div>
                            <div>{thisAccount.sent_to}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="mt-[30px] w-full md:w-[600px] pb-[20px] diagonal-gradient p-[15px]">
                <div className='flex'>
                    <div className='flex-grow font-anton text-white text-[16px]'>
                        C8 PROFILE
                    </div>
                    <div className='font-chakra text-white text-[12px] text-right'>
                        Optional
                    </div>
                </div>
                <div className='mt-[30px] font-anton text-white text-[12px]'>
                    USERNAME
                </div>
                <div className='mt-[10px] w-full'>
                    <div className='w-full h-[40px] bg-[#fff] border-none mt-[1px]'>
                        <input 
                            ref={usernameRef} 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            className='enteremail !text-[#1b062f] !pl-[10px]' 
                            type='text' 
                            placeholder="Enter username..." 
                        />
                    </div>
                </div>
                <div className='mt-[30px] font-anton text-white text-[12px]'>
                    PROFILE PICTURE
                </div>
                <div className='mt-[10px]'>
                    <img src={thisAccount.profile_pic} className='w-[40px] h-[40px]' />
                </div>
                <div className='mt-[30px] font-anton text-white text-[12px]'>
                    EMAIL
                </div>
                <div className='mt-[10px] w-full'>
                    <div className='w-full h-[40px] bg-[#fff] border-none mt-[1px]'>
                        <input 
                            ref={emailRef} 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className={`enteremail !text-[#1b062f] !pl-[10px] ${thisAccount.verifychannel === 'google' ? ('cursor-not-allowed') : ('')}`} 
                            type='text' 
                            placeholder="Enter email..." 
                            disabled={thisAccount.verifychannel === 'google'}
                        />
                    </div>
                </div>
                <div className='mt-[30px] font-anton text-white text-[12px]'>
                    PHONE
                </div>
                <div className='mt-[10px] w-full'>
                    <div className='w-full h-[40px] bg-[#fff] border-none mt-[1px]'>
                        <input 
                            ref={phoneRef} 
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)} 
                            className='enteremail !text-[#1b062f] !pl-[10px]' 
                            type='text' 
                            placeholder="Enter phone number..." 
                        />
                    </div>
                </div>
                <div className='mt-[30px] font-anton text-white text-[12px]'>
                    COUNTRY
                </div>
                <div className='mt-[10px] w-full'>
                    <div>
                        <Countries ref={countryRef} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
                    </div>
                </div>
                <div className='mt-[30px] font-anton text-white text-[12px]'>
                    FAVOURITE TEAMS
                </div>
                <div className='mt-[10px] w-full'>
                    <div>
                        <Tournaments ref={tournamentRef} selectedTournament={selectedTournament} setSelectedTournament={setSelectedTournament} />
                    </div>
                </div>
                <div className='mt-[10px] w-full'>
                    <div>
                        <Teams ref={team1Ref} selectedTeam={selectedTeam1} setSelectedTeam={setSelectedTeam1} />
                    </div>
                </div>
                <div className='mt-[10px] w-full'>
                    <div>
                        <Teams ref={team2Ref} selectedTeam={selectedTeam2} setSelectedTeam={setSelectedTeam2} />
                    </div>
                </div>
            </div>
            <div className='mt-[30px]'>
                <div className='flex'>
                    <div><input type='checkbox' ref={marketingRef} defaultChecked={thisAccount.marketing} /></div>
                    <div className='ml-[10px] pt-[5px] font-chakra text-[12px] text-white'>I'm happy to receive Cricket8 product and marketing messages.</div>
                </div>
            </div>
            <div className='mt-[10px]'>
                <div className='font-chakra text-[12px] text-white'>Cricket8 will never share or sell your data.</div>
            </div>
            {!saving ? (
                <div>
                    <div className='mt-[15px] bg-[#d2ff00] w-full md:w-[600px] h-[50px] text-[#1b062f] pt-[15px] font-anton text-[18px] text-center cursor-pointer' onClick={handleSave}>
                        SAVE CHANGES
                    </div>
                    {saveFeedback!=='' ? (
                        <div className={`mt-[10px] text-white font-chakra text-center w-full md:w-[600px] ${feedbackError ? ('text-[#ff0000]') : ( null )}`}>
                            {saveFeedback}
                        </div>
                    ) : ( null )}
                </div>
            ) : (
                <div className='h-[50px] pt-[29px] w-full md:w-[600px] font-chakra text-white text-[14px] text-center'>
                    Saving...
                </div>
            )}
            <div className='mt-[30px]'>
                <div className='font-chakra text-[14px] text-[#d2ff00] cursor-pointer' onClick={handleDeleteClick}>Delete my account</div>
            </div>
            <div className='mt-[10px]'>
                <div className='font-chakra text-[14px] text-[#d2ff00] cursor-pointer' onClick={handleSignout}>Sign out</div>
            </div>

            <Modal 
                isOpen={isDeleteModalOpen} 
                onRequestClose={() => setIsDeleteModalOpen(false)} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '400px',
                        background: '#1b062f',
                        textAlign: 'center',
                        color: '#fff'
                    }
                }}
                contentLabel="Delete Confirmation"
            >
                <div className='text-white font-chakra text-[18px]'>Are you sure you want to delete your account?</div>
                <div className="mt-[20px]">
                    <div className='bg-[#d2ff00] text-[#1b062f] p-[10px] mb-[20px] font-anton cursor-pointer' onClick={handleConfirmDelete}>Yes, delete</div>
                    <div className='bg-[#d2ff00] text-[#1b062f] p-[10px] font-anton cursor-pointer' onClick={() => setIsDeleteModalOpen(false)}>No, cancel</div>
                </div>
            </Modal>
        </div>
    );
};
