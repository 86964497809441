import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const Teams = React.forwardRef((props, ref) => {
    const [teamlist, setTeamlist] = useState([]);
    async function getTeams() {
        try {
            const response = await fetch(`${process.env.API_URL}/list/teams`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY,
              },
            });
    
            if (response.status === 200) {
                let tl=[];
                const data = await response.json();
                for (let i=0; i<data.length; i++) {
                    tl.push(data[i].teams);
                }
                setTeamlist(tl);
                }
        } catch (error) {
            return null; 
        }
    }
    useEffect(() => {
        getTeams();
    }, []);

    useEffect(() => {
        if (ref.current) {
            ref.current.value = props.selectedTeam; 
        }
    }, [ref, props.selectedTeam]);

    return (
        <div className='font-chakra text-[#18062f] !text-left text-[12px] md:text-[14px]'>
            <div className="select-wrapper !w-[100%]">
                <select
                    ref={ref}
                    id="teams"
                    name="teams"
                    className="dropdown !h-[40px]"
                    value={props.selectedTeam}
                    onChange={(e) => props.setSelectedTeam(e.target.value)}
                >
                    <option value="" disabled>Favourite team...</option>
                    {teamlist.map((team, index) => (
                        <option key={index} value={team}>
                            {team}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
});
