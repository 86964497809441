import React, { useEffect, useState, useRef } from "react";
import { ClubLogo } from "../ClubLogo";
import { FaVideo } from "react-icons/fa";

export const UpcomingMatches = () => {
  const [matches, setMatches] = useState([]);
  const [liveScore, setLiveScore] = useState({});
  const intervalsRef = useRef([]);

  async function fetchScoreCard(match_id, teams) {
    try {
      const res = await fetch(`${process.env.API_URL}/scorecard/${match_id}`, {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.API_KEY
        },
      });
      if (res.status === 200) {
        const data = await res.json(); 
        if (data.length>0) {
          let inns=[]
          inns[1]=data[0].score.inns1;
          inns[2]=data[0].score.inns2;
          inns[3]=data[0].score.inns3;
          inns[4]=data[0].score.inns4;
          let homeScore='';
          let awayScore='';
          let cinns = data[0].score.inns_now;
          if (teams[0]===data[0].score.bat_now) {
            homeScore=inns[cinns]
            if (cinns>1) {
              awayScore=inns[cinns-1];
            }
          } else {
            awayScore=inns[cinns]
            if (cinns>1) {
              homeScore=inns[cinns-1];
            }
          }
          setLiveScore(prevState => ({
            ...prevState,
            [match_id]: {homescore: homeScore, awayscore: awayScore, batnow: data[0].score.bat_now }
          }));
        }
      } else {
        //console.error('Failed to fetch scorecard:', res.status);
      }
    } catch (error) {
      //console.error('Network error:', error);
    }  
  }
  
  async function fetchData(fetchLive) {
    let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
    let maxDisplay=20;
    const res = await fetch(process.env.API_URL+'/fixturelist', {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.API_KEY
      },
    });
    const data = await res.json();
    const mData=data.matches;
    let matchBuild=[];
    mData.forEach(match => {
      if (match.sport===4) {
        if (match.id.includes('LIVE')) {
          if (match.competition.toLowerCase().trim()===tournamentFilter.trim() || tournamentFilter.trim()==='') {
            match.competition=match.competition.replace('Indian Premier League','IPL 2024');
            //if (match.invenuescoring) {
              let matchStart = new Date(match.start);
              let today = new Date();
              match.localTime = matchStart.toLocaleString('en-US', {
                weekday: 'short', 
                hour: 'numeric', 
                minute: '2-digit',
                hour12: true 
              });
              let diff=matchStart-today;
              let mm = Math.floor(diff / 1000 / 60);
              let hh = Math.floor(diff / 1000 / 60 / 60);
              let days = Math.floor(hh / 24);
              if (days > 0) {
                let remainingHours = hh % 24; // Remaining hours after subtracting full days
                match.diff = days + "D " + remainingHours + "H";
              } else if (hh > 0) {
                match.diff = hh + "H";
              } else {
                match.diff = mm + "M";
              }
              matchStart.setHours(0, 0, 0, 0);
              today.setHours(0, 0, 0, 0);
              let name=match.name.split(",");
              let teams=name[0].split(" v ");
              if (match.status.substring(0,17)==='Match in Progress' 
                  || match.status.substring(0,13)==='Break in Play' 
                  || match.status.substring(0,16)==='Innings Complete'
                  || match.status.substring(0,8)==='Play to '
                  || match.status.substring(0,12)==='Toss Pending'
                ) { 
                  match.localTime='LIVE'; 
                  fetchScoreCard(match.id, teams);
                  const intervalId = setInterval(() => {
                    fetchScoreCard(match.id, match.teams);
                  }, 30000); // 30 seconds interval
                  intervalsRef.current.push(intervalId);
                } else if (match.status.substring(0,14)==='Match Complete' || match.status.substring(0,12)==='Event Closed') {
                  match.localTime='Match Complete';
                  fetchScoreCard(match.id, teams);
                }
                //console.log(match.status + match.name, match.end);
              if (matchStart >= today) {
                if (match.end==='') {
                  match.teams=teams;
                  matchBuild.push(match);
                }
              }
            //}
          }
        }
      }
    })
    matchBuild.sort((a, b) => new Date(a.start) - new Date(b.start));
    setMatches(matchBuild);
  }
  useEffect(() => {
    fetchData();
    return () => {
      intervalsRef.current.forEach(clearInterval);
    };    
  }, []);

  const handleMatchClick = (matchId, localTime) => {
    if (localTime==='LIVE' || localTime==='MATCH COMPLETE') {
      window.location="/matchdetail?id="+matchId;
    }
  };

  return (
    <div className='w-[100%] overflow-x-auto'>
        <div className='flex gap-4'>
            {matches.map((match,index) => (
                <div key={match.id} className={`${match.localTime==='LIVE' || match.locaTime==='MATCH COMPLETE' ? 'cursor-pointer' : ''}`} onClick={() => handleMatchClick(match.id, match.localTime)}>
                    <div className="relative w-[260px] h-[30px]">
                        <div className="absolute w-[260px] h-[30px] top-0 left-0" style={{backgroundColor: "rgba(255, 255, 255, 0.15)"}} />
                        <div className="absolute h-[30px] top-0 left-0 bg-[#d2ff00] text-[14px] inline anton font-normal text-[#1b062f] pt-[9px] px-[5px] truncate max-w-[50%]">
                           {match.competition}
                        </div>
                        <div className='absolute right-[5px] top-[9px] font-chakra font-normal text-white text-[12px]'>
                            <div className={`schedule ${match.localTime === 'LIVE' ? 'text-[#f73261] flex' : ''}`}>
                                {match.localTime}
                                {match.localTime==='LIVE' ? (
                                    <div className="live-indicator mr-[10px]">
                                        <div className="inner"></div>
                                    </div>
                                ) : ( null )}
                            </div>
                        </div>
                    </div>
                    <div className="relative w-[260px] h-[120px] mt-[1px]">
                        <div className="absolute w-[260px] h-[120px] top-0 left-0 bg-white" />
                        <div className='absolute top-[10px] left-[10px]'>
                            <ClubLogo teamName={match.teams[0]} />
                        </div>
                        <div className='absolute top-[80px] left-[10px] font-chakra bold text-[16px] text-[#1b062d] truncate'>
                            {match.teams[0]}
                        </div>
                        <div className='absolute top-[10px] right-[10px] !text-right font-chakra bold text-[#1b062f] text-[26px]'>
                            {liveScore[match.id]?.homescore || ''}
                        </div>
                        {liveScore[match.id]?.batnow===match.teams[0] ? (
                            <div className="absolute w-[3px] h-[49px] top-0 right-[0px] bg-[#f73261]" />
                        ) : (null)}
                    </div>
                    <div className="relative w-[260px] h-[120px] mt-[1px]">
                        <div className="absolute w-[260px] h-[120px] top-0 left-0 bg-white" />
                        <div className='absolute top-[10px] left-[10px]'>
                            <ClubLogo teamName={match.teams[1]} />
                        </div>
                        <div className='absolute top-[80px] left-[10px] font-chakra bold text-[16px] text-[#1b062d] truncate'>
                            {match.teams[1]}
                        </div>
                        <div className='absolute top-[10px] right-[10px] !text-right font-chakra bold text-[#1b062f] text-[26px]'>
                            {liveScore[match.id]?.awayscore || ''}
                        </div>
                        {liveScore[match.id]?.batnow===match.teams[1] ? (
                            <div className="absolute w-[3px] h-[49px] top-0 right-[0px] bg-[#f73261]" />
                        ) : (null)}
                    </div>
                    <div className="relative w-[260px] h-[30px] mt-[1px]">
                        <div className="absolute w-[260px] h-[30px] top-0 left-0" style={{backgroundColor: "rgba(255, 255, 255, 0.15)"}} />
                        <div className='absolute top-[10px] left-[10px] font-chakra font-normal text-white text-[12px]'>
                            {match.localTime==='LIVE' ? (
                                <>
                                  <div className="absolute w-[260px] h-[30px] top-[-10px] left-[-10px] !bg-[#d73261]">
                                      <div className="relative top-[10px] left-[10px]">
                                        {match.watchalong!==null ? (
                                          <>
                                            <img className="pr-[10px]" alt="Vector" src="/img/camera.svg" />
                                            WATCH ALONG LIVE
                                          </>
                                        ) : (
                                          <>LIVE</>
                                        )}
                                      </div>
                                  </div>
                                </>
                            ) : match.localTime=='Match Complete' ? (
                                <span>MATCH COMPLETE</span>
                            ) : parseInt(match.diff) <= 0 ? (
                                <span></span>
                            ) : (
                                <>
                                <span>STARTS IN {match.diff}</span>
                                </>
                            )}
                        </div>
                        {match.watchalong!==null ? (
                                  <div className='font-chakra text-[14px] absolute right-[10px] top-[8px] text-[#32f78c]'><FaVideo /></div>
                                ) : ( null )}

                    </div>
                </div>            
            ))}
        </div>
    </div>
  );
};
